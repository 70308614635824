import React from "react"
import slugify from "slugify"
import {
  EntryWithoutUnresolvableLinks,
  isGroupSession,
  isGuide,
  ReadLearnArticleEntry
} from "../../../utils/ContentTypes"
import { FormattedMessage } from "react-intl"
import { Link, Typography } from "@carrotfertility/carotene-core"
import { Link as RouterLink } from "react-router-dom"
import { Category } from "./CategoryMapper"
import { TypeCategorySkeleton, TypeSubCategorySkeleton } from "types/contentful"

type useReadLearnLinksProps = {
  category: EntryWithoutUnresolvableLinks<TypeCategorySkeleton> | Category
  subCategory?: EntryWithoutUnresolvableLinks<TypeSubCategorySkeleton>
  article?: ReadLearnArticleEntry
}

function isICategory(entry: any): entry is EntryWithoutUnresolvableLinks<TypeCategorySkeleton> {
  return (entry as EntryWithoutUnresolvableLinks<TypeCategorySkeleton>).fields !== undefined
}

export function useReadLearnLinks({ category, subCategory = null, article = null }: useReadLearnLinksProps): {
  categoryLink: string
  subCategoryLink?: string
  articleLink?: string
  subCategoryPageLink?: string
} {
  const categorySlug = category
    ? isICategory(category)
      ? slugify(category.fields.navTitle)
      : slugify(category.navTitle)
    : null
  const links = {
    categoryLink: `/read/${categorySlug}`,
    subCategoryLink: null as string,
    articleLink: null as string,
    subCategoryPageLink: null as string
  }

  if (subCategory) {
    links.subCategoryLink = `/read/${categorySlug}#${slugify(subCategory?.fields?.navTitle)}`

    links.subCategoryPageLink = `/read/all-articles/${categorySlug}/${slugify(subCategory.fields.navTitle)}`
  }

  if (article && article.fields) {
    const articleSlug = "slug" in article.fields ? article.fields.slug : slugify(article.fields.title)
    if (isGuide(article)) {
      links.articleLink = `/read/${categorySlug}/guide/${articleSlug}`
    } else if (isGroupSession(article)) {
      links.articleLink = `/read/${categorySlug}/group/${articleSlug}`
    } else {
      links.articleLink = `/read/${categorySlug}/${articleSlug}`
    }
  }

  return { ...links }
}

type SubCategoryLinkProps = {
  subCategory: EntryWithoutUnresolvableLinks<TypeSubCategorySkeleton>
  category: EntryWithoutUnresolvableLinks<TypeCategorySkeleton> | Category
}

export function SubCategoryLink({ subCategory, category }: SubCategoryLinkProps): JSX.Element {
  const { subCategoryLink } = useReadLearnLinks({ category, subCategory })
  return (
    <Link to={subCategoryLink} component={RouterLink} color="inherit">
      {subCategory?.fields?.title}
    </Link>
  )
}

type AdditionalTopicsLinkProps = {
  categoryLink: string
  additionalTopicCount: number
}

export function AdditionalTopicsLink({ categoryLink, additionalTopicCount }: AdditionalTopicsLinkProps): JSX.Element {
  return (
    <Link to={categoryLink} component={RouterLink} sx={{ textDecoration: "none" }}>
      <Typography variant="body2" color={(theme) => theme.palette.text.tertiary}>
        <FormattedMessage
          defaultMessage="+ {additionalTopicCount, plural, one {# more topic} other {# more topics}}"
          values={{ additionalTopicCount: additionalTopicCount }}
        />
      </Typography>
    </Link>
  )
}
