import React, { useEffect } from "react"
import { useStateMachine } from "../../../context/stateMachine/StateMachineV2"
import { useGetRoutingInfo } from "components/carrot-plans/shared/useQueryRoutingInfo"

export default function ShowMedicalQuestionsDecisionStep(): JSX.Element {
  const { data: routingData } = useGetRoutingInfo()
  const medicalPreference = routingData?.amabAfabMedicalPreference
  const skipMedicalQuestionsForJourneyStage: boolean = routingData?.journeyStage === "POSTPARTUM"

  function showMedicalQuestion(): string {
    if (skipMedicalQuestionsForJourneyStage || medicalPreference === "DECLINED_HEALTH_QS") {
      return "NO"
    }
    return "YES"
  }

  const { send } = useStateMachine()
  useEffect(() => {
    send(showMedicalQuestion())
  })
  return <></>
}
