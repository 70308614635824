import React, { useEffect, useLayoutEffect, useRef, useState } from "react"
import PropTypes from "prop-types"
import { getErrorMessageFromCode, CarrotErrorCodes } from "utils/CarrotErrors"
import { withRouter } from "react-router-dom"
import queryString from "query-string"
import { useDidMount } from "../../../utils/Hooks"
import { useDispatch } from "react-redux"
import { redirectToSamlExternalLogin } from "../../../utils/RedirectToSamlExternalLogin"
import { persistEmail, signIn } from "../../../actions/loginActions"
import { useQueryClient } from "@tanstack/react-query"
import {
  attemptBiometricsCredentialsRetrieval,
  shouldAttemptBioAuthenticationLogin,
  showBiometricsEnrollment
} from "carrot-api/MobileNativeClient"
import {
  trackBiometricAuth,
  trackBiometricAuthAttempt,
  trackBiometricAuthEnrollment,
  useBiometricAuthEnrollment
} from "../../context/BiometricAuthEnrollment/BiometricAuthEnrollmentContext"
import { useAuthentication } from "../../context/authentication/AuthenticationProvider"
import { SignIn } from "pages/sign-in"
import { defineMessage, useIntl } from "react-intl"
import { Link } from "@carrotfertility/carotene-core"
import { Form } from "@carrotfertility/carotene-core-x"
import { EmployeeSupportUrl } from "../../../utils/EmployeeSupportLink"
import { carrotClient } from "utils/CarrotClient"

const defaultState = {
  username: null,
  password: null,
  submitting: false,
  showForgotPassword: false,
  showPasswordInput: false,
  showEmailInput: true,
  samlConfig: null,
  shake: false,
  errorHasLink: false
}

const LoginContainer = ({ location, history }) => {
  const [state, setState] = useState(defaultState)
  const [enrollInBioAuth, setEnrollInBioAuth] = useState(false)
  const passwordFailedRef = useRef(false)
  const dispatch = useDispatch()
  const passwordInputRef = useRef()
  const queryClient = useQueryClient()
  const {
    setBiometricAuthEnrollmentSuccess,
    setBiometricAuthEnrollmentFailure,
    resetBiometricAuthEnrollmentState,
    disableBiometricAuth
  } = useBiometricAuthEnrollment()
  const { isExplicitLogout, resetExplicitLogout } = useAuthentication()
  const intl = useIntl()

  useState(() => {
    async function execute() {
      if (!isExplicitLogout && (await shouldAttemptBioAuthenticationLogin())) {
        setTimeout(async () => {
          trackBiometricAuthAttempt()
          await attemptBiometricsCredentialsRetrieval(bioAuthLogin)
        }, 1000)
      }
    }
    execute()
  })

  useEffect(() => {
    queryClient.clear()
    // eslint-disable-next-line react-hooks/exhaustive-deps -- See https://carrotfertility.atlassian.net/wiki/spaces/PE/pages/2050295461/Remove+Build+Warnings#react-hooks%2Fexhaustive-deps
  }, [])

  const onSubmit = async (formData) => {
    if (formData.password) {
      await login(formData.username, formData.password, formData.setupBiometricAuth)
    } else {
      await lookup(formData.username)
    }
  }

  useDidMount(() => {
    const parsedQueryString = queryString.parse(location.search)
    const errorCode = parsedQueryString.errorCode
    const isErrorPage = errorCode != null

    setState({
      ...state,
      errorMessage: errorCode ? getErrorMessageFromCode(errorCode) : null,
      // If we have an error message from the URL upon loading this view, that means we want to display only the message and "Sign in" button, so showEmailInput should be false
      showEmailInput: !isErrorPage,
      isErrorPage,
      returnUrl: parsedQueryString.ReturnUrl ? parsedQueryString.ReturnUrl : null,
      errorHasLink:
        errorCode === CarrotErrorCodes.EMPLOYEE_INACTIVE || CarrotErrorCodes.SAML_UNSUCCESSFUL_OPERATION_EXCEPTION
    })
  })

  useLayoutEffect(() => {
    if (passwordInputRef.current) {
      passwordInputRef.current.focus()
    }
  }, [state.showEmailInput])

  const lookup = async (email) => {
    if (state.isErrorPage) {
      setState({
        ...state,
        isErrorPage: false,
        showEmailInput: true,
        showForgotPassword: false,
        showPasswordInput: false,
        errorMessage: null,
        errorHasLink: false
      })
      return
    }

    setState({
      ...state,
      submitting: true,
      shake: false
    })

    let response
    try {
      response = await carrotClient.lookup(email)
    } catch (error) {
      setState({
        ...state,
        submitting: false,
        errorMessage: defineMessage({ defaultMessage: "Connection problem" }),
        shake: true,
        errorHasLink: false
      })
      return
    }

    // Employee's company uses SAML
    if (response.employee && response.employee.samlIdpEntityId && response.employee.ssoOnly) {
      redirectToSamlExternalLogin(
        response.employee.samlExternalLoginUrl,
        response.employee.samlIdpEntityId,
        response.employee.isAdmin,
        state.returnUrl
      )
      return
    }

    if (!response.employee) {
      setState({
        ...state,
        submitting: false,
        errorMessage:
          response.status === "MEMBER_INACTIVE"
            ? defineMessage({
                defaultMessage:
                  "It looks like your account has recently been deactivated. If you need to submit receipts for service prior to the end of your employment, please <link>contact us</link>. If you feel that your account has been deactivated in error, please reach out to your internal benefits team for next steps."
              })
            : defineMessage({
                defaultMessage: "We couldn't find your Carrot account."
              }),
        errorHasLink: response.status === "MEMBER_INACTIVE",
        shake: true
      })
      return
    }

    // Persist current email in Store
    dispatch(persistEmail(email))

    // if employee hasn't registered yet, send them to signup
    if (!response.employee.isRegistered) {
      history.push(`/signup`)
      return
    }

    // Employee exists, so show login view with no error message
    await setState({
      ...state,
      submitting: false,
      showPasswordInput: true,
      showForgotPassword: true,
      samlConfig:
        !response.employee.ssoOnly && response.employee.samlIdpEntityId
          ? {
              samlExternalLoginUrl: response.employee.samlExternalLoginUrl,
              samlIdpEntityId: response.employee.samlIdpEntityId
            }
          : null,
      errorMessage: null,
      shake: false,
      errorHasLink: false
    })

    if (passwordInputRef.current) {
      passwordInputRef.current.focus()
    }

    // Set listener for "back" button press, and transforms view into "lookup" state
    history.listen((location, action) => {
      if (action === "POP") {
        setState({
          ...state,
          submitting: false,
          showPasswordInput: false,
          showForgotPassword: false,
          errorMessage: null
        })
      }
    })
  }

  const login = async (email, password, registerBioAuthentication = false) => {
    setState({
      ...state,
      submitting: true,
      shake: false
    })

    try {
      await carrotClient.login(email, password)
    } catch (error) {
      setState({
        ...state,
        submitting: false,
        errorMessage: defineMessage({ defaultMessage: "Password does not match." }),
        shake: true
      })

      passwordFailedRef.current = true
      return
    }

    if (registerBioAuthentication) {
      tryBioAuthEnrollment(email, password)
    } else {
      setState({ ...state, submitting: false })
      dispatch(signIn())
      resetExplicitLogout()
    }
  }

  const bioAuthEnrollmentSuccess = () => {
    trackBiometricAuthEnrollment(true)
    setBiometricAuthEnrollmentSuccess()
    setState({ ...state, submitting: false })
  }

  const bioAuthEnrollmentFailure = () => {
    trackBiometricAuthEnrollment(false)
    setBiometricAuthEnrollmentFailure()
    // We've logged in successfully, however, for some reason biometric enrollment failed
    setState({ ...state, submitting: false })
  }

  const tryBioAuthEnrollment = (email, password) => {
    showBiometricsEnrollment(
      email,
      password,
      () => {
        // Timeout is for visual purposes to slow the enrollment process down a touch. On iOS the app logs in before
        // the face id screen completes, which seems off
        setTimeout(() => {
          bioAuthEnrollmentSuccess()
        }, 1000)
      },
      bioAuthEnrollmentFailure
    )
  }

  const bioAuthLogin = async (email, password) => {
    await login(email, password, false)
    if (passwordFailedRef.current) {
      setState({
        ...state,
        submitting: false,
        shake: false,
        showPasswordInput: false,
        errorMessage: defineMessage({
          defaultMessage: "Biometric authentication failed, please type your credentials again."
        })
      })
      trackBiometricAuth(false)
      passwordFailedRef.current = false
      // This is for change in password failing bioauth
      disableBiometricAuth()
      setEnrollInBioAuth(true)
    } else {
      trackBiometricAuth(true)
    }
  }

  const errorMessage = state.errorMessage?.id
    ? intl.formatMessage(state.errorMessage, {
        link: (linkContent) => (
          <Link style={{ color: "inherit" }} href={EmployeeSupportUrl} target={"_blank"}>
            {linkContent}
          </Link>
        )
      })
    : ""

  return (
    <Form onSubmit={onSubmit}>
      <SignIn
        errorMessage={errorMessage}
        login={login}
        lookup={lookup}
        passwordInputRef={passwordInputRef}
        shake={state.shake}
        showEmailInput={state.showEmailInput}
        showForgotPassword={state.showForgotPassword}
        showPasswordInput={state.showPasswordInput}
        submitting={state.submitting}
        successfulBiometricAuthAction={() => {
          dispatch(signIn())
          resetBiometricAuthEnrollmentState()
        }}
        bioAuthLogin={bioAuthLogin}
        enrollInBioAuth={enrollInBioAuth}
        samlConfig={state.samlConfig}
        redirectToSamlExternalLogin={redirectToSamlExternalLogin}
        returnUrl={state.returnUrl}
      />
    </Form>
  )
}

LoginContainer.propTypes = {
  history: PropTypes.object.isRequired
}

export default withRouter(LoginContainer)
