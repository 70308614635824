import { Category } from "components/read/shared/CategoryMapper"
import {
  TypeArticleSkeleton,
  TypeGroupSessionSkeleton,
  TypeGuideSkeleton,
  TypeTileSkeleton,
  TypeVideoSkeleton
} from "types/contentful"
import { EntrySkeletonType, Entry } from "contentful"

enum ContentTypes {
  ARTICLE = "article",
  ATTRIBUTE = "attribute",
  ACCORDION = "accordion",
  BLOCK_QUOTE = "blockQuote",
  CALENDLY_LINK = "calendlyLink",
  CANT_HAVE = "cantHave",
  CARROT_PLAN_MENU_ITEM = "carrotPlanMenuItem",
  CARROT_PLAN_MENU_ITEM_CATEGORIES = "carrotPlanMenuItemCategories",
  CARROT_PLAN_MENU_ITEM_CATEGORY = "carrotPlanMenuItemCategory",
  CATEGORY = "category",
  CONTAINER = "container",
  CONTENT_BLOCK = "contentBlock",
  CONTENT_BLOCK_WITH_ICON = "contentBlockWithIcon",
  COUNTRY = "country",
  EXPERTBIO = "expertBio",
  GUIDE = "guide",
  GROUP_SESSION = "groupSession",
  HAS_ANY = "hasAny",
  ICON = "icon",
  MODAL = "modal",
  MUST_HAVE = "mustHave",
  PAGE = "page",
  PAGE_GUIDANCE = "pageGuidance",
  PAGE_SET = "pageSet",
  PHONE_SUPPORT_NUMBER = "phoneSupportNumber",
  SUB_CATEGORY = "subCategory",
  TALK_TO_CARROT_COUNTRY = "talkToCarrotCountry",
  TALK_TO_CARROT_SEND_A_MESSAGE = "talkToCarrotSendAMessage",
  TALK_TO_CARROT_LANGUAGE_PAGE = "talkToCarrotLanguagePage",
  TALK_TO_CARROT_LANGUAGE = "talkToCarrotLanguage",
  TILE = "tile",
  TILE_DISPLAY = "tileDisplay",
  TILE_PAGE = "tilePage",
  TWO_COLUMN_BULLET_LIST = "twoColumnBulletList",
  TWO_COLUMN_LAYOUT = "twoColumnLayout",
  VIDEO = "video",
  UNORDERED_LIST = "unorderedList",
  TALK_TO_CARROT_COUNTRY_V2 = "talkToCarrotCountryV2",
  QUICK_LINKS = "quickLinks"
}

export type EntryWithoutUnresolvableLinks<T extends EntrySkeletonType> = Entry<T, "WITHOUT_UNRESOLVABLE_LINKS", string>
export type ReadLearnArticleSkeleton =
  | TypeArticleSkeleton
  | TypeGuideSkeleton
  | TypeVideoSkeleton
  | TypeGroupSessionSkeleton

export type ReadLearnArticleEntry = EntryWithoutUnresolvableLinks<ReadLearnArticleSkeleton>

export const isArticle = (
  readLearnArticle: EntryWithoutUnresolvableLinks<ReadLearnArticleSkeleton>
): readLearnArticle is EntryWithoutUnresolvableLinks<TypeArticleSkeleton> =>
  readLearnArticle.sys.contentType.sys.id === ContentTypes.ARTICLE

export const isVideo = (
  readLearnArticle: EntryWithoutUnresolvableLinks<ReadLearnArticleSkeleton>
): readLearnArticle is EntryWithoutUnresolvableLinks<TypeVideoSkeleton> =>
  readLearnArticle.sys.contentType.sys.id === ContentTypes.VIDEO

export const isGuide = (
  readLearnArticle: EntryWithoutUnresolvableLinks<ReadLearnArticleSkeleton>
): readLearnArticle is EntryWithoutUnresolvableLinks<TypeGuideSkeleton> =>
  readLearnArticle.sys.contentType.sys.id === ContentTypes.GUIDE

export const isGroupSession = (
  readLearnArticle: EntryWithoutUnresolvableLinks<ReadLearnArticleSkeleton>
): readLearnArticle is EntryWithoutUnresolvableLinks<TypeGroupSessionSkeleton> =>
  readLearnArticle.sys.contentType.sys.id === ContentTypes.GROUP_SESSION

export type SortableContentEntry = Category | EntryWithoutUnresolvableLinks<TypeTileSkeleton>

export const isCategory = (contentEntry: SortableContentEntry): contentEntry is Category =>
  contentEntry.sys.contentType.sys.id === ContentTypes.CATEGORY

export const isTile = (
  contentEntry: SortableContentEntry
): contentEntry is EntryWithoutUnresolvableLinks<TypeTileSkeleton> =>
  contentEntry.sys.contentType.sys.id === ContentTypes.TILE

export default ContentTypes
