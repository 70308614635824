import { useFlags } from "launchdarkly-react-client-sdk"
import { EntryWithoutUnresolvableLinks } from "../../utils/ContentTypes"
import { TypeCalendlyLinkSkeleton } from "../../types/contentful"

export type AppointmentEntry = EntryWithoutUnresolvableLinks<TypeCalendlyLinkSkeleton>

type UseAppointmentSchedulingSystemResponse = {
  isCalendly: boolean
  isTimeTapForCareNavigation: boolean
  isTimeTapForOtherChats: boolean
}

export function useAppointmentSchedulingSystem(
  appointmentEntry: AppointmentEntry
): UseAppointmentSchedulingSystemResponse {
  const { showTimeTapTtcCareNavigation, showTimeTap } = useFlags()
  const hasReasonId = Boolean(appointmentEntry?.fields?.reasonId)

  const isTimeTapForCareNavigation = showTimeTapTtcCareNavigation && hasReasonId
  const isTimeTapForOtherChats = showTimeTap && hasReasonId
  const isCalendly = Boolean(appointmentEntry?.fields?.url)
  return {
    isCalendly,
    isTimeTapForCareNavigation,
    isTimeTapForOtherChats
  }
}
