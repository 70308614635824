import React from "react"
import { Container, FlexContainer, Stack, useBreakpointComponent } from "@carrotfertility/carotene"
import { HR } from "../views/atoms/Atoms"

import { ReadAndLearnBreadcrumbs } from "./shared/ReadAndLearnBreadcrumbs"
import { EmbeddedVideo } from "./EmbeddedVideo"
import { FormattedMessage, useIntl } from "react-intl"
import { carrotDisclaimerMessages } from "utils/CarrotDisclaimers"
import { ContentfulContentRenderer, ContentfulContentEntry, ContentfulRawMarkdownRenderer } from "services/contentful"
import { defaultMarkdownElements } from "services/markdown"
import { Box, Divider, Tile, Typography, useTheme } from "@carrotfertility/carotene-core"
import { Title } from "services/page-title/Title"
import { PageLayout } from "features/global-ui/page-layout"
import { PageBreadcrumbHeader } from "features/global-ui/page-header"
import { EntryWithoutUnresolvableLinks } from "utils/ContentTypes"
import { TypeCategorySkeleton, TypeSubCategorySkeleton } from "types/contentful"

type LastUpdatedTagProps = {
  lastUpdated: string
  isVideo: boolean
}

function LastUpdatedTag({ lastUpdated, isVideo }: LastUpdatedTagProps): JSX.Element {
  const intl = useIntl()
  const lastUpdatedDate = intl.formatDate(lastUpdated, { year: "numeric", month: "2-digit", day: "2-digit" })
  return (
    <Stack spacing={"12px"} padding={isVideo ? "40px 0px 0px 0px" : "48px 0px 0px 0px"}>
      <Container width={47}>
        <HR width={3} color="black-10" />
      </Container>
      <Typography variant="body2">
        <FormattedMessage
          defaultMessage="Last updated: {lastUpdatedDate}"
          description="The date for when the article was last updated"
          values={{ lastUpdatedDate }}
        />
      </Typography>
    </Stack>
  )
}

function ArticleLegalSidebar(): JSX.Element {
  const intl = useIntl()
  const dividerBottomSidebar = useBreakpointComponent([
    <Container padding={"48px 0px 24px 0px"} key={"smallest"}>
      <HR color="mineral-grey" />
    </Container>,
    <Container padding={"48px 0px 24px 0px"} key={"small"}>
      <HR color="mineral-grey" />
    </Container>,
    <Container padding={"48px 0px 24px 0px"} key={"medium"}>
      <HR color="mineral-grey" />
    </Container>,
    <Container height={"48px"} key={"large"} />
  ])
  return (
    <>
      {dividerBottomSidebar}
      <Tile>
        <Box
          color={(theme) => theme.palette.text.tertiary}
          display="flex"
          flexDirection="column"
          gap={(theme) => theme.spacing(theme.tokens.spacing.lg)}
        >
          <Typography variant="body2" color="inherit">
            {intl.formatMessage(carrotDisclaimerMessages.SERVICES_LEGALITY_AVAILABILITY)}
          </Typography>
          <Divider />
          <Typography variant="body2" color="inherit">
            {intl.formatMessage(carrotDisclaimerMessages.INCLUSIVE_LANGUAGE)}
          </Typography>
        </Box>
      </Tile>
    </>
  )
}

type MainContentProps = {
  articleBody: string
  articleTitle: string
  isVideo: boolean
  videoUrl: string
  articleContent?: Array<ContentfulContentEntry> | null
}

const MainContent = ({
  articleBody,
  articleContent,
  articleTitle,
  isVideo,
  videoUrl
}: MainContentProps): JSX.Element => {
  if (isVideo) {
    return <EmbeddedVideo videoUrl={videoUrl} title={articleTitle} />
  } else if (articleContent) {
    return <ContentfulContentRenderer contents={articleContent} />
  } else {
    return <ContentfulRawMarkdownRenderer>{articleBody}</ContentfulRawMarkdownRenderer>
  }
}

type ReadArticleProps = {
  articleBody: string
  articleTitle: string
  category: EntryWithoutUnresolvableLinks<TypeCategorySkeleton>
  isVideo: boolean
  lastUpdated: string
  subCategory: EntryWithoutUnresolvableLinks<TypeSubCategorySkeleton>
  videoUrl: string
  articleContent?: Array<ContentfulContentEntry> | null
}

export default function ReadLearnArticle({
  articleBody,
  articleTitle,
  category,
  isVideo,
  lastUpdated,
  subCategory,
  videoUrl,
  articleContent
}: ReadArticleProps): JSX.Element {
  const intl = useIntl()

  const dividerSideSidebar = useBreakpointComponent([
    null,
    null,
    null,
    <Container padding={"48px 0px 0px 0px"} key={"large"}>
      <HR color="mineral-grey" />
    </Container>
  ])
  const theme = useTheme()

  return (
    <>
      <Title title={articleTitle} productTitle="Read and learn" />
      <PageLayout
        variant="sidebar"
        sidebar={<ArticleLegalSidebar />}
        header={
          <PageBreadcrumbHeader
            breadcrumbs={<ReadAndLearnBreadcrumbs category={category} subcategory={subCategory} />}
          />
        }
      >
        <Container maxWidth={isVideo ? "800px" : "606px"} alignItems={"center"}>
          <defaultMarkdownElements.h1.component {...defaultMarkdownElements.h1.props}>
            {articleTitle}
          </defaultMarkdownElements.h1.component>
          <MainContent
            articleBody={articleBody}
            articleContent={articleContent}
            articleTitle={articleTitle}
            isVideo={isVideo}
            videoUrl={videoUrl}
          />
          <LastUpdatedTag lastUpdated={lastUpdated} isVideo={isVideo} />
        </Container>
        {dividerSideSidebar}
        <FlexContainer padding={"24px 0px 0px 0px"} maxWidth={"600px"} width={[null, "66%", "100%"]}>
          <Typography variant="body2" color={theme.palette.text.tertiary}>
            {intl.formatMessage(carrotDisclaimerMessages.CARROT_RESOURCES)}
          </Typography>
        </FlexContainer>
      </PageLayout>
    </>
  )
}
