import { useQuery } from "@tanstack/react-query"
import ResponseError from "../../../types/responseError"
import { CarrotPlanEmployeeSettings } from "../../../types/carrotPlanTypes"
import { carrotClient } from "utils/CarrotClient"
import buildCarrotApiRetryPolicy from "../../../utils/CarrotApiRetryPolicy"
import useQueryExpirationHandler from "../../context/authentication/hooks/useQueryExpirationHandler"
import { useMemo } from "react"

export const carrotPlanEmployeeSettingsQueryKey = "GetCarrotPlanEmployeeSettings"

export const setMemberRequestedEmailForInitialCarrotPlanChat = () =>
  carrotClient.setMemberRequestedEmailForInitialCarrotPlanChat()

export default function useCarrotPlanEmployeeSettings() {
  const carrotPlanEmployeeSettingsQuery = useQuery<CarrotPlanEmployeeSettings, ResponseError>(
    [carrotPlanEmployeeSettingsQueryKey],
    () => carrotClient.getCarrotPlanEmployeeSettings(),
    {
      retry: buildCarrotApiRetryPolicy(3)
    }
  )

  const isDoneForNow = useMemo(
    () =>
      !carrotPlanEmployeeSettingsQuery.isError &&
      !carrotPlanEmployeeSettingsQuery.isLoading &&
      !!carrotPlanEmployeeSettingsQuery?.data.doneForNowLockedAtUtc,
    [
      carrotPlanEmployeeSettingsQuery.isError,
      carrotPlanEmployeeSettingsQuery.isLoading,
      carrotPlanEmployeeSettingsQuery.data
    ]
  )

  const appIsUnlockedAndPendingCarrotPlan = useMemo(
    () =>
      !carrotPlanEmployeeSettingsQuery.isError &&
      !carrotPlanEmployeeSettingsQuery.isLoading &&
      !!carrotPlanEmployeeSettingsQuery.data.unlockedAndPendingCarrotPlan,
    [
      carrotPlanEmployeeSettingsQuery.isError,
      carrotPlanEmployeeSettingsQuery.isLoading,
      carrotPlanEmployeeSettingsQuery.data
    ]
  )

  useQueryExpirationHandler(carrotPlanEmployeeSettingsQuery)
  return {
    ...carrotPlanEmployeeSettingsQuery,
    isDoneForNow,
    appIsUnlockedAndPendingCarrotPlan
  }
}
