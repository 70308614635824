import { useQuery, UseQueryResult } from "@tanstack/react-query"
import ResponseError from "types/responseError"
import buildCarrotApiRetryPolicy from "utils/CarrotApiRetryPolicy"
import { carrotClient } from "utils/CarrotClient"

const queryKey = "getAirwallexScaAuthorizationCode"

export interface AuthorizationCodeResponse {
  authorizationCode: string
  codeVerifier: string
  clientId: string
}

const request = async (): Promise<void> => {
  return carrotClient.getAirwallexScaAuthorizationCode()
}

export default function useGetScaAuthorizationCode(): UseQueryResult<AuthorizationCodeResponse, ResponseError> {
  return useQuery([queryKey], request, {
    retry: buildCarrotApiRetryPolicy(3),
    refetchOnWindowFocus: false,
    cacheTime: 0
  })
}
