import { useQuery } from "@tanstack/react-query"
import buildCarrotApiRetryPolicy from "utils/CarrotApiRetryPolicy"
import ResponseError from "types/responseError"
import { reportErrorMessage } from "utils/ErrorReporting"
import { getTalkToCarrotLocationGuidance } from "lib/contentful/contentfulClient"
import { EntryWithoutUnresolvableLinks } from "utils/ContentTypes"
import { TypePageGuidanceSkeleton } from "types/contentful"

const talkToCarrotLocationGuidance = "getTalkToCarrotLocationGuidance"

export function useGetTalkToCarrotLocationGuidance(locale: string) {
  return useQuery<EntryWithoutUnresolvableLinks<TypePageGuidanceSkeleton>, ResponseError>(
    [talkToCarrotLocationGuidance],
    () => getTalkToCarrotLocationGuidance(locale),
    {
      refetchOnWindowFocus: false,
      refetchOnMount: true,
      refetchOnReconnect: false,
      retry: buildCarrotApiRetryPolicy(3),
      onError: (error) => reportErrorMessage(`Failed to fetch the location page guidance with error: ${error}`)
    }
  )
}
