import { UseMutationResult } from "@tanstack/react-query"
import { useToastsWithMutation } from "../../../utils/hooks/useToastsWithMutation"
import { useIntl } from "react-intl"
import useGetBankDetailsPageData from "./useGetBankDetailsPage"
import { useCurrentUser } from "components/context/user/UserContext"
import { carrotClient } from "utils/CarrotClient"

const queryKey = "UPDATE_BANK_DETAILS"

export default function useUpdateBankDetails(): UseMutationResult {
  const intl = useIntl()
  const { refetchData } = useGetBankDetailsPageData()
  const { refreshUserInfo } = useCurrentUser()

  const getSuccessMessage = (): string => {
    return intl.formatMessage({ defaultMessage: "Bank details have been saved." })
  }
  const request = (args: unknown) => carrotClient.updateBankDetails(args)

  const onSuccess = () => {
    refetchData()
    refreshUserInfo()
  }

  return useToastsWithMutation({
    request,
    queryKey,
    getSuccessMessage,
    onSuccess
  })
}
