import { Button, Stack, Typography, useTheme } from "@carrotfertility/carotene-core"
import React from "react"
import { MessageDescriptor, useIntl } from "react-intl"

type WtwEngageHeroProps = {
  buttonText: MessageDescriptor
  messageText: MessageDescriptor
  handleClick: () => void
}

export function WtwEngageHero({ buttonText, messageText, handleClick }: WtwEngageHeroProps): JSX.Element {
  const theme = useTheme()
  const intl = useIntl()

  return (
    <Stack spacing={theme.spacing(theme.tokens.spacing.md)}>
      <Typography margin={0} variant="body1" color={(theme) => theme.palette.text.secondary}>
        {intl.formatMessage(messageText)}
      </Typography>
      <Stack alignItems={"start"}>
        <Button onClick={handleClick} color="secondary" variant="contained" fullWidth={false}>
          {intl.formatMessage(buttonText)}
        </Button>
      </Stack>
    </Stack>
  )
}
