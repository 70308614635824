import React from "react"
import { FormattedMessage, MessageDescriptor, useIntl } from "react-intl"
import { Benefit } from "lib/carrot-api/types/Benefit"
import {
  Box,
  Button,
  CloseIcon,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton
} from "@carrotfertility/carotene-core"

export type tierForModalProps = {
  tierDisplayName?: MessageDescriptor
  backgroundColor?: string
  color?: string
  iconColor?: string
  modalTitle?: MessageDescriptor
  modalDescription?: MessageDescriptor
}

interface TierInfoModalProps {
  open: boolean
  onClose: () => void
  tierForModal?: tierForModalProps
  benefit: Benefit
}

export const TierInfoModal = ({ open, onClose, tierForModal, benefit }: TierInfoModalProps): JSX.Element => {
  const intl = useIntl()
  const hasCustomCoverageText = !!benefit.customizations.memberCustomCoverageExplanationMarkdown

  return (
    <Dialog open={open} onClose={onClose}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-end"
        }}
      >
        <IconButton aria-label={intl.formatMessage({ defaultMessage: "close" })} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </Box>
      <DialogTitle>
        <FormattedMessage {...tierForModal?.modalTitle} />
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          <FormattedMessage {...tierForModal?.modalDescription} />
        </DialogContentText>
      </DialogContent>
      {hasCustomCoverageText ? (
        <DialogActions>
          <Button component="a" href="/your-benefit-guide/coverage/employer-specific-information">
            <FormattedMessage defaultMessage="Learn more" />
          </Button>
        </DialogActions>
      ) : null}
    </Dialog>
  )
}
