import { EntrySkeletonType } from "contentful"
import { getContentfulClient } from "../../../lib/contentful/contentfulClient"
import { getContentfulLocale } from "../../../lib/contentful/utils/contenfulLocaleUtils"
import contentTypes from "../../../utils/ContentTypes"

export async function getHealthPlanQuickLinkEntry(locale: string, channelId: number): Promise<EntrySkeletonType> {
  if (channelId == null) {
    return null
  }
  const contentfulClient = getContentfulClient()
  const contentfulLocale = getContentfulLocale(locale)

  const { items } = await contentfulClient.getEntries({
    content_type: contentTypes.QUICK_LINKS,
    include: 10,
    locale: contentfulLocale,
    "fields.channelId": channelId.toString()
  })
  return items[0] as unknown as EntrySkeletonType
}
