import { useMutation, UseMutationResult } from "@tanstack/react-query"
import ResponseError from "../../types/responseError"
import { carrotClient } from "utils/CarrotClient"

type RegisteringMemberActionRequest = {
  registrationGuid: string
  memberActionTaken: string
}

export default function useUpdateRegisteringMemberActionTracking(): UseMutationResult<
  void,
  ResponseError,
  RegisteringMemberActionRequest
> {
  return useMutation<void, ResponseError, RegisteringMemberActionRequest>(
    ({ registrationGuid, memberActionTaken }: RegisteringMemberActionRequest) =>
      carrotClient.createRegistrationMemberAction(registrationGuid, memberActionTaken),
    {
      retry: false
    }
  )
}
