import Settings from "./CarrotConfig"
import { MessageDescriptor, defineMessage } from "react-intl"

const regexes: Record<"email" | "utf8Characters" | "whiteSpace" | "containsAtSymbol", RegExp> = {
  // https://html.spec.whatwg.org/multipage/input.html#valid-e-mail-address
  email:
    /[\w!#$%&'*+\-/=?^_`{|}~]+(\.[\w!#$%&'*+\-/=?^_`{|}~]+)*@((([-\w]+\.)+[a-zA-Z]{2,62})|(([0-9]{1,3}\.){3}[0-9]{1,3}))/g,
  // eslint-disable-next-line no-control-regex
  utf8Characters: /[^\x00-\x7F]/g,
  whiteSpace: /\s/g,
  containsAtSymbol: /@/g
}

// Only allow urls to start with allowed Carrot URLs and end with combinations of
// slashes and alphanumeric characters. This prevents redirecting to potentially
// malicious URLs e.g. https://admin.get-carrot.com.myfakecarrot.com
const allowedUrlsRegexes = [
  `^${Settings.CARROT_BACKEND_URL}[/a-zA-Z0-9?%=_-]*$`,
  `^${Settings.CARROT_ADMIN_V2_URL}[/a-zA-Z0-9?%=_-]*$`,
  `^${Settings.CARROT_FRONTEND_URL}[/a-zA-Z0-9?%=_-]*$`,
  `^${Settings.ABALONE_URL}[/a-zA-Z0-9?%=_-]*$`,
  `^${Settings.PRODUCT_CONTENT_URL}[/a-zA-Z0-9?%=_-]*$`
]

function validateEmail(value: string | null): null | MessageDescriptor {
  if (!value) {
    return null
  }
  if (!value.match(regexes.containsAtSymbol)) {
    return defineMessage({ defaultMessage: "Value is missing an '@'." })
  }
  if (!value.match(regexes.email) || Boolean(value.match(regexes.whiteSpace)) || containsMoreThanOneAtSymbol(value)) {
    return defineMessage({ defaultMessage: "Please enter a valid email address." })
  }
  if (value.match(regexes.utf8Characters)) {
    return defineMessage({
      defaultMessage:
        "Unfortunately, our system isn't currently able to accept email addresses that use accent marks or other special characters."
    })
  }
  return null
}

function validateUrl(value: string | null): boolean {
  if (!value) {
    return false
  }
  const regexMatches = allowedUrlsRegexes.map((regex) => value.match(regex))
  return regexMatches.some((match) => match !== null)
}

function containsMoreThanOneAtSymbol(value: string): boolean {
  return value.match(regexes.containsAtSymbol).length > 1
}

function validateRequiredEmail(value: string | null): null | MessageDescriptor {
  if (!value) {
    return defineMessage({ defaultMessage: "Required" })
  }
  return validateEmail(value)
}

const emailValidationCarotene = (value: string | null): false | MessageDescriptor => {
  return validateRequiredEmail(value) ?? false
}
const emailValidationCaroteneCore = (value: string | null): true | MessageDescriptor => {
  return validateRequiredEmail(value) ?? true
}

const emailValidationOptionalCaroteneCore = (value: string | null): true | MessageDescriptor => {
  return validateEmail(value) ?? true
}

const emailValidationOptionalCarotene = (value: string | null): false | MessageDescriptor => {
  return validateEmail(value) ?? false
}

const emailValidation = (value: string | null): true | MessageDescriptor => {
  return validateEmail(value) ?? true
}

export {
  regexes,
  allowedUrlsRegexes,
  validateUrl,
  emailValidation,
  emailValidationCarotene,
  emailValidationCaroteneCore,
  emailValidationOptionalCaroteneCore,
  emailValidationOptionalCarotene
}
