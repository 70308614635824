import React from "react"

import { Route, useParams } from "react-router-dom"
import { FlexContainer } from "@carrotfertility/carotene"
import { CategoryPageContent } from "./CategoryPageContent"
import { useReadLearnLinks } from "./shared/ReadLearnLinks"
import { ReadAndLearnBreadcrumbs } from "./shared/ReadAndLearnBreadcrumbs"
import useGetCategories from "./shared/useGetCategories"
import slugify from "slugify"
import { Redirect } from "react-router"
import { useContentfulConditionalGate } from "../../lib/contentful/components/ContentfulConditionGate"
import { ReadLearnHomeMasterLayout } from "./ReadLearnLandingPageLayout"
import { PageLayout } from "features/global-ui/page-layout"
import { PageBreadcrumbHeader } from "features/global-ui/page-header"
import { EntryWithoutUnresolvableLinks } from "utils/ContentTypes"
import { TypeCategorySkeleton } from "types/contentful"

function ReadAndLearnBreadcrumbsContainer(): JSX.Element {
  const { category } = useParams<{ category?: string }>()
  const { data: categories } = useGetCategories()
  const currentCategory = categories.find((c) => slugify(c.fields.navTitle) === category)

  if (!currentCategory) {
    return <Redirect to="/read" />
  }
  return <ReadAndLearnBreadcrumbs category={currentCategory} />
}

function CategoryRoute({ category }: { category: EntryWithoutUnresolvableLinks<TypeCategorySkeleton> }): JSX.Element {
  const { categoryLink } = useReadLearnLinks({ category })

  return <Route exact path={categoryLink} component={() => <CategoryPageContent category={category} />} />
}

export function CategoryPage(): JSX.Element {
  const { data: categories } = useGetCategories()
  const categoriesAndConditions = React.useMemo(
    () =>
      categories.map((category) => ({
        element: <CategoryRoute key={category.sys.id} {...{ category }} />,
        conditions: category?.fields?.conditions
      })),
    [categories]
  )
  const readAndLearnCategories = useContentfulConditionalGate(categoriesAndConditions)
  if (readAndLearnCategories.length === 1) {
    return <ReadLearnHomeMasterLayout>{readAndLearnCategories}</ReadLearnHomeMasterLayout>
  }
  return (
    <PageLayout header={<PageBreadcrumbHeader breadcrumbs={<ReadAndLearnBreadcrumbsContainer />} />}>
      <FlexContainer direction={"column"}>{readAndLearnCategories}</FlexContainer>
    </PageLayout>
  )
}
