import React, { useState } from "react"
import { Text, colors, Form, H2, H3, Stack, Container, FlexContainer } from "@carrotfertility/carotene"
import { BackArrow } from "components/views/atoms/Molecules"
import { Progress } from "components/views/modal/Progress"
import { useStateMachine } from "components/context/stateMachine/StateMachineV2"
import styled from "styled-components"
import { CompleteFormTextArea } from "components/views/atoms/forms/FormTextArea"
import { useCurrentUser } from "components/context/user/UserContext"
import { fetchUserInfo } from "actions/userActions"
import { useDispatch } from "react-redux"
import { useReimbursementContext } from "components/context/reimbursement/GetReimbursedContext"
import { ZendeskLink } from "../../views/atoms/links/ZendeskTextLink"
import { SupportCenterLinks } from "../../../utils/SupportCenterLinks"
import { FormattedMessage, useIntl } from "react-intl"
import { Button, Link, Checkbox, FormControlLabel } from "@carrotfertility/carotene-core"
import { carrotClient } from "utils/CarrotClient"

const StyledZendeskLink = styled(ZendeskLink)`
  margin-inline-start: 4px;
  font-size: 14px;
  color: ${colors.base.black60};
`
export const AnythingElse = (): JSX.Element => {
  const intl = useIntl()
  const dispatch = useDispatch()
  const { send, back } = useStateMachine()
  const { state, expenseId } = useReimbursementContext()
  const [answer, setAnswer] = useState("")
  const [showErrorMessage, updateErrorMessage] = useState(false)
  const { company, hasMetSignatureRequirement } = useCurrentUser()
  const hasIvfRequirement = company?.hasIvfRequirements
  const [isSubmitting, updateIsSubmitting] = useState(false)
  const [taxAcknowledged, setTaxAcknowledged] = useState(false)

  // @ts-expect-error TS7006
  const handleChange = (value): void => {
    setAnswer(value.target.value)
  }

  // @ts-expect-error TS7006
  const isQME = (expenseJourneyType): boolean => {
    return (
      expenseJourneyType === "IVF" ||
      expenseJourneyType === "IUI" ||
      expenseJourneyType === "PRESERVATION" ||
      expenseJourneyType === "PATHFINDING"
    )
  }

  const addReimbursement = async (
    // @ts-expect-error TS7006
    files,
    // @ts-expect-error TS7006
    eligibleReimbursementAmount,
    // @ts-expect-error TS7006
    eligibleReimbursementAmountCurrencyCode,
    // @ts-expect-error TS7006
    whoReceivedCare,
    // @ts-expect-error TS7006
    expenseJourneyType,
    // @ts-expect-error TS7006
    isStepchildAdoption,
    // @ts-expect-error TS7006
    kindOfJourney,
    // @ts-expect-error TS7006
    expenseJourneySubType,
    // @ts-expect-error TS7006
    relatedToStorageFees,
    // @ts-expect-error TS7006
    isInfertility,
    // @ts-expect-error TS7006
    isMedicalNecessity,
    // @ts-expect-error TS7006
    attestationIsMedicallyNecessary,
    // @ts-expect-error TS7006
    attestationIsEligibilityRequirementMet,
    // @ts-expect-error TS7006
    attestationIsMedicallyRelated,
    // @ts-expect-error TS7006
    memberNotes,
    // @ts-expect-error TS7006
    expenseId
  ) => {
    const isCardExpense = !!expenseId
    // @ts-expect-error TS7006
    const fileGuids = files.map((file) => file.guid)
    if (kindOfJourney && expenseJourneyType === "FERTILITY_CARE") {
      expenseJourneyType = kindOfJourney
    }
    if (expenseJourneyType !== "ADOPTION") isStepchildAdoption = null

    if (!isQME(expenseJourneyType)) {
      isInfertility = false
      isMedicalNecessity = false
    }

    if (isCardExpense) {
      await carrotClient.submitCardExpense(
        fileGuids,
        [whoReceivedCare],
        expenseJourneyType,
        expenseJourneySubType,
        relatedToStorageFees,
        isStepchildAdoption,
        isInfertility,
        isMedicalNecessity,
        attestationIsMedicallyNecessary,
        attestationIsEligibilityRequirementMet,
        attestationIsMedicallyRelated,
        memberNotes,
        expenseId
      )
    } else {
      await carrotClient.submitExpense(
        fileGuids,
        eligibleReimbursementAmount,
        eligibleReimbursementAmountCurrencyCode,
        [whoReceivedCare],
        expenseJourneyType,
        expenseJourneySubType,
        relatedToStorageFees,
        isStepchildAdoption,
        isInfertility,
        isMedicalNecessity,
        attestationIsMedicallyNecessary,
        attestationIsEligibilityRequirementMet,
        attestationIsMedicallyRelated,
        memberNotes
      )
    }
  }

  const onSubmit = async () => {
    updateIsSubmitting(true)
    const files = [...state.statementFiles]
    try {
      await addReimbursement(
        files,
        state.eligibleReimbursementAmount,
        state.eligibleReimbursementAmountCurrencyCode,
        state.whoReceivedCare.value,
        state.expenseRelatedTo.value,
        state.isStepchildAdoption,
        state.kindOfJourney.value,
        state.journeySubtype.value,
        state.relatedToStorageFees.value,
        state.isInfertilityDiagnosis,
        state.isMedicalNecessity,
        state.attestationIsMedicallyNecessary,
        state.attestationIsEligibilityRequirementMet,
        state.attestationIsMedicallyRelated,
        answer,
        expenseId
      )
    } catch (error) {
      updateErrorMessage(true)
      updateIsSubmitting(false)
      return
    }

    await dispatch(fetchUserInfo())

    if (state.kindOfJourney.value === "IVF" && hasIvfRequirement && !hasMetSignatureRequirement)
      send("NEEDS_IVF_REQUIREMENT")
    else send("")
  }

  return (
    <>
      <Progress
        numSteps={3}
        step={3}
        label={intl.formatMessage({ defaultMessage: "Additional Info" })}
        inactiveStepBackgroundColor="#DBE6EE"
      />
      <BackArrow onClick={back} />
      <Form onSubmit={onSubmit}>
        <Stack minHeight="500px" paddingTopBottom="huge">
          <H2 id="step-heading">
            <FormattedMessage defaultMessage="Anything else you'd like to share?" />
          </H2>
          <CompleteFormTextArea
            aria-labelledby="step-heading"
            onChange={handleChange}
            name={""}
            placeholder={intl.formatMessage({ defaultMessage: "Type your message here..." })}
            maxLength={2048}
          />
          {showErrorMessage && (
            <>
              <H3 variant="primary" stack="tiny">
                <FormattedMessage
                  defaultMessage="We're having trouble. Please try submitting again or <link>contact us</link> for help."
                  values={{
                    link: (linkContent) => (
                      <Link fontWeight="inherit" target="_blank" href="/talk-to-carrot" color="inherit">
                        {linkContent}
                      </Link>
                    )
                  }}
                />
              </H3>
            </>
          )}
        </Stack>

        <FlexContainer
          direction={["column", null, "row"]}
          justifyContent="space-between"
          gap="16px"
          paddingTopBottom="huge"
          alignItems={["start", null, "center"]}
        >
          <Stack alignItems={["start", "center"]} spacing="tiny" direction={["column", "row"]}>
            <FormControlLabel
              label={
                <Text
                  id="terms-acknowledgement"
                  as="p"
                  sx={{ lineHeight: "150%", maxInlineSize: "300px" }}
                  size="14px"
                  color="black60"
                >
                  <FormattedMessage defaultMessage="I acknowledge that, depending on location and other factors," />
                  <StyledZendeskLink
                    target="_blank"
                    supportCenterLink={SupportCenterLinks.ZENDESK_TAXABILITY_GUIDE}
                    color="inherit"
                  >
                    <FormattedMessage defaultMessage="taxes may apply." />
                  </StyledZendeskLink>
                </Text>
              }
              control={
                <Checkbox
                  aria-labelledby="terms-acknowledgement"
                  value="acknowledged"
                  checked={taxAcknowledged}
                  onChange={(e) => {
                    setTaxAcknowledged(e.target.checked)
                  }}
                />
              }
            />
          </Stack>
          <Container width="100%" sx={{ flex: 0, textAlign: "end" }}>
            <Button
              variant="contained"
              color="primary"
              size="medium"
              type="submit"
              disabled={isSubmitting || !taxAcknowledged}
            >
              <FormattedMessage defaultMessage="Submit" />
            </Button>
          </Container>
        </FlexContainer>
      </Form>
    </>
  )
}
