import React, { useEffect, useState } from "react"
import { AirwallexPantokenResponse } from "./hooks/useCreateAirwallexPanToken"
import { useIntl } from "react-intl"
import { Box, Progress } from "@carrotfertility/carotene-core"
import styles from "./AirwallexCardDetailsStyle.json"
import Settings from "../../../../../utils/CarrotConfig"

interface AirwallexCardDetailsProps {
  onError: (error: any) => void
  isLoading: boolean
  panToken: AirwallexPantokenResponse
}
export const AirwallexCardDetails: React.FC<AirwallexCardDetailsProps> = ({ onError, isLoading, panToken }) => {
  const [loaded, setLoaded] = useState(false)
  const [height, setHeight] = useState(0)

  useEffect(() => {
    if (!panToken?.card_id) return
    function handleMessage(event: { origin: string | string[]; data: { type: string; error: any; height: number } }) {
      if (!event.origin.includes("airwallex.com")) return
      if (event?.data.type === `${panToken?.card_id}:details:loaded`) setLoaded(true)
      if (event?.data.type === `${panToken?.card_id}:details:error`) {
        setLoaded(true)
        onError("Error loading card details")
        reportError(`event?.data.error:${event?.data.error}`)
      }
      if (event?.data.type === `${panToken?.card_id}:details:resize`) {
        //Had to add 30 to get it to not scroll :-/
        setHeight(event?.data.height)
      }
    }

    window.addEventListener("message", handleMessage)
    return () => window.removeEventListener("message", handleMessage)
  }, [panToken, onError])

  const intl = useIntl()

  if (isLoading || !panToken?.token) {
    return (
      <Box width={"100%"} display="flex" alignItems="center" justifyContent="center" height="100px">
        <Progress />
      </Box>
    )
  }

  const hash = {
    token: panToken.token,
    langKey: "en",
    rules: {
      ...styles
    }
  }

  const hashURI = encodeURIComponent(JSON.stringify(hash))

  const iframeSrc = `https://${Settings.AIRWALLEX_DOMAIN}/issuing/pci/v2/${panToken?.card_id}/details#${hashURI}`

  return (
    <>
      {!loaded && (
        <Box width={"100%"} display="flex" alignItems="center" justifyContent="center" height="100px">
          <Progress />
        </Box>
      )}
      <iframe
        title={intl.formatMessage({ defaultMessage: "card details" })}
        style={{
          height: loaded ? `${height}px` : "100%",
          display: loaded ? "block" : "none",
          width: "100%"
        }}
        src={iframeSrc}
      />
    </>
  )
}
