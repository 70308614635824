import { useCurrentUser } from "components/context/user/UserContext"
import React from "react"
import { useIntl } from "react-intl"
import { Box, CloseIcon, Dialog, IconButton, useTheme } from "@carrotfertility/carotene-core"

type TimeTapSchedulerProps = {
  reasonId: number
  locationId: number | undefined
}

type DialogTimeTapSchedulerProps = TimeTapSchedulerProps & {
  open: boolean
  setOpen: (value: boolean) => void
}

export function DialogTimeTapScheduler({
  reasonId,
  locationId,
  open,
  setOpen
}: DialogTimeTapSchedulerProps): JSX.Element {
  const intl = useIntl()
  const theme = useTheme()
  const onClose = () => setOpen(false)
  return (
    <Dialog
      open={open}
      onClose={onClose}
      PaperProps={{
        sx: {
          width: "90%",
          height: "90%",
          maxHeight: "80vh",
          [theme.breakpoints.down("lg")]: {
            maxWidth: "800px"
          },
          [theme.breakpoints.up("lg")]: {
            maxWidth: "1000px"
          }
        }
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-end"
        }}
      >
        <IconButton aria-label={intl.formatMessage({ defaultMessage: "close" })} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </Box>
      <Box height="90%">
        <TimeTapScheduler reasonId={reasonId} locationId={locationId} />
      </Box>
    </Dialog>
  )
}

export function TimeTapScheduler({ reasonId, locationId }: TimeTapSchedulerProps): JSX.Element {
  const { employeeId, firstName, lastName, preferredEmail } = useCurrentUser()
  const intl = useIntl()
  const baseUrl = "https://www.timetap.com/emb/394425/"
  const timeTapUrl = new URL(baseUrl)
  timeTapUrl.searchParams.set("rid", String(reasonId))
  if (locationId) {
    timeTapUrl.searchParams.set("lid", String(locationId))
  }
  timeTapUrl.searchParams.set("clientFirstName", firstName)
  timeTapUrl.searchParams.set("clientLastName", lastName)
  timeTapUrl.searchParams.set("clientEmail", preferredEmail)
  timeTapUrl.searchParams.set("CF_CARROT_ID", employeeId)

  timeTapUrl.hash = `/?${new URLSearchParams([["locale", intl.locale]]).toString()}`

  return (
    <iframe
      src={timeTapUrl.toString()}
      width="100%"
      height="100%"
      title={intl.formatMessage({ defaultMessage: "Schedule a chat" })}
    />
  )
}
