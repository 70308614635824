import React, { useEffect } from "react"

import TwoColumnRightPrimary from "components/views/atoms/layouts/TwoColumnRightPrimary"
import { GuidanceElement } from "../../lib/contentful/components/GuidanceElement"
import { talkToCarrotSource } from "../../components/talk-to-carrot/send-message/SendMessageUtil"
import { SendMessage } from "../../components/talk-to-carrot/send-message/SendMessage"
import { TalkToCarrotLegal } from "../../components/talk-to-carrot/TalkToCarrotLegal"
import { useCurrentUser } from "components/context/user/UserContext"
import { useHistory } from "react-router-dom"
import { Title } from "services/page-title/Title"
import { goToHelpCenterLegacy } from "carrot-api/HelpCenterClient"
import { useIntl } from "react-intl"
import { useSendMessageTranslator } from "components/talk-to-carrot/send-message/useSendMessageTranslator"
import { PageLayout } from "features/global-ui/page-layout"
import { PageBreadcrumbHeader } from "features/global-ui/page-header"
import { Breadcrumbs, Breadcrumb } from "@carrotfertility/carotene-core"
import { TypePageGuidanceSkeleton } from "types/contentful"
import { EntryWithoutUnresolvableLinks } from "utils/ContentTypes"

export function SendMessagePage(): JSX.Element {
  const { isInAppLockdown } = useCurrentUser()
  const history = useHistory()
  const intl = useIntl()
  const titleMessage = intl.formatMessage({
    defaultMessage: "Send a message"
  })
  const helpBreadcrumbTitle = intl.formatMessage({
    defaultMessage: "Help center"
  })
  const messageBreadcrumbTitle = intl.formatMessage({
    defaultMessage: "Send a message"
  })
  useEffect(() => {
    if (isInAppLockdown) {
      history.push("/get-help")
    }
  }, [history, isInAppLockdown])

  const translator = useSendMessageTranslator()
  const { heading: title, description } = translator.getTranslationsForTopic("send-a-message-page-as-string")
  const guidance = (
    <GuidanceElement
      guidance={{ fields: { title, description } } as EntryWithoutUnresolvableLinks<TypePageGuidanceSkeleton>}
    />
  )

  return (
    <>
      <Title title={titleMessage} />
      <PageLayout
        header={
          <PageBreadcrumbHeader
            breadcrumbs={
              <Breadcrumbs>
                <Breadcrumb component="button" onClick={() => goToHelpCenterLegacy()}>
                  {helpBreadcrumbTitle}
                </Breadcrumb>
                <Breadcrumb currentPage>{messageBreadcrumbTitle}</Breadcrumb>
              </Breadcrumbs>
            }
          />
        }
        footer={<TalkToCarrotLegal />}
      >
        <TwoColumnRightPrimary
          secondaryContent={guidance}
          primaryContent={<SendMessage source={talkToCarrotSource.SEND_A_MESSAGE_PAGE} />}
        />
      </PageLayout>
    </>
  )
}
