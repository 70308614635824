import { Box, Button } from "@carrotfertility/carotene-core"
import React from "react"
import { FormattedMessage } from "react-intl"
import { useLocation } from "react-router"
import { useToggle, useDidMount } from "utils/Hooks"
import { AirwallexCard } from "./AirwallexCard"

export const AirwallexVirtualCardDetailsAccordion = () => {
  const location = useLocation()
  const [showCardDetails, toggleShowCardDetails] = useToggle(false)
  const [showButton, toggleShowButton] = useToggle(true)

  useDidMount(() => {
    const cardDetailsShouldStartOpen = location.hash === "#card-details"
    toggleShowCardDetails(cardDetailsShouldStartOpen)
  })

  function closeCardDetails() {
    toggleShowCardDetails()
    toggleShowButton()
  }

  return (
    <div>
      <Box paddingBottom={(theme) => theme.tokens.spacing.lg} hidden={showCardDetails}>
        {showButton && (
          <Button
            onClick={() => {
              toggleShowCardDetails()
              toggleShowButton()
            }}
            variant="outlined"
            color="secondary"
            sx={(theme) => ({ marginBlockStart: theme.tokens.spacing.md })}
          >
            <FormattedMessage defaultMessage="Access card details" />
          </Button>
        )}
      </Box>
      {showCardDetails && <AirwallexCard hideCardDetails={closeCardDetails} />}
    </div>
  )
}
