import React, { useContext, useReducer } from "react"
import { CarrotErrorCodes } from "utils/CarrotErrors"
import { reportError } from "utils/ErrorReporting"
import { useSignUpFlow } from "../../context/signup/SignUpFlowContext"
import { getHeap } from "../../../utils/heap"
import { carrotClient } from "utils/CarrotClient"

interface SignUpGetHelpReducerState {
  errorMessage: string
  inputsEnabled: boolean
  showSubmittingLabel: boolean
  shake: boolean
  success: boolean
}

interface SignUpGetHelpProviderProps extends SignUpGetHelpReducerState {
  onSubmit: ({
    email,
    firstName,
    lastName,
    employer,
    dateOfBirth
  }: {
    email: string | string[]
    firstName: string
    lastName: string
    employer: string
    dateOfBirth: Date
  }) => Promise<void>
}

export type SendTalkToCarrotSignUpMessagePayload = {
  message: string
  topic: string
  sendFromPartner: boolean
  email: string
  name: string
}

const SignUpGetHelpContext = React.createContext<SignUpGetHelpProviderProps>(null)

export const useSignUpGetHelp = () => useContext(SignUpGetHelpContext)

const actionTypes = {
  error: "ERROR",
  success: "REQUEST_SUCCESS",
  start: "REQUEST_START"
}

// @ts-expect-error TS7006
function signUpGetHelpReducer(state, action): SignUpGetHelpReducerState {
  switch (action.type) {
    case actionTypes.error: {
      return {
        ...state,
        inputsEnabled: true,
        shake: true,
        errorMessage: action.payload.errorCode
      }
    }
    case actionTypes.success: {
      return {
        ...state,
        inputsEnabled: false,
        success: true
      }
    }
    case actionTypes.start: {
      return {
        ...state,
        inputsEnabled: true,
        showSubmittingLabel: true,
        shake: false
      }
    }
    default: {
      throw new Error(`Unhandled type: ${action.type}`)
    }
  }
}

// @ts-expect-error TS7031
export function SignUpGetHelpProvider({ children, onGetHelpSuccessfulRequest }) {
  const [state, dispatch] = useReducer(signUpGetHelpReducer, {
    inputsEnabled: true,
    shake: false,
    showSubmittingLabel: false,
    errorMessage: null,
    success: false
  })
  const { handleSaveMemberInfoInMemory } = useSignUpFlow()

  // @ts-expect-error TS7006
  const setFormError = (errorCode) => dispatch({ type: actionTypes.error, payload: { errorCode } })
  const startRequest = () => dispatch({ type: actionTypes.start })
  const endSuccessfulRequest = () => dispatch({ type: actionTypes.success })

  // @ts-expect-error TS7006
  const setErrorState = (errorCode) => {
    setFormError(errorCode)
  }

  // @ts-expect-error TS7031
  const onSubmit = async ({ email, firstName, lastName, employer, dateOfBirth }) => {
    getHeap().track("NoEmailOnboarding", { EventName: "submit support request" })
    startRequest()
    try {
      await carrotClient.sendTalkToCarrotSignUpMessage({
        message: `INFORMATION ENTERED -- (email: ${email}), (first name: ${firstName}), (last name: ${lastName}),
        (date of birth: ${dateOfBirth.format("YYYY-MM-DD")}), (company name: ${employer})`,
        topic: "We can't find the requested employee record by first name, last name, and DOB",
        sendFromPartner: false,
        email,
        name: `${firstName} ${lastName}`
      })
      handleSaveMemberInfoInMemory({ email, dateOfBirth, firstName, lastName })
    } catch (error) {
      reportError(error)
      setErrorState(CarrotErrorCodes.UNEXPECTED_ERROR)
      return
    }
    onGetHelpSuccessfulRequest()
    endSuccessfulRequest()
  }

  return <SignUpGetHelpContext.Provider value={{ ...state, onSubmit }}>{children}</SignUpGetHelpContext.Provider>
}
