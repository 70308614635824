import React, { useEffect } from "react"
import { FlexContainer, SlidingDotsLoader } from "@carrotfertility/carotene"
import { ProviderDetails } from "./ProviderDetails"
import { Provider } from "../../types/Providers"
import { PaginationBar } from "./PaginationBar"
import { useHistory } from "react-router-dom"
import { getHeap } from "../../utils/heap"
import { ProviderFinderParam, providerFinderRootPath } from "../../utils/providerFinderSearchUrl"
import { HeapPropertyValue } from "utils/heap"
import { Benefit } from "lib/carrot-api/types/Benefit"
import { FormattedMessage } from "react-intl"
import { Alert, Box, Divider, Link, Stack, Typography, useTheme } from "@carrotfertility/carotene-core"
import { Loading } from "../Loading"
import useGetCustomNetworks from "pages/provider-finder/hooks/useGetCustomNetworks"
import {
  getProviderNetworkConfig,
  useProviderNetworkForCurrentUser
} from "components/views/app/navigation/hooks/useHasProviderFinder"

const ITEMS_PER_PAGE = 25

export function ResultList({ providers, benefit }: { providers: Provider[]; benefit: Benefit }): JSX.Element {
  const history = useHistory()

  const params = new URLSearchParams(history.location.search)
  const page = Number(params.get(ProviderFinderParam.Page))
  const searchLocation = params.get(ProviderFinderParam.Search)
  const carrotPartnersOnly = params.get(ProviderFinderParam.CarrotPartner) ? "true" : "false"

  const providerCount = providers.length
  const pageCount = Math.ceil(providerCount / ITEMS_PER_PAGE)

  const startIndex = (page - 1) * ITEMS_PER_PAGE
  const endIndex = startIndex + ITEMS_PER_PAGE - 1
  const currentItems = providers.slice(startIndex, endIndex + 1)
  const carrotPartnerCount = currentItems.filter((p) => p.carrotPartner).length

  const { data, isLoading: isCustomNetworksLoading } = useGetCustomNetworks()
  const isCustomNetworks = data?.hasCustomNetworks

  const theme = useTheme()

  const providerNetworkResponse = useProviderNetworkForCurrentUser()
  const providerNetworkConfig =
    !providerNetworkResponse.isLoading &&
    !providerNetworkResponse.isError &&
    getProviderNetworkConfig(providerNetworkResponse.data.providerNetworkIdentifier)
  const shouldDisplayInNetworkStatusBanner = providerNetworkConfig?.displayInNetworkStatusBanner

  useEffect(
    function trackProviderSearchResultsRendered() {
      const customProperties: Record<string, HeapPropertyValue> = {
        "Provider Count": providerCount,
        "Carrot Partners Filter on (true/false)": carrotPartnersOnly,
        "Total Page Count": pageCount,
        "Results per Page": ITEMS_PER_PAGE,
        "Page Number": page
      }
      if (carrotPartnersOnly === "false") {
        customProperties["Carrot Partner Count"] = carrotPartnerCount
      }
      getHeap().track("Provider Search Results Rendered", customProperties)
    },
    [providerCount, carrotPartnersOnly, pageCount, carrotPartnerCount, page]
  )

  function handlePageChange(event: React.ChangeEvent, page: number): void {
    const params = new URLSearchParams(history.location.search)
    params.set(ProviderFinderParam.Page, page.toString())
    history.push(`${providerFinderRootPath}/providers?${params}`)
  }

  const firstPageResultRank = startIndex + 1
  const lastProviderRank = Math.min(endIndex + 1, providerCount)

  const hasMissingJourneyCoverage = Object.values(benefit.journeys).some((journey) => {
    return journey.hasEligibleExpenses === false
  })

  const isProMember = benefit.program.type === "PRO"

  if (isCustomNetworksLoading) {
    return <Loading />
  }

  return (
    <Box display="flex" flexDirection="column" padding="0 0 96px 0">
      <Stack gap={(theme) => theme.tokens.spacing.lg} marginBottom={(theme) => theme.tokens.spacing.xl}>
        {isCustomNetworks && (
          <Alert>
            <FormattedMessage defaultMessage="These are Carrot providers that are in-network with your health plan. Always check with your health plan that the services you are looking for are covered." />
          </Alert>
        )}
        {hasMissingJourneyCoverage && isProMember && !isCustomNetworks && (
          <Alert severity="info">
            <FormattedMessage
              defaultMessage="Always check your <link>benefit guide</link> to ensure the care and services you are seeking are covered."
              values={{
                link: (linkContent) => (
                  <Link href={"/your-benefit-guide/coverage"} fontSize="inherit" color="inherit">
                    {linkContent}
                  </Link>
                )
              }}
            />
          </Alert>
        )}
        {shouldDisplayInNetworkStatusBanner && (
          <Alert severity="info">
            <FormattedMessage defaultMessage="Because a provider's in-network status can change, always verify with your plan before receiving care." />
          </Alert>
        )}
      </Stack>

      <Box display="flex" justifyContent="flex-end" marginBottom={(theme) => theme.tokens.spacing.md}>
        <Typography variant="body2" color={(theme) => theme.palette.text.secondary}>
          <FormattedMessage
            defaultMessage="{providerCount} results"
            values={{
              providerCount: providerCount
            }}
          />
        </Typography>
      </Box>
      <Stack
        sx={{ "> li::before": { display: "none" } }}
        component="ol"
        direction="column"
        divider={<Divider />}
        gap={theme.spacing(theme.tokens.spacing.lg)}
      >
        {currentItems && currentItems.length ? (
          currentItems.map((provider, index) => (
            <Box component="li" key={provider.providerUuid}>
              <ProviderDetails
                provider={provider}
                rank={startIndex + index + 1}
                searchLocation={searchLocation}
                benefit={benefit}
              />
            </Box>
          ))
        ) : (
          <FlexContainer height="180px" justifyContent="center" alignItems="center">
            <SlidingDotsLoader />
          </FlexContainer>
        )}
      </Stack>
      <Box
        display="flex"
        justifyContent="center"
        width="100%"
        paddingTop={(theme) => theme.spacing(theme.tokens.spacing.xl)}
        paddingBottom={(theme) => theme.spacing(theme.tokens.spacing.xl)}
      >
        <Typography variant="body2" color={(theme) => theme.palette.text.tertiary}>
          <FormattedMessage
            defaultMessage="{firstPageResultRank}-{lastProviderRank} of {providerCount}"
            values={{
              firstPageResultRank: firstPageResultRank,
              lastProviderRank: lastProviderRank,
              providerCount: providerCount
            }}
          />
        </Typography>
      </Box>
      <PaginationBar count={pageCount} onChange={handlePageChange} page={page} />
    </Box>
  )
}
