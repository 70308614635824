import { MerchantValidModel, RegisteringUserModel, ReimbursementDetailsModel } from "lib/carrot-api/Models"
import Helpers from "utils/Helpers"
import { fetchWrapper } from "lib/FetchWrapper/FetchWrapper"
// @ts-expect-error TS7016
import { v4 as uuid } from "uuid"
import { CarrotErrorCodes } from "utils/CarrotErrors"
import { reportError } from "utils/ErrorReporting"
import { HttpErrors } from "utils/HttpErrors"
import { AboutYou, CarrotPlan, CarrotPlanEmployeeSettings, CarrotPlanStepRequest } from "../../types/carrotPlanTypes"
import ResponseError from "../../types/responseError"
import { HttpStatusCodes } from "../../utils/HttpStatusCodes"
import { BenefitEnrollmentStatus } from "../../types/benefitEnrollmentStatus"
import { UpdatedThread } from "../../components/messaging/inAppMessagingTypes"
import { MessagingAuthResponse, SupportCenterUrlResponse } from "../../types/zendeskTypes"
import { CreateAccount } from "./types/CreateAccount"
import { UserConditionSubset, UserInfo } from "./types/UserInfo"
import { SendTalkToCarrotMessagePayload } from "../../components/talk-to-carrot/send-message/SendMessage"
import { SendTalkToCarrotSignUpMessagePayload } from "../../components/signup-get-help/context/SignUpGetHelpContext"
import { DoulaAttestationRequest } from "components/doula-attestation/utils/doulaAttestationTypes"
import { MemberInternationalAddress, MemberUSAddress } from "./types/Address"
import { BankDetailsData } from "./types/Bank"
import { Benefit } from "./types/Benefit"
import { ProviderNetwork } from "./ProviderFinderClient"
import { PhoneSupportNumber } from "./types/PhoneNumber"
import { CountryCode } from "content/CountryCode"

// @ts-expect-error TS7006
async function processJsonResponse<T>(response, correlationId): Promise<T> {
  if (response.status === HttpStatusCodes.OK) {
    const responseText = await response.text()
    return JSON.parse(responseText)
  }
  throw new ResponseError(response, correlationId)
}

function buildRequestOptions(method: string, body?: any): { options: RequestInit; correlationId: string } {
  const correlationId = uuid()
  const headers = new Headers()
  headers.append("X-Request-ID", correlationId)
  headers.append("X-React-App-Version", process.env.REACT_APP_VERSION || "local")
  headers.append("Content-Type", "application/json")

  return {
    options: {
      credentials: "include",
      body: body ? JSON.stringify(body) : null,
      headers,
      method: method
    },
    correlationId
  }
}

// @ts-expect-error TS7006
async function getResponseKeyValues(response, correlationId) {
  if (response.status === HttpStatusCodes.OK) {
    const responseText = await response.text()
    return JSON.parse(responseText, function (key, value) {
      /**
       *  Here we've utilized the 2nd arg of JSON.parse (a reviver function) in order to align this response signature
       *  to the signature of the {@link BenefitConfiguration} type. See also the benefitConfiguration reducer to
       *  observe a similar transformation of response keys.
       */
      if (key === "features") {
        this.featureConfig = value
      } else if (key === "coverages") {
        this.coverageConfig = value
      } else {
        return value
      }
    })
  }
  throw new ResponseError(response, correlationId)
}

const clientMethods = {
  // @ts-expect-error TS7006
  apiV2PostJson: async function (path, payload) {
    const headers = new Headers()
    headers.append("Content-Type", "application/json")
    const body = JSON.stringify(payload)
    const response = await fetchWrapper(`${this.baseUrl}/api/v2/${path}`, {
      credentials: "include",
      body,
      headers,
      method: "POST"
    })

    try {
      return JSON.parse(await response.text())
    } catch {
      return null
    }
  },

  // @ts-expect-error TS7006
  apiV2RequestWithRedirect: async function (request) {
    let response
    try {
      response = await request()
    } catch (error) {
      if (error.name === HttpErrors.UNAUTHORIZED) {
        Helpers.browserRedirect(`${window.location.pathname}?errorCode=${CarrotErrorCodes.NOT_AUTHENTICATED}`)
        return
      }
      throw error
    }
    return response
  },

  // @ts-expect-error TS7006
  apiV2JsonWithRedirect: async function (path, method, payload = null) {
    return this.apiV2RequestWithRedirect(async () => {
      const headers = new Headers()
      headers.append("Content-Type", "application/json")
      const body = payload ? JSON.stringify(payload) : null

      return fetchWrapper(`${this.baseUrl}/api/v2/${path}`, {
        method,
        headers,
        body,
        credentials: "include"
      })
    })
  },

  // @ts-expect-error TS7006
  apiV2GetJson: async function (path) {
    const headers = new Headers()
    headers.append("Content-Type", "application/json")
    const response = await fetchWrapper(`${this.baseUrl}/api/v2/${path}`, {
      method: "GET",
      headers,
      credentials: "include"
    })

    const responseText = await response.text()
    return JSON.parse(responseText)
  },

  // @ts-expect-error TS7006
  // eslint-disable-next-line no-restricted-syntax -- This is the user's preferredLocale based on their selection in the language menu on Sign Up pages. Carrot-app's LocaleService will then determine if they can use the selected language once they sign up.
  signUp: async function (email, preferredLocale) {
    // eslint-disable-next-line no-restricted-syntax
    return this.apiV2PostJson("sign-up/verify", { email, preferredLocale })
  },

  // @ts-expect-error TS7006
  // eslint-disable-next-line no-restricted-syntax -- This is the user's preferredLocale based on their selection in the language menu on Sign Up pages. Carrot-app's LocaleService will then determine if they can use the selected language once they sign up.
  signUpFirstLastDateOfBirth: async function (email, firstName, lastName, dateOfBirth, preferredLocale) {
    // eslint-disable-next-line no-restricted-syntax
    return this.apiV2PostJson("sign-up/find-account", { email, firstName, lastName, dateOfBirth, preferredLocale })
  },

  // @ts-expect-error TS7006
  // eslint-disable-next-line no-restricted-syntax -- This is the user's preferredLocale based on their selection in the language menu on Sign Up pages. Carrot-app's LocaleService will then determine if they can use the selected language once they sign up.
  signUpExternalEmployeeId: async function (externalEmployeeId, parentCompanyId, dateOfBirth, preferredLocale) {
    return this.apiV2PostJson("sign-up/find-account-by-employee-id", {
      parentCompanyId,
      externalEmployeeId,
      dateOfBirth,
      // eslint-disable-next-line no-restricted-syntax
      preferredLocale
    })
  },

  // @ts-expect-error TS7006
  signUpAddEmailToAccount: async function (email, firstName, lastName, dateOfBirth) {
    return this.apiV2PostJson("sign-up/email", { email, firstName, lastName, dateOfBirth })
  },

  signUpAddEmailToAccountByExternalEmployeeId: async function (
    email: any,
    externalEmployeeId: any,
    parentCompanyId: any,
    dateOfBirth: any
  ) {
    return this.apiV2PostJson("sign-up/external-id-add-email", {
      email,
      externalEmployeeId,
      parentCompanyId,
      dateOfBirth
    })
  },

  // @ts-expect-error TS7006
  samlNoEmailOnboardingAddEmailToAccount: async function (email) {
    return this.apiV2PostJson("sign-up/saml-no-email-onboarding-add-email", email)
  },

  IsValidSamlNoEmailOnboardingMember: async function () {
    return this.apiV2GetJson("sign-up/is-valid-saml-no-email-onboarding-member")
  },

  logout: async function () {
    return this.apiV2PostJson("auth/logout", {})
  },

  // @ts-expect-error TS7006
  sendPartnerInvite: async function (isConsentChecked) {
    return this.apiV2PostJson("partner/send-invite", { isConsentChecked })
  },

  resendPartnerInvite: async function () {
    return this.apiV2PostJson("partner/resend-invite")
  },

  getPartnerInviteStatus: async function () {
    return this.apiV2GetJson("partner/status", {})
  },

  getPartnerEmailOptIn: async function () {
    return this.apiV2GetJson("partner/email-opt-in")
  },

  // @ts-expect-error TS7006
  updatePartnerEmailOptIn: function (partnerEmailOptIn) {
    return this.apiV2JsonWithRedirect("partner/email-opt-in", "PATCH", { partnerEmailOptIn })
  },

  sendTalkToCarrotMessage: async function (payload: SendTalkToCarrotMessagePayload) {
    return this.apiV2PostJson("talk-to-carrot/send-talk-to-carrot-message", payload)
  },

  sendTalkToCarrotSignUpMessage: async function (payload: SendTalkToCarrotSignUpMessagePayload) {
    return this.apiV2PostJson("talk-to-carrot-signup/get-help", payload)
  },

  // @ts-expect-error TS7006
  lookup: async function (email) {
    return await this.apiV2PostJson("users/lookup", { email })
  },

  getIsUserLoggedIn: async function () {
    return this.apiV2GetJson("users/is-logged-in")
  },

  // @ts-expect-error TS7006
  login: async function (email, password) {
    return await this.apiV2PostJson("auth/login", { email, password })
  },

  // @ts-expect-error TS7006
  validateResetPasswordGuid: async function (passwordResetGuid) {
    return this.apiV2PostJson("auth/validate-reset-guid", {
      passwordResetGuid
    })
  },

  // @ts-expect-error TS7006
  resetPassword: async function (password, confirmPassword, passwordResetGuid) {
    return this.apiV2PostJson("auth/reset-password", {
      password,
      confirmPassword,
      passwordResetGuid
    })
  },

  sendPersonalEmailVerificationEmail: async function () {
    return await this.apiV2PostJson("email-verification/send-verification-email", null)
  },

  // @ts-expect-error TS7006
  verifyPersonalEmail: async function (personalEmailVerificationToken) {
    const response = await this.apiV2PostJson("email-verification/verify-email", { personalEmailVerificationToken })
    return response
  },

  getPersonalEmailVerified: async function () {
    return await this.apiV2GetJson("email-verification/personal-email-verified")
  },

  removePersonalEmail: async function () {
    return await this.apiV2JsonWithRedirect("email-verification/remove-personal-email", "DELETE")
  },

  // @ts-expect-error TS7006
  updatePersonalEmailAndVerification: async function (email) {
    return await this.apiV2JsonWithRedirect("email-verification/update-personal-email", "PATCH", { email })
  },

  submitCardExpense: async function (
    // @ts-expect-error TS7006
    fileGuids,
    // @ts-expect-error TS7006
    whoReceivedCare,
    // @ts-expect-error TS7006
    expenseJourneyType,
    // @ts-expect-error TS7006
    expenseJourneySubType,
    // @ts-expect-error TS7006
    relatedToStorageFees,
    // @ts-expect-error TS7006
    isStepchildAdoption,
    // @ts-expect-error TS7006
    isInfertility,
    // @ts-expect-error TS7006
    isMedicalNecessity,
    // @ts-expect-error TS7006
    attestationIsMedicallyNecessary,
    // @ts-expect-error TS7006
    attestationIsEligibilityRequirementMet,
    // @ts-expect-error TS7006
    attestationIsMedicallyRelated,
    // @ts-expect-error TS7006
    memberNotes,
    // @ts-expect-error TS7006
    expenseId
  ) {
    return this.apiV2JsonWithRedirect("statements", "POST", {
      fileGuids,
      whoReceivedCare,
      expenseJourneyType,
      expenseJourneySubType,
      relatedToStorageFees,
      isStepchildAdoption,
      isInfertility,
      isMedicalNecessity,
      attestationIsMedicallyNecessary,
      attestationIsEligibilityRequirementMet,
      attestationIsMedicallyRelated,
      memberNotes,
      expenseId
    })
  },

  submitExpense: async function (
    // @ts-expect-error TS7006
    fileGuids,
    // @ts-expect-error TS7006
    eligibleReimbursementAmount,
    // @ts-expect-error TS7006
    eligibleReimbursementAmountCurrencyCode,
    // @ts-expect-error TS7006
    whoReceivedCare,
    // @ts-expect-error TS7006
    expenseJourneyType,
    // @ts-expect-error TS7006
    expenseJourneySubType,
    // @ts-expect-error TS7006
    relatedToStorageFees,
    // @ts-expect-error TS7006
    isStepchildAdoption,
    // @ts-expect-error TS7006
    isInfertility,
    // @ts-expect-error TS7006
    isMedicalNecessity,
    // @ts-expect-error TS7006
    attestationIsMedicallyNecessary,
    // @ts-expect-error TS7006
    attestationIsEligibilityRequirementMet,
    // @ts-expect-error TS7006
    attestationIsMedicallyRelated,
    // @ts-expect-error TS7006
    memberNotes
  ) {
    return this.apiV2JsonWithRedirect("statements", "POST", {
      fileGuids,
      eligibleReimbursementAmount: eligibleReimbursementAmount,
      eligibleReimbursementAmountCurrencyCode: eligibleReimbursementAmountCurrencyCode,
      whoReceivedCare,
      expenseJourneyType,
      expenseJourneySubType,
      relatedToStorageFees,
      isStepchildAdoption,
      isInfertility,
      isMedicalNecessity,
      attestationIsMedicallyNecessary,
      attestationIsEligibilityRequirementMet,
      attestationIsMedicallyRelated,
      memberNotes
    })
  },

  // @ts-expect-error TS7006
  startPhoneVerification: async function (phoneNumber, countryCode) {
    return this.apiV2JsonWithRedirect("phoneVerification/start", "POST", {
      phoneNumber,
      countryCode
    })
  },

  // @ts-expect-error TS7006
  registrationPhoneStandardization: async function (phoneNumberWithCountryCode, countryCode) {
    return this.apiV2JsonWithRedirect("register/standardize-phone-number", "POST", {
      phoneNumberWithCountryCode,
      countryCode
    })
  },

  // @ts-expect-error TS7006
  registrationPhoneLocalization: async function (phoneNumberWithCountryCode, countryCode) {
    return this.apiV2JsonWithRedirect("register/localize-phone-number", "POST", {
      phoneNumberWithCountryCode,
      countryCode
    })
  },

  // @ts-expect-error TS7006
  completePhoneVerification: async function (phoneNumber, code, countryCode, allowsMarketingTexts) {
    return this.apiV2JsonWithRedirect("phoneVerification/complete", "POST", {
      phoneNumber,
      code,
      countryCode,
      allowsMarketingTexts
    })
  },

  requestCard: async function () {
    return this.apiV2JsonWithRedirect("card/requestcard", "POST")
  },

  // @ts-expect-error TS7006
  verifyPhoneNumber: async function (phoneNumber, code, countryCode) {
    return this.apiV2JsonWithRedirect("card/verification/verifyphonenumber", "POST", {
      phoneNumber,
      code,
      countryCode
    })
  },

  // @ts-expect-error TS7006
  createCardholder: async function (address1, address2, city, state, zip) {
    return this.apiV2JsonWithRedirect("card/createcardholder", "POST", {
      address1,
      address2,
      city,
      state,
      zip
    })
  },

  updateMemberCardAddress: async function (addressFields: MemberUSAddress | MemberInternationalAddress) {
    return this.apiV2JsonWithRedirect("card/updatememberaddress", "POST", addressFields)
  },

  // Registration Methods
  createAccount: async function (payload: CreateAccount) {
    return this.apiV2JsonWithRedirect(`register/${payload.guid}`, "POST", payload)
  },

  // @ts-expect-error TS7006
  checkEmailUniqueness: async function (email) {
    return this.apiV2PostJson("users/lookup-email", {
      email
    })
  },

  // @ts-expect-error TS7006
  validatePassword: async function (password) {
    return this.apiV2JsonWithRedirect(`register/verify-password`, "POST", {
      password
    })
  },

  // @ts-expect-error TS7031
  updatePassword: async function ({ currentPassword, newPassword, confirmNewPassword }) {
    return this.apiV2PostJson("auth/update-password", {
      currentPassword,
      newPassword,
      confirmNewPassword
    })
  },

  // @ts-expect-error TS7006
  getRegisteringUser: async function (guid) {
    try {
      const headers = new Headers()
      headers.append("Content-Type", "application/json")
      const response = await fetchWrapper(`${this.baseUrl}/api/v2/register/${guid}`, {
        method: "GET",
        headers,
        credentials: "include"
      })
      const responseText = await response.text()
      const responseJson = JSON.parse(responseText, function (key, value) {
        /**
         *  Here we've utilized the 2nd arg of JSON.parse (a reviver function) in order to align this response signature
         *  to the signature of the {@link BenefitConfiguration} type. See also the benefitConfiguration reducer to
         *  observe a similar transformation of response keys.
         */
        if (key === "features") {
          this.featureConfig = value
        } else if (key === "coverages") {
          this.coverageConfig = value
        } else {
          return value
        }
      })
      return Object.assign(new RegisteringUserModel(), responseJson)
    } catch (error) {
      if (error.name === HttpErrors.UNAUTHORIZED) {
        Helpers.browserRedirect(`/?errorCode=${CarrotErrorCodes.EMPLOYEE_ALREADY_REGISTERED}`)
        return
      }

      // Catching issue with CORS and 404s as well, which both are not actionable
      if ([HttpErrors.NOT_FOUND, "FetchError"].includes(error.name)) {
        Helpers.browserRedirect("/")
        return
      }
      reportError(error)
    }
  },

  // @ts-expect-error TS7006
  getRegisteringPartner: async function (guid) {
    const correlationId = uuid()
    const headers = new Headers()
    headers.append("Content-Type", "application/json")
    const response = await fetchWrapper(`${this.baseUrl}/api/v2/register/partner/${guid}`, {
      method: "GET",
      headers,
      credentials: "include"
    })
    return getResponseKeyValues(response, correlationId)
  },

  // @ts-expect-error TS7006
  createPartnerAccount: async function (guid, password, emailOptIn, agreeToTerms, agreeToPolicy, agreeToHealthData) {
    return this.apiV2JsonWithRedirect(`register/partner/${guid}`, "POST", {
      guid,
      password,
      emailOptIn,
      agreeToTerms,
      agreeToPolicy,
      agreeToHealthData
    })
  },

  // API V2 File Upload Request

  // @ts-expect-error TS7006
  uploadReimbursementFile: async function (file, guid: string, fileCategory: string) {
    const response = await this.apiV2RequestWithRedirect(async () => {
      const body = new FormData()
      body.append("file", file)
      body.append("guid", guid)
      body.append("fileCategory", fileCategory)

      return fetchWrapper(this.baseUrl + "/api/v2/statements/files", {
        method: "POST",
        body,
        credentials: "include"
      })
    })

    const responseJson = await response.json()
    // eslint-disable-next-line no-prototype-builtins
    if (!responseJson.hasOwnProperty("fileGuid")) {
      throw new Error(`Response does not have field "fileGuid": ${JSON.stringify(responseJson)}`)
    }
    return responseJson.fileGuid
  },

  uploadAdditionalFiles: async function (expenseId: number, fileGuids: string[], memberNotes: string) {
    return this.apiV2JsonWithRedirect(`statements/${expenseId}`, "PUT", {
      fileGuids,
      memberNotes
    })
  },

  activateCard: function () {
    return this.apiV2JsonWithRedirect("card/activate", "POST", {})
  },

  getCardDetails: async function () {
    const response = await this.apiV2JsonWithRedirect("card/details", "GET")
    return await response.json()
  },

  getCurrentUser: async function (): Promise<UserInfo> {
    const headers = new Headers()
    headers.append("Content-Type", "application/json")
    const response = await fetchWrapper(`${this.baseUrl}/api/v2/users/me`, {
      method: "GET",
      headers,
      credentials: "include"
    })
    const responseText = await response.text()
    const responseJson = JSON.parse(responseText)
    return responseJson
  },

  getCurrentUserConditionsSubset: async function (): Promise<UserConditionSubset> {
    const { options, correlationId } = buildRequestOptions("GET")
    const response = await fetch(this._buildApiV2Url("users/me/conditions-subset"), options)
    return processJsonResponse(response, correlationId)
  },

  getCurrentUserRole: async function () {
    return await this.apiV2GetJson("users/role")
  },

  getMemberEnrollmentHistory: async function () {
    const headers = new Headers()
    headers.append("Content-Type", "application/json")
    const response = await fetchWrapper(`${this.baseUrl}/api/v2/users/enrollment-history`, {
      method: "GET",
      headers,
      credentials: "include"
    })
    const responseText = await response.text()
    const responseJson = JSON.parse(responseText)
    return responseJson
  },

  // @ts-expect-error TS7006
  checkIfMerchantValid: async function (token) {
    const headers = new Headers()
    headers.append("Content-Type", "application/json")
    const response = await fetchWrapper(`${this.baseUrl}/api/v2/merchant/isValid/${token}`, {
      method: "GET",
      headers
    })
    const responseText = await response.text()
    const responseJson = JSON.parse(responseText)
    return Object.assign(new MerchantValidModel(), responseJson)
  },

  // @ts-expect-error TS7006
  approveMerchant: async function (token) {
    const headers = new Headers()
    headers.append("Content-Type", "application/json")
    await fetchWrapper(`${this.baseUrl}/api/v2/merchant/approve/${token}`, {
      method: "POST",
      headers
    })
  },

  getReimbursementDetails: async function () {
    const headers = new Headers()
    headers.append("Content-Type", "application/json")
    const response = await fetchWrapper(`${this.baseUrl}/api/v2/users/me/reimbursement-details`, {
      method: "GET",
      headers,
      credentials: "include"
    })
    const responseText = await response.text()
    const responseJson = JSON.parse(responseText)
    return Object.assign(new ReimbursementDetailsModel(), responseJson)
  },

  verifyMemberInsurance: async function () {
    return this.apiV2PostJson("users/me/verify-member-insurance", null)
  },

  verifyPartnerInsurance: async function () {
    return this.apiV2PostJson("users/me/verify-partner-insurance", null)
  },

  updateUser: function (userInfo: Partial<UserInfo>): Promise<Response> {
    return this.apiV2JsonWithRedirect("users/me", "PATCH", userInfo)
  },

  // @ts-expect-error TS7006
  forgotPassword: async function (email) {
    return this.apiV2PostJson("users/forgotPassword", { email })
  },

  getSupportCenterUrl: async function (isRetry = false): Promise<SupportCenterUrlResponse> {
    let path = "users/zendesk/support-center-url"
    if (isRetry) {
      path += "?isRetry=true"
    }
    const response = await this.apiV2JsonWithRedirect(path, "GET")
    const responseText = await response.text()
    return JSON.parse(responseText)
  },

  getZendeskMessagingWidgetSettings: async function (): Promise<MessagingAuthResponse> {
    const response = await this.apiV2JsonWithRedirect("users/zendesk/messaging-widget", "GET")
    const responseText = await response.text()
    return JSON.parse(responseText)
  },

  getBenefitConfiguration: async function () {
    return await this.apiV2GetJson("users/me/benefit-configuration")
  },

  startRoutingInfoFlow: async function () {
    const response = await this.apiV2JsonWithRedirect("users/me/routing-info", "POST")
    return response
  },

  startJourneyChangeRoutingInfoFlow: async function () {
    const response = await this.apiV2JsonWithRedirect("users/me/routing-info/change-journey", "POST")
    return response
  },

  // @ts-expect-error TS7006
  updateRoutingInfo: async function (payload) {
    const response = await this.apiV2JsonWithRedirect("users/me/routing-info", "PATCH", payload)
    return response
  },

  getCarrotPlansRoutingInfo: async function () {
    const response = await this.apiV2GetJson("users/me/routing-info")
    return response
  },

  getMostRecentlyCompletedRoutingInfo: async function () {
    const response = await this.apiV2GetJson("users/me/routing-info/most-recently-completed")
    return response
  },

  getRegistrationJourney: async function () {
    const response = await this.apiV2GetJson("users/me/routing-info/registration-journey")
    return response
  },

  // @ts-expect-error TS7006
  startMedicalInfoFlow: async function (payload = null) {
    const response = await this.apiV2JsonWithRedirect("users/me/medical-info", "POST", payload)
    return response
  },

  // @ts-expect-error TS7006
  updateMedicalInfo: async function (payload) {
    const response = await this.apiV2JsonWithRedirect("users/me/medical-info", "PATCH", payload)
    return response
  },

  getCarrotPlansMedicalInfo: async function () {
    const response = await this.apiV2GetJson("users/me/medical-info")
    return response
  },

  getAboutYou: async function () {
    const response = await this.apiV2GetJson("users/me/about-you")
    return response
  },

  updateAboutYou: async function (aboutYouModel: Partial<AboutYou>) {
    return this.apiV2JsonWithRedirect("users/me/about-you", "PATCH", aboutYouModel)
  },

  getInAppMessagingThreads: async function () {
    return await this.apiV2GetJson("in-app-messaging/threads")
  },

  // @ts-expect-error TS7006
  createThread: async function (subject, message, viaFollowUpSourceId) {
    const response = await this.apiV2PostJson("in-app-messaging/threads", {
      subject,
      message,
      viaFollowUpSourceId: viaFollowUpSourceId ?? undefined
    })
    return response
  },

  // @ts-expect-error TS7006
  updateThread: async function (threadId, message): Promise<UpdatedThread> {
    const response = await this.apiV2JsonWithRedirect(`in-app-messaging/threads/${threadId}`, "PUT", {
      message
    })
    const responseText = await response.text()
    return JSON.parse(responseText)
  },

  // @ts-expect-error TS7006
  getThreadMessages: async function (threadId) {
    return await this.apiV2GetJson(`in-app-messaging/threads/${threadId}/messages`)
  },

  // @ts-expect-error TS7006
  patchThread: async function (threadId, payload) {
    return await this.apiV2JsonWithRedirect(`in-app-messaging/threads/${threadId}`, "PATCH", payload)
  },

  enrollUserBenefitEnrollment: async function () {
    const response = await this.apiV2JsonWithRedirect("users/me/benefit-enrollment", "POST")
    return response
  },

  getBenefitEnrollmentPreview: async function () {
    const response = await this.apiV2JsonWithRedirect("users/me/benefit-enrollment/preview", "GET")
    const responseText = await response.text()
    return JSON.parse(responseText)
  },

  getBenefitEnrollmentStatus: async function (): Promise<BenefitEnrollmentStatus> {
    const { options, correlationId } = buildRequestOptions("GET")
    const response = await fetch(this._buildApiV2Url("users/me/enrollment-status"), options)
    return await processJsonResponse<BenefitEnrollmentStatus>(response, correlationId)
  },

  deactivateMedicareEnrollment: async function (): Promise<void> {
    const { options, correlationId } = buildRequestOptions("PUT")
    const response = await fetch(this._buildApiV2Url("users/me/medicare-enrollment/deactivate"), options)
    if (response.status !== HttpStatusCodes.OK) {
      throw new ResponseError(response, correlationId)
    }
  },

  addMedicareEnrollment: function (medicareId: string): Promise<Response> {
    return this.apiV2PostJson("users/me/medicare-enrollment", { medicareId: medicareId })
  },

  unPoseAsMember: async function (): Promise<Response> {
    const { options, correlationId } = buildRequestOptions("POST")
    const response = await fetch(this._buildApiV2Url("member-pose/un-pose"), options)
    return await processJsonResponse(response, correlationId)
  },

  // Carrot plans
  getCarrotPlan: async function (): Promise<CarrotPlan> {
    const { options, correlationId } = buildRequestOptions("GET")
    const response = await fetch(this._buildApiV2Url("users/me/carrot-plan"), options)
    return processJsonResponse<CarrotPlan>(response, correlationId)
  },

  // @ts-expect-error TS7006
  republishCarrotPlan: async function (employeeId) {
    const response = await this.apiV2JsonWithRedirect("users/me/carrot-plan/republish", "POST", employeeId)
    return response
  },

  setDoneWithCarrot: async function () {
    const response = await this.apiV2PostJson("users/me/carrot-plan/settings/done-with-carrot", "POST")
    return response
  },

  requestJourneyChangeCarrotPlan: async function () {
    return await this.apiV2PostJson("users/me/carrot-plan/request-journey-change-carrot-plan", "POST")
  },

  requestNewCaseRateRenewalCarrotPlan: async function () {
    return await this.apiV2PostJson("users/me/carrot-plan/request-case-rate-renewal-carrot-plan", "POST")
  },

  setNonStaleCarrotPlanOnAnniversaryCheck: async function () {
    return await this.apiV2PostJson("users/me/carrot-plan/non-stale-unlock", "POST")
  },

  getHasCarrotPlanCreatedWithin90DaysOfUnlockAnniversary: async function () {
    return await this.apiV2GetJson("users/me/carrot-plan/carrot-plan-within-ninety-days-from-unlock-anniversary")
  },

  setCarrotPlanRenewalDismissed: async function () {
    return await this.apiV2PostJson("users/me/carrot-plan/case-rate-renewal-dismissed", "POST")
  },

  getCaseRateRenewalStatus: async function () {
    return await this.apiV2GetJson("users/me/carrot-plan/case-rate-renewal-status")
  },

  getDaysSinceLastUnlock: async function () {
    return await this.apiV2GetJson("users/me/carrot-plan/days-since-unlock")
  },

  startCarrotPlansEnrollment: function () {
    return this.apiV2JsonWithRedirect("users/me/carrot-plans/routing/start", "POST")
  },

  getCarrotPlanEmployeeSettings: async function (): Promise<CarrotPlanEmployeeSettings> {
    const { options, correlationId } = buildRequestOptions("GET")
    const response = await fetch(this._buildApiV2Url("users/me/carrot-plan/settings/employees"), options)
    return processJsonResponse<CarrotPlanEmployeeSettings>(response, correlationId)
  },
  updateCarrotPlanStep: async function ({
    stepId,
    completed,
    markedNotRelevant
  }: CarrotPlanStepRequest): Promise<void> {
    const correlationId = uuid()
    const headers = new Headers()
    headers.append("X-Request-ID", correlationId)
    headers.append("X-React-App-Version", process.env.REACT_APP_VERSION || "local")
    headers.append("Content-Type", "application/json")
    const body = JSON.stringify({
      stepId: stepId,
      completed: completed,
      markedNotRelevant: markedNotRelevant
    })
    await fetch(`${this.baseUrl}/api/v2/users/me/carrot-plan/steps/${stepId}`, {
      credentials: "include",
      body,
      headers,
      method: "POST"
    })
  },

  setMemberRequestedEmailForInitialCarrotPlanChat: async function () {
    const response = await this.apiV2JsonWithRedirect("users/me/carrot-plan/initial-carrot-plan-chat-requested", "POST")
    return response
  },

  setAcknowledgedCarrotPlansPopup: async function () {
    const response = await this.apiV2JsonWithRedirect("users/me/carrot-plan/popup", "POST")
    return response
  },

  _buildApiV2Url: function buildApiV2Url(path: string): string {
    return `${this.baseUrl}/api/v2/${path}`
  },

  triggerQleEnrollment: async function (): Promise<boolean> {
    const { options } = buildRequestOptions("POST")
    const response = await fetch(this._buildApiV2Url("enrollments/qle"), options)
    return response.ok
  },

  triggerInsuranceEdit: async function (): Promise<boolean> {
    const { options } = buildRequestOptions("POST")
    const response = await fetch(this._buildApiV2Url("enrollments/clear/insurance"), options)
    return response.ok
  },

  getBenefitEnrollment: async function () {
    const { options, correlationId } = buildRequestOptions("GET")
    const response = await fetch(this._buildApiV2Url("users/me/benefit-enrollment"), options)
    return await processJsonResponse(response, correlationId)
  },

  getZendeskMobileAuthToken: async function () {
    const { options } = buildRequestOptions("GET")
    const response = await this.apiV2RequestWithRedirect(async () =>
      fetchWrapper(this._buildApiV2Url("users/zendesk/mobile/support-center-mobile-auth-token"), options)
    )
    return response.text()
  },

  createZendeskTicketForCPMedicalQuestions: async function (stepId: number) {
    await this.apiV2PostJson(`users/me/carrot-plan/medical-questions-completed/${stepId}`, "POST")
  },

  getAirwallexScaAuthorizationCode: async function () {
    return this.apiV2GetJson("airwallex/authorization-code/sca")
  },

  createAirwallexPanToken: async function (scaToken: string) {
    return this.apiV2JsonWithRedirect("airwallex/issuing/pantoken", "POST", { scaToken })
  },

  markScaSetupAsCompleted: async function () {
    await this.apiV2PostJson("card/sca-setup-completed", null)
  },

  getBankDetailsSchema: async function () {
    const { options } = buildRequestOptions("GET")
    const response = await fetch(this._buildApiV2Url("bank-details/schema"), options)
    return response.json()
  },

  getBankDetails: async function (): Promise<BankDetailsData> {
    const { options, correlationId } = buildRequestOptions("GET")
    const response = await fetch(this._buildApiV2Url("users/me/bank-details"), options)

    return processJsonResponse(response, correlationId)
  },

  // @ts-expect-error TS7006
  updateBankDetails: async function (bankDetails) {
    const response = await this.apiV2JsonWithRedirect("users/me/bank-details", "PATCH", bankDetails)
    return response.json
  },

  getBenefit: async function (): Promise<Benefit> {
    const { options, correlationId } = buildRequestOptions("GET")
    const response = await fetch(this._buildApiV2Url("benefits/me"), options)

    return processJsonResponse(response, correlationId)
  },

  // This could probably benefit from ENUMS
  getCompanyComplianceConfigLineItemFilter: async function (): Promise<string> {
    const { options, correlationId } = buildRequestOptions("GET")
    const response = await fetch(this._buildApiV2Url("company-compliance-config/line-item-filter"), options)

    return processJsonResponse(response, correlationId)
  },

  getPhoneSupportNumber: async function ({
    companyId,
    countryCode
  }: {
    companyId: string
    countryCode: CountryCode
  }): Promise<PhoneSupportNumber> {
    const { options, correlationId } = buildRequestOptions("GET")
    const response = await fetch(this._buildApiV2Url(`phone-support/${companyId}/${countryCode}`), options)

    return processJsonResponse(response, correlationId)
  },

  createMemberAction: async function (memberActionTaken: string) {
    await this.apiV2PostJson(`member-action-tracking/add-member-action`, { memberActionTaken: memberActionTaken })
  },

  createRegistrationMemberAction: async function (registrationGuid: string, memberActionTaken: string) {
    await this.apiV2PostJson(`member-action-tracking/add-registration-member-action/${registrationGuid}`, {
      registrationGuid: registrationGuid,
      memberActionTaken: memberActionTaken
    })
  },

  getAllEnabledLocales: async function (): Promise<string[]> {
    return await this.apiV2GetJson("locales")
  },

  getEnabledLocalesForCurrentUser: async function (): Promise<string[]> {
    return await this.apiV2GetJson("locales/me")
  },

  getSignatureOrSurveyLinkClickExists: async function () {
    return this.apiV2GetJson("survey/get-signature-survey-event-exists")
  },

  getActiveSignatures: async function () {
    return this.apiV2GetJson("signatures/active")
  },

  getProviderNetworkForCurrentUser: async function (): Promise<ProviderNetwork> {
    return this.apiV2GetJson("provider-network/me")
  },

  createSurveyLinkView: async function () {
    return this.apiV2PostJson("survey/track-link-view?bannerVariant=1", {})
  },

  createSurveyLinkClick: async function () {
    return this.apiV2PostJson("survey/track-link-click?bannerVariant=1", {})
  },

  createSurveyBannerCloseClick: async function () {
    return this.apiV2PostJson("survey/track-close-click?bannerVariant=1", {})
  },

  sendDoulaAttestation: async function (request: DoulaAttestationRequest): Promise<void> {
    const { options, correlationId } = buildRequestOptions("POST", request)
    const response = await fetchWrapper(`${this.baseUrl}/api/v2/signature/send-doula-attestation`, options)
    if (response.status !== HttpStatusCodes.OK) {
      throw new ResponseError(response, correlationId)
    }
  },

  updatePushNotificationOptInSetting: async function (request: boolean): Promise<void> {
    const { options, correlationId } = buildRequestOptions("PUT", { pushNotificationsOptIn: request })
    const response = await fetchWrapper(`${this.baseUrl}/api/v2/users/me/push-notifications`, options)
    if (response.status !== HttpStatusCodes.OK) {
      throw new ResponseError(response, correlationId)
    }
  },

  getChannelId: async function (): Promise<number> {
    const { options, correlationId } = buildRequestOptions("GET")
    const response = await fetch(this._buildApiV2Url("users/me/channel"), options)
    if (response.status !== HttpStatusCodes.OK) {
      throw new ResponseError(response, correlationId)
    }
    return response.json()
  }
}

const CarrotClient = function (baseUrl: string) {
  this.baseUrl = baseUrl
} as any as { new (baseUrl: string): typeof clientMethods }
CarrotClient.prototype = clientMethods

export default CarrotClient
