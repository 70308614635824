import React, { ChangeEvent, ReactElement, useEffect, useState } from "react"
import { Button } from "@carrotfertility/carotene"
import { Autocomplete, Box, TextField, Breadcrumbs, Breadcrumb, useTheme, Stack } from "@carrotfertility/carotene-core"
import TwoColumnRightPrimary from "../views/atoms/layouts/TwoColumnRightPrimary"
import {
  StaticAssetID,
  getTalkToCarrotLanguageGuidance,
  getTalkToCarrotLanguages
} from "../../lib/contentful/contentfulClient"
import { GuidanceElement } from "../../lib/contentful/components/GuidanceElement"
import {
  CALENDLY_PRIMARY_COLOR,
  fieldIsValid,
  getNextLanguageAction,
  getPathForLanguage,
  getTTCLanguageOption,
  isAppointmentLink,
  isPhoneSupportNumber,
  isTilePage
} from "../../lib/contentful/utils/mappingUtils"
import { RootBreadcrumb, SmallHeaderOverride } from "./util/talkToCarrotMolecules"
import { LanguageOption } from "../dynamic-content/util/dynamicContent"
import { useHistory, Link as ReactRouterLink } from "react-router-dom"
import { useSelector } from "react-redux"
import { getEmployeeId } from "../../reducers/userInfo"
import { TalkToCarrotMultilingualImage } from "./TalkToCarrotMultilingualImage"
import { TalkToCarrotSelectOption } from "./TalkToCarrotSelectOption"
import { useIntlSort } from "utils/hooks/useIntlSort"
import { FormattedMessage, useIntl } from "react-intl"
import { toCondition } from "../../lib/contentful/utils/conditionUtils"
import { useDoesUserSatisfyConditions } from "services/contentful/conditional-gate"
import { useGetPhoneSupportNumberForUser } from "components/dynamic-content/hooks/useQueryPhoneSupport"
import { PopupModal, useCalendlyEventListener } from "react-calendly"

import { TalkToCarrotLegal } from "./TalkToCarrotLegal"
import { Title } from "services/page-title/Title"
import { Paths } from "utils/Paths"
import { PageLayout } from "features/global-ui/page-layout"
import { PageBreadcrumbHeader } from "features/global-ui/page-header"
import { getHeap } from "utils/heap"
import { useResponsiveTalkToCarrotComponent } from "./hooks/useResponsiveTalkToCarrotComponent"
import { EntryWithoutUnresolvableLinks } from "utils/ContentTypes"
import { TypeTalkToCarrotLanguageSkeleton } from "types/contentful"
import { AppointmentEntry, useAppointmentSchedulingSystem } from "../time-tap/useGetAppointmentSchedulingSystem"
import { DialogTimeTapScheduler } from "../time-tap/TimeTapScheduler"

const setEnglishToTop = ({ entryId }: { entryId: string }): 0 | -1 => {
  if (entryId === StaticAssetID.TALK_TO_CARROT_BENEFIT_CHAT_LANGUAGE_ENGLISH) {
    return -1
  }
  return 0
}

type TalkToCarrotLanguagePageProps = {
  baseUrl: string
}

interface LocalizedLanguageOption extends LanguageOption {
  formattedLabel: string
}

const CalendlyWidget = ({
  url,
  selectedLanguageOption,
  open,
  setOpen
}: {
  url: string
  selectedLanguageOption: LanguageOption
  open: boolean
  setOpen: (value: boolean) => void
}): JSX.Element => {
  const employeeId = useSelector(getEmployeeId)

  useEffect(() => {
    const handleEsc = (event: KeyboardEvent): void => {
      if (event.keyCode === 27) {
        setOpen(false)
      }
    }
    window.addEventListener("keydown", handleEsc)

    return () => {
      window.removeEventListener("keydown", handleEsc)
    }
  }, [setOpen])

  return (
    <PopupModal
      url={url}
      utm={{ utmCampaign: employeeId }}
      onModalClose={() => setOpen(false)}
      open={open}
      rootElement={document.getElementById("continue-button-box")}
      prefill={{ customAnswers: { a5: selectedLanguageOption?.label.toString() } }}
      pageSettings={{
        primaryColor: CALENDLY_PRIMARY_COLOR
      }}
    />
  )
}

function AppointmentSchedulingWidget({
  appointmentEntry,
  selectedLanguageOption,
  open,
  setOpen
}: {
  appointmentEntry: AppointmentEntry
  selectedLanguageOption: LocalizedLanguageOption
  open: boolean
  setOpen: (value: boolean) => void
}): JSX.Element {
  const { isTimeTapForCareNavigation: isTimeTap, isCalendly } = useAppointmentSchedulingSystem(appointmentEntry)

  if (!isTimeTap && !isCalendly) {
    return <></>
  }

  return isTimeTap ? (
    <DialogTimeTapScheduler
      reasonId={appointmentEntry.fields.reasonId}
      locationId={appointmentEntry.fields.locationId}
      open={open}
      setOpen={setOpen}
    />
  ) : (
    <CalendlyWidget
      url={appointmentEntry.fields.url}
      selectedLanguageOption={selectedLanguageOption}
      open={open}
      setOpen={setOpen}
    />
  )
}

type TalkToCarrotLanguageEntry = EntryWithoutUnresolvableLinks<TypeTalkToCarrotLanguageSkeleton>

export function TalkToCarrotLanguagePage({ baseUrl }: TalkToCarrotLanguagePageProps): JSX.Element {
  const [talkToCarrotLanguages, setTalkToCarrotLanguages] = useState<TalkToCarrotLanguageEntry[]>(null)
  const [talkToCarrotLanguageOptions, setTalkToCarrotLanguageOptions] = useState<LocalizedLanguageOption[]>(null)
  const [selectedLanguageOption, setSelectedLanguageOption] = useState<LocalizedLanguageOption>(null)
  const [guidance, setGuidance] = useState<ReactElement<typeof GuidanceElement>>(null)
  const history = useHistory()
  const { localeComparator } = useIntlSort()
  const { doesUserSatisfyConditions, isLoading } = useDoesUserSatisfyConditions()
  const { data: phoneSupportNumber } = useGetPhoneSupportNumberForUser()
  const [open, setOpen] = useState(false)
  const [selectedAppointmentLink, setSelectedAppointmentLink] = useState<AppointmentEntry>(null)
  const intl = useIntl()
  const { locale } = intl
  const theme = useTheme()

  const formatLanguages = (option: LanguageOption): LocalizedLanguageOption => {
    return {
      ...option,
      formattedLabel: intl.formatDisplayName(option.value, { type: "language" })
    }
  }

  async function loadTalkToCarrotLanguages(): Promise<void> {
    const languages = await getTalkToCarrotLanguages()
    const filteredLanguages = languages?.filter(
      ({ fields: { conditions } }) => !conditions || doesUserSatisfyConditions(conditions.map(toCondition))
    )
    let languageOptions: LocalizedLanguageOption[]
    if (!isLoading) {
      languageOptions = filteredLanguages
        .map(getTTCLanguageOption)
        .map(formatLanguages)
        .sort(({ formattedLabel: labelA }, { formattedLabel: labelB }) => localeComparator(labelA, labelB))
        .sort(setEnglishToTop)
    }
    setTalkToCarrotLanguages(filteredLanguages)
    setTalkToCarrotLanguageOptions(languageOptions)
  }

  useEffect(() => {
    if (!talkToCarrotLanguageOptions) {
      loadTalkToCarrotLanguages()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading])

  useEffect(() => {
    async function loadGuidance(): Promise<void> {
      const guidance = await getTalkToCarrotLanguageGuidance(locale)
      setGuidance(<GuidanceElement guidance={guidance} />)
    }

    if (!guidance) {
      loadGuidance()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useCalendlyEventListener({
    onEventScheduled: () => {
      if (selectedAppointmentLink) {
        getHeap().track("Talk to carrot benefit language chat scheduled", {
          "Calendly chat id": selectedAppointmentLink.sys.id
        })
      }
    }
  })

  function setSelectedOptionToTop(selectedOption: LocalizedLanguageOption): void {
    setTalkToCarrotLanguageOptions(
      selectedOption
        ? [
            selectedOption,
            ...talkToCarrotLanguageOptions
              .filter(({ label }) => label !== selectedOption.label)
              .sort(({ formattedLabel: labelA }, { formattedLabel: labelB }) => localeComparator(labelA, labelB))
              .sort(setEnglishToTop)
          ]
        : talkToCarrotLanguageOptions
    )
  }

  function onLanguagePickerChange(_event: ChangeEvent, selectedOption: LocalizedLanguageOption): void {
    setSelectedLanguageOption(selectedOption)
    setSelectedOptionToTop(selectedOption)
  }

  function findLanguageFromOption() {
    return talkToCarrotLanguages?.find((language) => language?.fields?.name === selectedLanguageOption?.label)
  }

  function onSubmit() {
    const language = findLanguageFromOption()
    const nextAction = getNextLanguageAction(language)
    if (fieldIsValid(nextAction)) {
      if (isTilePage(nextAction)) {
        return history.push(getPathForLanguage({ language, baseUrl }))
      } else if (isAppointmentLink(nextAction)) {
        setOpen(true)
        setSelectedAppointmentLink(nextAction)
        return
      } else if (isPhoneSupportNumber(nextAction)) {
        return window.open(`tel:${phoneSupportNumber?.e164FormatPhoneNumber}`, "_self")
      }
    } else {
      return history.push("/404")
    }
  }

  const continueButton = (
    <Box id="continue-button-box">
      <Button onClick={onSubmit} disabled={!selectedLanguageOption} id="continueButton">
        <FormattedMessage defaultMessage="Continue" />
      </Button>
      <AppointmentSchedulingWidget
        appointmentEntry={selectedAppointmentLink}
        selectedLanguageOption={selectedLanguageOption}
        open={open}
        setOpen={setOpen}
      />
    </Box>
  )

  const textfieldPlaceholder = intl.formatMessage({
    defaultMessage: "Select language"
  })

  const languagePicker = talkToCarrotLanguageOptions ? (
    <Autocomplete
      aria-label={intl.formatMessage({ defaultMessage: "Preferred language" })}
      disablePortal
      multiple={false}
      onChange={onLanguagePickerChange}
      value={selectedLanguageOption}
      getOptionLabel={(option) => option.formattedLabel}
      options={talkToCarrotLanguageOptions}
      clearOnEscape
      renderOption={(props, state) => {
        return (
          <TalkToCarrotSelectOption
            {...props}
            key={state.value}
            isSelected={state.value === selectedLanguageOption?.value}
          >
            {state.formattedLabel}
          </TalkToCarrotSelectOption>
        )
      }}
      renderInput={(params) => <TextField name="language" placeholder={textfieldPlaceholder} {...params} />}
    />
  ) : (
    <></>
  )

  const largeContent = (
    <TwoColumnRightPrimary
      secondaryContent={guidance}
      primaryContent={
        <Stack spacing={theme.spacing(theme.tokens.spacing.md)}>
          <TalkToCarrotMultilingualImage />
          {languagePicker}
          <Box paddingTop={theme.spacing(theme.tokens.spacing.sm)} display="flex" justifyContent="flex-end">
            {continueButton}
          </Box>
        </Stack>
      }
    />
  )

  const mediumContent = (
    <Stack spacing={theme.spacing(theme.tokens.spacing.sm)}>
      {guidance}
      <Box paddingTop={theme.spacing(theme.tokens.spacing.lg)}>
        <TalkToCarrotMultilingualImage />
      </Box>
      {languagePicker}
      <Box paddingTop={theme.spacing(theme.tokens.spacing.sm)}>{continueButton}</Box>
    </Stack>
  )

  const smallContent = (
    <SmallHeaderOverride>
      <Stack spacing={theme.spacing(theme.tokens.spacing.sm)}>
        {guidance}
        <Box paddingTop={theme.spacing(theme.tokens.spacing.md)}>{languagePicker}</Box>
        <Box paddingTop={theme.spacing(theme.tokens.spacing.sm)}>{continueButton}</Box>
      </Stack>
    </SmallHeaderOverride>
  )
  const languageContent = useResponsiveTalkToCarrotComponent(smallContent, mediumContent, largeContent)

  return (
    <PageLayout
      footer={<TalkToCarrotLegal />}
      header={
        <PageBreadcrumbHeader
          breadcrumbs={
            <Breadcrumbs>
              <RootBreadcrumb />
              <Breadcrumb component={ReactRouterLink} to={`${Paths.TALK_TO_CARROT}/care-navigation`}>
                <FormattedMessage defaultMessage="Care Navigation" />
              </Breadcrumb>
              <Breadcrumb currentPage>
                <FormattedMessage defaultMessage="Schedule a video chat or call" />
              </Breadcrumb>
            </Breadcrumbs>
          }
        />
      }
    >
      <Title
        title={intl.formatMessage({ defaultMessage: "Care Navigation" })}
        productTitle={intl.formatMessage({ defaultMessage: "Talk to Carrot" })}
      />
      {languageContent}
    </PageLayout>
  )
}
