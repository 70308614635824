import slugify from "slugify"
import { TypeCategory, TypeSubCategorySkeleton } from "types/contentful"
import {
  EntryWithoutUnresolvableLinks,
  isGroupSession,
  isGuide,
  ReadLearnArticleEntry
} from "../../../utils/ContentTypes"
import { ExpertBio } from "./ExpertBioMapper"

const SUBCATEGORIES_IN_CATEGORIES_DEPTH = 1
const ARTICLES_IN_SUBCATEGORIES_IN_CATEGORIES_DEPTH = 2

export const findCategoryBySlug = (categories: TypeCategory<"WITHOUT_UNRESOLVABLE_LINKS">[], categorySlug: string) =>
  categories.find((category) => slugify(category.fields.navTitle) === categorySlug)

export const findArticlesBySlug = (categories: TypeCategory<"WITHOUT_UNRESOLVABLE_LINKS">[], articleSlug: string) => {
  return categories
    .map((category) => category.fields.subCategories.map((subCategory) => subCategory.fields.articles))
    .flat(ARTICLES_IN_SUBCATEGORIES_IN_CATEGORIES_DEPTH)
    .filter((article) => article !== undefined)
    .filter((article) => article.fields.slug === articleSlug || slugify(article.fields.title) === articleSlug)
}

export const findGuidesBySlug = (categories: TypeCategory<"WITHOUT_UNRESOLVABLE_LINKS">[], guideSlug: string) =>
  categories
    .map((category) => category.fields.subCategories.map((subCategory) => subCategory.fields.articles))
    .flat(ARTICLES_IN_SUBCATEGORIES_IN_CATEGORIES_DEPTH)
    .filter((article) => article !== undefined)
    .filter(isGuide)
    .filter((article) => article.fields.slug === guideSlug)

export const findSubcategoryForArticle = (
  categories: TypeCategory<"WITHOUT_UNRESOLVABLE_LINKS">[],
  article: ReadLearnArticleEntry
) =>
  categories
    .map((category) => category.fields.subCategories)
    .flat(SUBCATEGORIES_IN_CATEGORIES_DEPTH)
    .filter((subCategory) => subCategory !== undefined)
    .find((subCategory: EntryWithoutUnresolvableLinks<TypeSubCategorySkeleton>) => {
      return subCategory.fields.articles.includes(article)
    })

export const findGroupSessionsBySlug = (categories: TypeCategory<"WITHOUT_UNRESOLVABLE_LINKS">[], groupSlug: string) =>
  categories
    .map((category) => category.fields.subCategories.map((subCategory) => subCategory.fields.articles))
    .flat(ARTICLES_IN_SUBCATEGORIES_IN_CATEGORIES_DEPTH)
    .filter((article) => article !== undefined)
    .filter(isGroupSession)
    .filter((article) => article.fields.slug === groupSlug)

export const findModeratorBySlug = (moderators: Array<ExpertBio>, moderatorSlug: string): ExpertBio[] => {
  return moderators.filter((moderator) => moderator.slug === moderatorSlug)
}
