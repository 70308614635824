import { useQuery, UseQueryResult } from "@tanstack/react-query"
import ResponseError from "../../../../types/responseError"
import { HttpStatusCodes } from "../../../../utils/HttpStatusCodes"
import { carrotClient } from "utils/CarrotClient"

const ENDPOINT = "GetCompanyComplianceConfigLineItemFilter"

export const getBenefitCacheKey = (): (string | number)[] => [ENDPOINT]

export default function useCompanyComplianceConfigLineItemFilter(): UseQueryResult<string, ResponseError> {
  return useQuery<string, ResponseError>(
    getBenefitCacheKey(),
    () => carrotClient.getCompanyComplianceConfigLineItemFilter(),
    {
      retry: (failureCount, error) => {
        switch (error.statusCode) {
          case HttpStatusCodes.UNAUTHORIZED:
          case HttpStatusCodes.BAD_REQUEST:
          case HttpStatusCodes.NOT_FOUND:
            return false
          default:
            return failureCount <= 3
        }
      }
    }
  )
}
