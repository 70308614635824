import React from "react"
import { FormattedMessage, useIntl } from "react-intl"
import { Redirect, useHistory } from "react-router-dom"
import { TalkToCarrotLegal } from "./TalkToCarrotLegal"
import { useGetSendMessageDisplay } from "./hooks/useGetSendMessageDisplay"

import { Title } from "services/page-title/Title"
import { RootBreadcrumb, createTileElement } from "./util/talkToCarrotMolecules"
import { GuidanceElement } from "lib/contentful/components/GuidanceElement"
import { useGetTalkToCarrotCareNavigationGuidance } from "./hooks/useGetTalkToCarrotCareNavigationGuidance"
import { FullScreenLoading } from "components/read/shared/FullScreenLoading"
import { useGetTalkToCarrotPhoneSupportDisplay } from "./hooks/useGetTalkToCarrotPhoneSupportDisplay"
import { useGetScheduleAVideoChatOrCallDisplay } from "./hooks/useGetScheduleAVideoChatOrCallDisplay"
import TalkToCarrotTilePage from "./TalkToCarrotTilePage"
import { useGetPhoneSupportNumberForUser } from "components/dynamic-content/hooks/useQueryPhoneSupport"
import useBenefit from "components/dynamic-content/hooks/useGetBenefit"
import { PageLayout } from "features/global-ui/page-layout"
import { PageBreadcrumbHeader } from "features/global-ui/page-header"
import { useCurrentUser } from "components/context/user/UserContext"
import { Breadcrumbs, Breadcrumb } from "@carrotfertility/carotene-core"
import { useSendMessagePath } from "./hooks/useSendMessagePath"
import { useGetZendeskMessagingWidgetSettings } from "./hooks/useGetZendeskMessagingWidgetSettings"
import { useGetTalkToCarrotZendeskMessagingWidgetDisplay } from "./hooks/useGetTalkToCarrotZendeskMessagingWidgetDisplay"
import { ProgramType } from "lib/carrot-api/types/Benefit"

export const TalkToCarrotCareNavPageRoutes = (): JSX.Element => {
  const history = useHistory()
  const sendMessagePath = useSendMessagePath()
  const { isInAppLockdown } = useCurrentUser()

  const { data: benefit, isLoading: isBenefitLoading } = useBenefit()

  // making this logic match the Contentful condition used for Benefit Chat
  const isWTWEngage = !isBenefitLoading && benefit.program.type === ProgramType.WTW_ENGAGE

  const intl = useIntl()
  const { locale } = intl

  const { data: sendMessageDisplay, isLoading: isSendMessageDisplayLoading } = useGetSendMessageDisplay(locale)
  const { data: phoneSupportDisplay, isLoading: isPhoneSupportDisplayLoading } =
    useGetTalkToCarrotPhoneSupportDisplay(locale)
  const { data: videoChatOrCallDisplay, isLoading: isVideoChatOrCallDisplayLoading } =
    useGetScheduleAVideoChatOrCallDisplay(locale)
  const { data: zendeskMessagingWidgetDisplay, isLoading: isZendeskMessagingWidgetDisplayLoading } =
    useGetTalkToCarrotZendeskMessagingWidgetDisplay(locale)

  const {
    data: phoneSupportNumber,
    isError,
    isInitialLoading: isPhoneSupportNumberLoading
  } = useGetPhoneSupportNumberForUser()
  const { data: guidanceData, isLoading: isLoadingCareNavigationGuidance } =
    useGetTalkToCarrotCareNavigationGuidance(locale)
  const guidance = <GuidanceElement guidance={guidanceData} />

  const sendMessageTile = !isSendMessageDisplayLoading
    ? createTileElement({
        tileDisplay: sendMessageDisplay,
        onClick: () => history.push(sendMessagePath)
      })
    : null

  const { data: zendeskMessagingWidgetSettings, isLoading: isZendeskMessagingWidgetSettingsLoading } =
    useGetZendeskMessagingWidgetSettings()

  const shouldDisplayPhoneSupport = !isPhoneSupportDisplayLoading && Boolean(phoneSupportNumber?.e164FormatPhoneNumber)
  const phoneSupportTile = shouldDisplayPhoneSupport
    ? createTileElement({
        tileDisplay: phoneSupportDisplay,
        onClick: () => window.open(`tel:${phoneSupportNumber?.e164FormatPhoneNumber}`, "_self"),
        showArrow: false
      })
    : null

  const shouldDisplayZendeskMessagingWidgetTile =
    !isZendeskMessagingWidgetSettingsLoading && !isZendeskMessagingWidgetDisplayLoading && !shouldDisplayPhoneSupport
  const handleMessagingWidgetTileClick = () => {
    window.zE("messenger", "show")
    window.zE("messenger:open", "voice", zendeskMessagingWidgetSettings?.digitalLineId)

    setTimeout(() => {
      // The iframe title varies with the widget locale, e.g. it's "Call Window" in en-US, "Ventana de llamada" in es-419.
      // However, for some mysterious reason, the query selector will work with all locales, even if the iframe title is not "Call window".
      const voiceIframe = document.querySelector(`[title="Call window"]`)
      const observer = new MutationObserver((mutations) => {
        mutations.forEach((_) => {
          if (!document.body.contains(voiceIframe)) {
            window.zE("messenger", "hide")
            observer.disconnect()
          }
        })
      })

      observer.observe(document.body, { subtree: true, childList: true })
    }, 2000)
  }
  const zendeskMessagingWidgetTile = shouldDisplayZendeskMessagingWidgetTile
    ? createTileElement({
        tileDisplay: zendeskMessagingWidgetDisplay,
        onClick: handleMessagingWidgetTileClick,
        showArrow: false
      })
    : null

  const shouldDisplayVideoChatOrCall = !isVideoChatOrCallDisplayLoading && !isWTWEngage && !isInAppLockdown

  const videoChatOrCallTile = shouldDisplayVideoChatOrCall
    ? createTileElement({
        tileDisplay: videoChatOrCallDisplay,
        onClick: () => history.push("/talk-to-carrot/care-navigation/schedule-a-video-chat-or-call")
      })
    : null

  if (isLoadingCareNavigationGuidance || isBenefitLoading || isPhoneSupportNumberLoading) {
    return <FullScreenLoading />
  }
  if (isError) {
    return <Redirect to="/talk-to-carrot/error" />
  }

  return (
    <PageLayout
      footer={<TalkToCarrotLegal />}
      header={
        <PageBreadcrumbHeader
          breadcrumbs={
            <Breadcrumbs>
              <RootBreadcrumb />
              <Breadcrumb currentPage>
                <FormattedMessage defaultMessage="Care Navigation" />
              </Breadcrumb>
            </Breadcrumbs>
          }
        />
      }
    >
      <Title
        title={intl.formatMessage({ defaultMessage: "Care Navigation" })}
        productTitle={intl.formatMessage({ defaultMessage: "Talk to Carrot" })}
      />
      {
        <TalkToCarrotTilePage
          guidance={guidance}
          tiles={[sendMessageTile, phoneSupportTile, zendeskMessagingWidgetTile, videoChatOrCallTile].filter(Boolean)}
        />
      }
    </PageLayout>
  )
}
