import { PageWrapperProps, TileOrPhoneSupportActions } from "components/dynamic-content/util/dynamicContent"
import React, { ReactElement } from "react"
import { GuidanceElement } from "./GuidanceElement"
import {
  fieldIsValid,
  getNextLanguageAction,
  getNextTileAction,
  getPathForLanguage,
  getPathForTile,
  isAppointmentLink,
  isPhoneSupportNumber,
  isLanguagePage,
  isTalkToCarrotSendAMessage,
  isTilePage
} from "../utils/mappingUtils"
import { TTCTilePageElement } from "./TTCTilePageElement"
import TwoColumnRightPrimary from "components/views/atoms/layouts/TwoColumnRightPrimary"
import { SendMessage } from "../../../components/talk-to-carrot/send-message/SendMessage"
import { talkToCarrotSource } from "../../../components/talk-to-carrot/send-message/SendMessageUtil"
import { TTCLanguagePageElement } from "./TTCLanguagePageElement"
import { Breadcrumb } from "@carrotfertility/carotene-core"
import { TalkToCarrotScheduleSpecialistChat } from "../../../components/talk-to-carrot/TalkToCarrotScheduleSpecialistChat"
import { EntryWithoutUnresolvableLinks } from "utils/ContentTypes"
import { TypeTalkToCarrotLanguageSkeleton, TypeTileSkeleton } from "types/contentful"

type BaseContentProps = {
  tile?: EntryWithoutUnresolvableLinks<TypeTileSkeleton>
  language?: EntryWithoutUnresolvableLinks<TypeTalkToCarrotLanguageSkeleton>
  actions: TileOrPhoneSupportActions
  baseUrl: string
}

const BaseContent = ({ tile, language, actions, baseUrl }: BaseContentProps): ReactElement => {
  const nextAction = tile ? getNextTileAction(tile) : getNextLanguageAction(language)
  const destinationBaseUrl = tile ? getPathForTile({ tile, baseUrl }) : getPathForLanguage({ language, baseUrl })

  if (fieldIsValid(nextAction)) {
    if (isTilePage(nextAction)) {
      return <TTCTilePageElement page={nextAction} baseUrl={destinationBaseUrl} />
    } else if (isLanguagePage(nextAction)) {
      return <TTCLanguagePageElement page={nextAction} actions={actions} baseUrl={destinationBaseUrl} />
    } else if (isAppointmentLink(nextAction)) {
      return <TalkToCarrotScheduleSpecialistChat calendlyLink={nextAction} />
    } else if (isPhoneSupportNumber(nextAction)) {
      return null
    } else if (isTalkToCarrotSendAMessage(nextAction)) {
      return (
        <TwoColumnRightPrimary
          secondaryContent={
            <>
              {fieldIsValid(nextAction?.fields?.guidance) ? (
                <GuidanceElement guidance={nextAction?.fields?.guidance} />
              ) : null}
            </>
          }
          primaryContent={<SendMessage source={talkToCarrotSource.SEND_A_MESSAGE} />}
        />
      )
    }
  } else {
    return null
  }
}

type TileContentProps = {
  tile: EntryWithoutUnresolvableLinks<TypeTileSkeleton>
  actions: TileOrPhoneSupportActions
  baseUrl: string
  breadcrumbs: Array<ReactElement<typeof Breadcrumb>>
  Wrapper?: ({ children, breadcrumbs }: PageWrapperProps) => ReactElement
}

const TileContent = ({ tile, actions, baseUrl, breadcrumbs, Wrapper }: TileContentProps): ReactElement => {
  const routeContent = <BaseContent tile={tile} actions={actions} baseUrl={baseUrl} />
  return Wrapper ? <Wrapper breadcrumbs={breadcrumbs}>{routeContent}</Wrapper> : routeContent
}

type LanguageContentProps = {
  language: EntryWithoutUnresolvableLinks<TypeTalkToCarrotLanguageSkeleton>
  actions: TileOrPhoneSupportActions
  baseUrl: string
  breadcrumbs: Array<ReactElement<typeof Breadcrumb>>
  Wrapper?: ({ children, breadcrumbs }: PageWrapperProps) => ReactElement
}

const LanguageContent = ({ language, actions, baseUrl, breadcrumbs, Wrapper }: LanguageContentProps): ReactElement => {
  const routeContent = <BaseContent language={language} actions={actions} baseUrl={baseUrl} />
  return Wrapper ? <Wrapper breadcrumbs={breadcrumbs}>{routeContent}</Wrapper> : routeContent
}
export { TileContent, LanguageContent }
