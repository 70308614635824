import { Asset, EntrySys, UnresolvedLink } from "contentful"
import { TypeCategorySkeleton, TypeContentBlockSkeleton, TypeSubCategorySkeleton } from "types/contentful"
import { RawContentfulConditionSkeleton } from "lib/contentful/utils/conditionUtils"
import { EntryWithoutUnresolvableLinks } from "utils/ContentTypes"

export type File = {
  url: string
}

export type Icon = {
  file: File
}
export type Tag = {
  id: string
}

export type Category = {
  id: string
  title: string
  subCategories: EntryWithoutUnresolvableLinks<TypeSubCategorySkeleton>[]
  order: number
  navTitle: string
  conditions: EntryWithoutUnresolvableLinks<RawContentfulConditionSkeleton>[]
  icon: Icon
  description: EntryWithoutUnresolvableLinks<TypeContentBlockSkeleton>[]
  tags: Tag[]
  sys: EntrySys
}

const mapContentToIcon = ({ fields }: Asset<"WITHOUT_UNRESOLVABLE_LINKS">): Icon => {
  if (!fields) {
    return null
  }
  const { file } = fields
  return {
    file
  }
}

const mapContentToTag = ({ sys }: UnresolvedLink<"Tag">): Tag => {
  if (!sys) {
    return null
  }
  const { id } = sys
  return {
    id
  }
}

export const mapContentToCategory = ({
  fields,
  metadata,
  sys
}: EntryWithoutUnresolvableLinks<TypeCategorySkeleton>): Category => {
  if (!fields || !metadata || !sys) {
    return null
  }
  const { title, subCategories, order, navTitle, conditions, icon, description } = fields
  const { id } = sys
  const { tags } = metadata

  return {
    id,
    title,
    subCategories,
    order,
    navTitle,
    conditions,
    icon: icon && mapContentToIcon(icon),
    description,
    tags: tags?.map(mapContentToTag),
    sys: sys
  }
}
