import { Box, Typography, Button } from "@carrotfertility/carotene-core"
import { DialogActionsBar } from "@carrotfertility/carotene-core-x"
import React from "react"
import { FormattedMessage } from "react-intl"

export function AirwallexScaInstructions({
  setAcknowledgedInstructions,
  handleCancel
}: {
  setAcknowledgedInstructions: () => void
  handleCancel: () => void
}) {
  return (
    <Box>
      <Typography
        variant="h2"
        color={(theme) => theme.palette.text.primary}
        paddingBottom={(theme) => theme.tokens.spacing.xs}
      >
        <FormattedMessage defaultMessage="Access card details" />
      </Typography>
      <Typography color={(theme) => theme.palette.text.secondary}>
        <FormattedMessage
          defaultMessage="For your security, you need to create a passcode to access your card details. You also need to verify your
              identity through SMS authentication, so please have your mobile phone ready."
        />
      </Typography>

      <Typography color={(theme) => theme.palette.text.secondary} marginTop="30px">
        <FormattedMessage defaultMessage="We’ll ask for your passcode each time you access your card details. Be sure to save it in a secure place." />
      </Typography>
      <DialogActionsBar
        primaryAction={
          <Button onClick={setAcknowledgedInstructions}>
            <FormattedMessage defaultMessage="Continue" />
          </Button>
        }
        secondaryAction={
          <Button variant="text" color="secondary" onClick={handleCancel}>
            <FormattedMessage defaultMessage="Cancel" />
          </Button>
        }
      />
    </Box>
  )
}
