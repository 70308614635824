import { useQuery, UseQueryResult } from "@tanstack/react-query"
import { carrotClient } from "../../../utils/CarrotClient"
import buildCarrotApiRetryPolicy from "../../../utils/CarrotApiRetryPolicy"
import ResponseError from "../../../types/responseError"
import { RoutingInfo } from "types/routingInfo"

export const MOST_RECENT_ROUTING_QUERY_KEY = "GetMostRecentRouting"

type MostRecentlyCompletedRoutingResult = Pick<
  RoutingInfo,
  "journey" | "somethingElseJourney" | "dueDate" | "journeyStage" | "deliveryDate"
>

/**
 * Fetches journey, journeyStage, somethingElseJourney, and dueDate from the most recently COMPLETE routing info for the current user.
 * We only use these select fields from the complete routing info, so we don't need to fetch the entire routing info object
 * */
export function useGetMostRecentlyCompletedRouting(): UseQueryResult<
  MostRecentlyCompletedRoutingResult,
  ResponseError
> {
  return useQuery([MOST_RECENT_ROUTING_QUERY_KEY], () => carrotClient.getMostRecentlyCompletedRoutingInfo(), {
    retry: buildCarrotApiRetryPolicy(3)
  })
}
