import React, { useMemo } from "react"
import { useSelector } from "react-redux"
import HowToUseYourBenefitCard from "./HowToUseYourBenefitCard"
import AvailableFundsModule from "../molecules/AvailableFundsModule"
import { CenteredLoadingIndicator } from "../molecules/Molecules"
import { ScrollToTop } from "../ScrollToTop"
import { BenefitEnrollmentModule } from "components/views/molecules/BenefitEnrollmentModule"
import { useToggle } from "utils/Hooks"
import { BenefitEnrollmentFlowContainer } from "components/cmd-enrollment"
import { useBenefitEnrollment } from "../../context/enrollment/BenefitEnrollmentContext"
import useCarrotPlan from "../../carrot-plans/hooks/useCarrotPlan"
import { RefreshCarrotPlanModule } from "../molecules/RefreshCarrotPlanModule"
import { HelpCenterModule } from "../molecules/HelpCenterModule"
import ReadAndLearnEligibilityModule from "components/home/eligibility-modules/ReadAndLearn"
import { useCurrentUser } from "../../context/user/UserContext"
import { useHistory, useLocation } from "react-router-dom"
import BenefitsModule from "components/home/benefits-modules"
import { Hero } from "../../home/Hero"
import Locations from "./navigation"
import { CarrotPlansRefreshModule } from "components/home/CarrotPlansRefreshModule"
import useCarrotPlanEmployeeSettings from "../../carrot-plans/hooks/useCarrotPlanEmployeeSettings"
import EmailVerifiedModal from "../../EmailVerification/EmailVerifiedModal"
import { getSubsidies } from "../../../reducers/subsidies"
import { getReimbursementsList } from "reducers/reimbursements"
import { NeedsMemberUploadsModule } from "components/home/NeedsMemberUploadsModule"
import { ExpenseState } from "utils/ExpenseState"
import useBenefit from "../../dynamic-content/hooks/useGetBenefit"
import CarrotRxHomeSidebarModule from "../../home/CarrotRxHomeSidebarModule"
import { PageHeader } from "features/global-ui/page-header/page-header"
import { FormattedMessage } from "react-intl"
import { PiiText } from "services/tracking"
import { getMemberFullName } from "derivedSelectors"
import { getPreferredName } from "reducers/userInfo"
import { Box, Grid, Stack, useTheme } from "@carrotfertility/carotene-core"
import { useUserRole } from "components/partner-access/hooks/usePartnerAccess"
import { PageLayout } from "features/global-ui/page-layout"
import {
  BenefitHighlightsCard,
  BenefitHighlightsTile,
  LaunchDarklyBenefitHighlightMvpContainer
} from "../../home/benefits-modules/BenefitHighlights"
import { useFlags } from "launchdarkly-react-client-sdk"
import { SurveyTile } from "../../home/survey/SurveyTile"
import { JourneyChange } from "../../carrot-plans/journey-change/JourneyChange"
import { HealthPlanQuickLinks } from "../../home/quick-links/HealthPlansQuickLinks"
import { ProgramType } from "carrot-api/types/Benefit"

function EligibilityModules(): JSX.Element {
  const theme = useTheme()
  return (
    <Grid container spacing={0}>
      <Grid
        container
        item
        columnSpacing={theme.spacing(theme.tokens.spacing.xl)}
        rowSpacing={theme.spacing(theme.tokens.spacing.lg)}
      >
        <Grid item xl={6} lg={12}>
          <HowToUseYourBenefitCard />
        </Grid>
        <Grid item xl={6} lg={12}>
          <ReadAndLearnEligibilityModule />
        </Grid>
      </Grid>
    </Grid>
  )
}

const HomeMain = (): JSX.Element => {
  const theme = useTheme()
  const { data: memberHasACarrotPlan, isLoading: isCarrotPlanLoading } = useCarrotPlan()
  const { benefitHighlightMvp, memberReportedOutcomesOnboardingSurvey, benefitHighlightDesignVariation } = useFlags()
  const { data: carrotPlan } = useCarrotPlan()
  const { isDoneForNow, appIsUnlockedAndPendingCarrotPlan } = useCarrotPlanEmployeeSettings()

  const cpIsOnTheWay = !carrotPlan?.journeyCompleteTimestampUtc && appIsUnlockedAndPendingCarrotPlan
  const shouldShowBenefitHighlightForDesignVariation =
    benefitHighlightDesignVariation && (isDoneForNow || !memberHasACarrotPlan || cpIsOnTheWay)

  if (isCarrotPlanLoading) {
    return null
  }

  return (
    <Stack spacing={theme.spacing(theme.tokens.spacing.lg)}>
      <CarrotPlansRefreshModule />
      <LaunchDarklyBenefitHighlightMvpContainer>
        {(!memberHasACarrotPlan || shouldShowBenefitHighlightForDesignVariation) && <BenefitHighlightsTile />}
      </LaunchDarklyBenefitHighlightMvpContainer>
      <Stack spacing={theme.spacing(theme.tokens.spacing.xxxl)}>
        <Hero />
        {memberReportedOutcomesOnboardingSurvey && <SurveyTile />}
        {!benefitHighlightMvp && <EligibilityModules />}
      </Stack>
      <HealthPlanQuickLinks />
    </Stack>
  )
}

const SidebarModules = (): JSX.Element => {
  const { status: benefitEnrollmentStatus, availabilityStatus } = useBenefitEnrollment()
  const { data: benefit, isLoading: isUseBenefitLoading } = useBenefit()
  const { needsBenefitEnrollment } = benefitEnrollmentStatus
  const [showBenefitEnrollmentModal, toggleBenefitEnrollmentModal] = useToggle(false)
  const { data: memberHasACarrotPlan, isLoading: isCarrotPlanLoading } = useCarrotPlan()
  const { isInAppLockdown } = useCurrentUser()
  const subsidies = useSelector(getSubsidies)
  // @ts-expect-error TS7006
  const hasUnlimitedBenefit = subsidies?.every((plan) => plan.hasUnlimitedBenefit)
  const expensesNeedingMemberUploads = useSelector((state) => {
    const reimbursementsList = getReimbursementsList(state)
    return reimbursementsList?.filter(
      (expense: { expenseState: string }) => expense.expenseState === ExpenseState.NEEDS_MEMBER_UPLOADS
    )
  })
  const { isPartnerAccessingAccount } = useUserRole()
  const { benefitHighlightDesignVariation } = useFlags()
  const { data: carrotPlan } = useCarrotPlan()
  const { isDoneForNow, appIsUnlockedAndPendingCarrotPlan } = useCarrotPlanEmployeeSettings()

  const cpIsOnTheWay = !carrotPlan?.journeyCompleteTimestampUtc && appIsUnlockedAndPendingCarrotPlan
  const shouldNotShowBenefitHighlightSidebarForDesignVariation =
    benefitHighlightDesignVariation && (isDoneForNow || !memberHasACarrotPlan || cpIsOnTheWay)

  const isPro = benefit?.program?.type === ProgramType.PRO
  const isCoreOrEngage = [ProgramType.CORE, ProgramType.WTW_ENGAGE].includes(benefit?.program?.type)

  const sideModulesToRender = useMemo(() => {
    const modules = []
    const hideAvailableFundsModule: boolean = expensesNeedingMemberUploads?.length > 0

    if (isCarrotPlanLoading || isUseBenefitLoading) {
      return [<CenteredLoadingIndicator key={"centered-loading-indicator"} id={"loading-indicator-test"} />]
    }

    if (isPro && !isPartnerAccessingAccount && expensesNeedingMemberUploads?.length) {
      modules.push(
        <NeedsMemberUploadsModule hasMultipleExpensesNeedingUploads={expensesNeedingMemberUploads.length > 1} />
      )
    }

    if (isPro && !hasUnlimitedBenefit) {
      modules.push(
        <BenefitsModule
          appLockedDown={isInAppLockdown}
          availabilityStatus={availabilityStatus}
          hideAvailableFundsModule={hideAvailableFundsModule}
        />
      )
    } else {
      if (isPro) {
        if (isInAppLockdown && !hasUnlimitedBenefit) {
          if (!hideAvailableFundsModule) {
            modules.push(<AvailableFundsModule appLockedDown={isInAppLockdown} />)
          }
        } else {
          if (needsBenefitEnrollment) {
            modules.push(<BenefitEnrollmentModule onClickEnroll={toggleBenefitEnrollmentModal} />)
          } else if (!hasUnlimitedBenefit && !hideAvailableFundsModule) {
            modules.push(<AvailableFundsModule appLockedDown={isInAppLockdown} />)
          }
        }
      }
    }

    if (memberHasACarrotPlan) {
      if (shouldNotShowBenefitHighlightSidebarForDesignVariation) {
        modules.push(null)
      } else {
        modules.push(
          <LaunchDarklyBenefitHighlightMvpContainer>
            <BenefitHighlightsCard />
          </LaunchDarklyBenefitHighlightMvpContainer>
        )
      }
    }

    if (isCoreOrEngage) {
      modules.push(<HelpCenterModule />)
    }

    if (benefit?.carrotRx.isCarrotRxActive && benefit?.program?.type !== ProgramType.WTW_ENGAGE) {
      const fertilityCareOrPreservationEnabled =
        benefit?.journeys.fertilityPreservation.isAppSupportEnabled ||
        benefit?.journeys.fertilityCare.isAppSupportEnabled
      modules.push(
        <CarrotRxHomeSidebarModule fertilityCareOrPreservationEnabled={fertilityCareOrPreservationEnabled} />
      )
    }

    if (memberHasACarrotPlan && !isDoneForNow && !appIsUnlockedAndPendingCarrotPlan) {
      modules.push(<RefreshCarrotPlanModule />)
    }

    return modules
  }, [
    isCarrotPlanLoading,
    memberHasACarrotPlan,
    isPro,
    isCoreOrEngage,
    hasUnlimitedBenefit,
    isDoneForNow,
    appIsUnlockedAndPendingCarrotPlan,
    isInAppLockdown,
    availabilityStatus,
    needsBenefitEnrollment,
    toggleBenefitEnrollmentModal,
    expensesNeedingMemberUploads,
    benefit,
    isUseBenefitLoading,
    isPartnerAccessingAccount,
    shouldNotShowBenefitHighlightSidebarForDesignVariation
  ])

  return (
    <>
      {sideModulesToRender.map((module, idx) => (
        <React.Fragment key={idx}>{module}</React.Fragment>
      ))}
      {showBenefitEnrollmentModal && <BenefitEnrollmentFlowContainer onExit={toggleBenefitEnrollmentModal} />}
    </>
  )
}

export const HomeSidebar = (): JSX.Element => {
  return (
    <Box
      display="flex"
      flexWrap="wrap"
      flexDirection="row"
      gap={(theme) => theme.spacing(theme.tokens.spacing.lg)}
      sx={(theme) => ({
        [theme.breakpoints.down("lg")]: {
          "& > *": {
            flex: 1,
            blockSize: "auto",
            minInlineSize: "20rem",
            inlineSize: "calc(50% - 1.5rem)"
          }
        }
      })}
    >
      <SidebarModules />
    </Box>
  )
}

type LocationState = {
  state: {
    [key: string]: unknown
  }
}

const Home = (): JSX.Element => {
  const theme = useTheme()
  const location: LocationState = useLocation()
  const state = location.state || {}
  const history = useHistory()
  const memberFullName = useSelector(getMemberFullName)
  const preferredName = useSelector(getPreferredName)
  const displayName = preferredName ? preferredName : memberFullName

  const onEnrollmentExit = (): void => {
    history.push(Locations.Home.Index)
  }

  return (
    // See https://trello.com/c/qQYnzDnt This was added because on mobile, home would load scrolled down a little.
    <ScrollToTop>
      <PageLayout
        variant="sidebar"
        sidebar={<HomeSidebar />}
        header={
          <Stack marginBottom={theme.spacing(theme.tokens.spacing.xl)} spacing={theme.spacing(theme.tokens.spacing.lg)}>
            <PageHeader
              pageTitle={
                <FormattedMessage
                  defaultMessage="Hello, {displayName}"
                  values={{
                    displayName: <PiiText>{displayName}</PiiText>
                  }}
                />
              }
            />
            <JourneyChange />
          </Stack>
        }
      >
        <HomeMain />
      </PageLayout>
      {state.showBenefitEnrollmentModal && (
        <BenefitEnrollmentFlowContainer enrollmentType={state.enrollmentType} onExit={onEnrollmentExit} />
      )}
      <EmailVerifiedModal open={Boolean(state.showPersonalEmailVerifiedModal)} />
    </ScrollToTop>
  )
}

export default Home
