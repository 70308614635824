import React, { FC, useContext } from "react"
import { RequestCardContext } from "pages/request-carrot-card-flow"
import { Container, Stepper } from "@carrotfertility/carotene-core"
import { BackButton, CancelButton, ContinueButton } from "pages/request-carrot-card-flow/requestCardWrapper/buttons"
import { DialogActionsBar } from "@carrotfertility/carotene-core-x"

type RequestCardWrapperProps = {
  disableContinue?: string
  sectionNum?: number
  showBackButton?: boolean
  showCancelButton?: boolean
  goBackToStepName?: string
  showContinueButton?: boolean
  children: React.ReactNode
}

const RequestCardWrapper: FC<RequestCardWrapperProps> = ({
  children,
  disableContinue,
  goBackToStepName,
  sectionNum,
  showBackButton,
  showCancelButton,
  showContinueButton
}) => {
  const { totalSteps } = useContext(RequestCardContext)

  return (
    <Container>
      {sectionNum < totalSteps && (
        <Stepper
          sx={{ paddingBlockEnd: (theme) => theme.tokens.spacing.xl }}
          variant="progress"
          steps={totalSteps}
          position="static"
          activeStep={sectionNum}
        />
      )}
      {children}
      <DialogActionsBar
        primaryAction={showContinueButton && <ContinueButton disableContinue={disableContinue} />}
        secondaryAction={showCancelButton && <CancelButton />}
        tertiaryAction={showBackButton && <BackButton goBackToStepName={goBackToStepName} />}
      />
    </Container>
  )
}

RequestCardWrapper.defaultProps = {
  sectionNum: 1
}

export default RequestCardWrapper
