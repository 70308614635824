import React, { ReactElement } from "react"
import { Route } from "react-router-dom"
import { PageWrapperProps, TileOrPhoneSupportActions } from "../../../components/dynamic-content/util/dynamicContent"
import { getHelpPath } from "components/talk-to-carrot/util/talkToCarrotMolecules"
import { HelpPage } from "components/talk-to-carrot/TalkToCarrotNeedHelp"
import PageBreadcrumb from "./PageBreadcrumb"
import { fieldIsValid, getNextLanguageAction, getPathForLanguage, isTilePage } from "../utils/mappingUtils"
import { LanguageContent } from "./RouteContent"
import { getTileRoutes } from "./TilePageRoutes"
import { Breadcrumb } from "@carrotfertility/carotene-core"
import { EntryWithoutUnresolvableLinks } from "utils/ContentTypes"
import { TypeTalkToCarrotLanguageSkeleton } from "types/contentful"

type LanguageRoutesProps = {
  actions: TileOrPhoneSupportActions
  baseUrl: string
  breadcrumbs: Array<ReactElement<typeof Breadcrumb>>
  Wrapper?: ({ children, breadcrumbs }: PageWrapperProps) => ReactElement
  language: EntryWithoutUnresolvableLinks<TypeTalkToCarrotLanguageSkeleton>
}

const getLanguageRoutes = ({
  actions,
  baseUrl,
  breadcrumbs,
  Wrapper,
  language
}: LanguageRoutesProps): ReactElement<typeof Route>[] => {
  const languagePath = getPathForLanguage({ language, baseUrl })

  const newBreadcrumbs = [
    ...breadcrumbs,
    <PageBreadcrumb language={language} baseUrl={baseUrl} actions={actions} isCurrentPage key={languagePath} />
  ]

  const contentRender = (): JSX.Element => (
    <LanguageContent
      language={language}
      actions={actions}
      baseUrl={baseUrl}
      breadcrumbs={newBreadcrumbs}
      Wrapper={Wrapper}
    />
  )

  const getNestedRoutes = (): ReactElement<typeof Route>[] => {
    const nextAction = getNextLanguageAction(language)

    if (fieldIsValid(nextAction) && isTilePage(nextAction)) {
      const {
        fields: { tiles }
      } = nextAction

      const parentCrumbs = [
        ...breadcrumbs,
        <PageBreadcrumb language={language} baseUrl={baseUrl} actions={actions} key={languagePath} />
      ]

      const nestedRoutes = tiles.map((tile): ReactElement<typeof Route>[] =>
        getTileRoutes({ actions, baseUrl: languagePath, breadcrumbs: parentCrumbs, Wrapper, tile })
      )
      return [
        <Route
          exact
          path={getHelpPath({ baseUrl: languagePath })}
          key={`${languagePath}-help`}
          render={(): JSX.Element => <HelpPage breadcrumbs={parentCrumbs} />}
        />,
        ...nestedRoutes.flat()
      ]
    }
    return []
  }

  return [<Route exact path={languagePath} render={contentRender} key={languagePath} />, ...getNestedRoutes()]
}

export { getLanguageRoutes }
