import React, { useState } from "react"
import { Box, Button, CloseIcon, dayjs, IconButton, Tile, Typography } from "@carrotfertility/carotene-core"
import { defineMessage, FormattedMessage, MessageDescriptor, useIntl } from "react-intl"
import { useSelector } from "react-redux"
import { getCountryCode } from "../../../reducers/companyInfo"
import useCarrotPlan from "../../carrot-plans/hooks/useCarrotPlan"
import { CountryCode } from "../../../content/CountryCode"
import useBenefit from "../../dynamic-content/hooks/useGetBenefit"
import { getEmployeeId } from "../../../reducers/userInfo"
import {
  useCreateSurveyBannerCloseClick,
  useCreateSurveyLinkClick,
  useCreateSurveyLinkView,
  useSignatureOrSurveyLinkClicksExists
} from "./hooks/useSurvey"
import { getHeap } from "../../../utils/heap"
import { useIsExcludedFromMedicalRecordsConsent } from "../../../services/user/hooks/useGetIsExcludedFromMedicalRecordsConsent"
import { Locale, SupportedLocale } from "types/Locale"
import { useGetMostRecentlyCompletedRouting } from "components/carrot-plans/hooks/useGetMostRecentlyCompletedRouting"
import { ProgramType } from "lib/carrot-api/types/Benefit"

const COUNTRY_ALLOW_LIST = [
  CountryCode.Argentina,
  CountryCode.Australia,
  CountryCode.Austria,
  CountryCode.Belgium,
  CountryCode.Brazil,
  CountryCode.Cambodia,
  CountryCode.Canada,
  CountryCode.Chile,
  CountryCode.China,
  CountryCode.Colombia,
  CountryCode.Denmark,
  CountryCode.Dominican_Republic,
  CountryCode.France,
  CountryCode.Germany,
  CountryCode.Guatemala,
  CountryCode.Hong_Kong,
  CountryCode.Hungary,
  CountryCode.India,
  CountryCode.Ireland,
  CountryCode.Israel,
  CountryCode.Italy,
  CountryCode.Japan,
  CountryCode.Mexico,
  CountryCode.Netherlands,
  CountryCode.New_Zealand,
  CountryCode.Philippines,
  CountryCode.Poland,
  CountryCode.Romania,
  CountryCode.Serbia,
  CountryCode.Singapore,
  CountryCode.South_Africa,
  CountryCode.South_Korea,
  CountryCode.Spain,
  CountryCode.Sweden,
  CountryCode.Switzerland,
  CountryCode.Taiwan,
  CountryCode.Thailand,
  CountryCode.Turkey,
  CountryCode.United_Kingdom,
  CountryCode.United_States
]

const JOURNEYS = {
  AssistedReproduction: "ASSISTED_REPRODUCTION",
  TryingToGetPregnant: "TRY_PREGNANT",
  Pregnant: "PREGNANT"
}

const JOURNEY_ALLOW_LIST = Object.values(JOURNEYS)
const PROGRAM_ALLOW_LIST = [ProgramType.CORE, ProgramType.PRO] // No WTW Engage

const QUALTRICS_SURVEY_BASE_URL = "https://carrotcheckin.get-carrot.com/jfe/form/SV_9YvqvXtSDMoF0Pk"

const QUALTRICS_URL_QUERY_PARAM_NAMES = {
  DISTRIBUTION_CHANNEL: "Q_CHL",
  LANGUAGE: "Q_LANGUAGE",
  ROUTING_JOUNREY_OF_INTEREST: "routing_journey_of_interest",
  ROUTING_DUE_DATE: "routing_due_date",
  COUNTRY_CODE: "country_code",
  EMPLOYEE_ID: "employee_id"
} as const

const QUALTRICS_APP_DISTRIBUTION_CHANNEL = "app"

type buildQualtricsSurveyUrlParams = {
  journey: string
  dueDate: string
  countryCode: CountryCode
  employeeId: number
  locale: Locale
}

const buildQualtricsSurveyUrl = ({
  journey,
  dueDate,
  countryCode,
  employeeId,
  locale
}: buildQualtricsSurveyUrlParams): URL => {
  const url = new URL(QUALTRICS_SURVEY_BASE_URL)

  url.searchParams.set(QUALTRICS_URL_QUERY_PARAM_NAMES.DISTRIBUTION_CHANNEL, QUALTRICS_APP_DISTRIBUTION_CHANNEL)
  url.searchParams.set(QUALTRICS_URL_QUERY_PARAM_NAMES.LANGUAGE, locale.qualtricsLanguageCode)
  url.searchParams.set(QUALTRICS_URL_QUERY_PARAM_NAMES.COUNTRY_CODE, countryCode)
  url.searchParams.set(QUALTRICS_URL_QUERY_PARAM_NAMES.EMPLOYEE_ID, employeeId.toString())
  url.searchParams.set(QUALTRICS_URL_QUERY_PARAM_NAMES.ROUTING_JOUNREY_OF_INTEREST, journey)

  if (dueDate) {
    url.searchParams.set(QUALTRICS_URL_QUERY_PARAM_NAMES.ROUTING_DUE_DATE, dueDate)
  }

  return url
}

export function SurveyTile(): JSX.Element {
  const employeeId = useSelector(getEmployeeId)
  const intl = useIntl()
  const { locale } = intl
  const companyCountryCode = useSelector(getCountryCode)
  const {
    data: routingData,
    isLoading: isRoutingInfoLoading,
    isError: isRoutingInfoError
  } = useGetMostRecentlyCompletedRouting()
  const { data: benefit, isLoading: isBenefitLoading, isError: isBenefitError } = useBenefit()
  const {
    hasCarrotPlan,
    data: carrotPlanData,
    isLoading: isCarrotPlanLoading,
    isError: isCarrotPlanError
  } = useCarrotPlan()
  const {
    data: doesSignatureOrLinkClickExist,
    isLoading: isSignatureOrLinkClickLoading,
    isError: isSignatureOrLinkClickError
  } = useSignatureOrSurveyLinkClicksExists()
  const { mutate: updateSurveyLinkView } = useCreateSurveyLinkView()
  const [sentLinkViewEvent, setSentLinkViewEvent] = useState(false)
  const { mutate: updateSurveyLinkClick } = useCreateSurveyLinkClick()
  const { mutate: updateSurveyBannerCloseClick } = useCreateSurveyBannerCloseClick()
  const [clickedCloseButton, setClickedCloseButton] = useState(false)
  const isExcludedFromMedicalRecordsConsent = useIsExcludedFromMedicalRecordsConsent()

  if (
    isRoutingInfoLoading ||
    isRoutingInfoError ||
    isBenefitLoading ||
    isBenefitError ||
    isSignatureOrLinkClickLoading ||
    isSignatureOrLinkClickError ||
    isCarrotPlanLoading ||
    isCarrotPlanError ||
    !carrotPlanData
  ) {
    return null
  }

  let carrotPlanCreatedAtTimestamp
  try {
    carrotPlanCreatedAtTimestamp = dayjs(carrotPlanData.createdAtUtc)
  } catch (e) {
    reportError(e)
    return null
  }

  let pregnantJourneyTargetDateTimestamp
  const shouldValidatePregnantJourneyTargetDate = routingData?.journey === JOURNEYS.Pregnant
  const pregnantJourneyTargetDate = routingData?.dueDate || routingData?.deliveryDate
  if (pregnantJourneyTargetDate) {
    try {
      pregnantJourneyTargetDateTimestamp = dayjs(pregnantJourneyTargetDate)
    } catch (e) {
      reportError(e)
      return null
    }
  }
  const isPregnantJourneyTargetDateInRange =
    pregnantJourneyTargetDateTimestamp > dayjs().subtract(168, "day") &&
    pregnantJourneyTargetDateTimestamp < dayjs().subtract(28, "day")

  function onSurveyLinkClick() {
    updateSurveyLinkClick({})
  }

  function onCloseButtonClick() {
    setClickedCloseButton(true)
    updateSurveyBannerCloseClick(
      {},
      {
        onError: () => setClickedCloseButton(false)
      }
    )
  }

  const showSurveyTile =
    !isExcludedFromMedicalRecordsConsent &&
    COUNTRY_ALLOW_LIST.includes(companyCountryCode) &&
    JOURNEY_ALLOW_LIST.includes(routingData?.journey) &&
    PROGRAM_ALLOW_LIST.includes(benefit?.program.type) &&
    hasCarrotPlan &&
    carrotPlanCreatedAtTimestamp > dayjs().subtract(365, "day") &&
    carrotPlanCreatedAtTimestamp < dayjs().subtract(4, "weeks") &&
    !doesSignatureOrLinkClickExist &&
    !clickedCloseButton &&
    locale === SupportedLocale.English_UnitedStates.toString() &&
    (!shouldValidatePregnantJourneyTargetDate || isPregnantJourneyTargetDateInRange)

  if (showSurveyTile && !sentLinkViewEvent) {
    setSentLinkViewEvent(true)
    updateSurveyLinkView(
      {},
      {
        onError: () => setSentLinkViewEvent(false)
      }
    )
    getHeap().track("Outcomes onboarding survey link viewed")
  }

  function getTileText(): MessageDescriptor {
    switch (routingData.journey) {
      case JOURNEYS.AssistedReproduction:
      case JOURNEYS.TryingToGetPregnant:
        return defineMessage({
          defaultMessage:
            "How is your journey to have a baby going so far? Your update helps us improve our support for everyone."
        })
      case JOURNEYS.Pregnant:
        return defineMessage({
          defaultMessage:
            "How is your pregnancy journey going so far? Your update helps us improve our support for everyone."
        })
      default:
        return null
    }
  }

  const qualtricsSurveyUrl = buildQualtricsSurveyUrl({
    countryCode: companyCountryCode,
    employeeId: employeeId,
    journey: routingData.journey,
    dueDate: routingData.dueDate,
    locale: Locale.getLocaleFromString(locale)
  })

  return showSurveyTile ? (
    <Tile>
      <Box
        display="flex"
        flexDirection={["column-reverse", "column-reverse", "row"]}
        minWidth="224px"
        gap={(theme) => theme.spacing(theme.tokens.spacing.lg)}
        alignItems="center"
      >
        <Box
          display="flex"
          flexDirection="row"
          alignItems="center"
          gap={(theme) => theme.spacing(theme.tokens.spacing.lg)}
        >
          <Box component="img" src={`/images/speech-bubbles.png`} alt="" width={93} height={93} />
          <Box display="flex" flexDirection="column" gap={(theme) => theme.spacing(theme.tokens.spacing.lg)}>
            <Box>
              <Box display="flex" justifyContent="flex-end">
                <IconButton
                  size="small"
                  id="close-outcomes-onboarding-survey-banner"
                  aria-label={intl.formatMessage({ defaultMessage: "close" })}
                  onClick={onCloseButtonClick}
                >
                  <CloseIcon />
                </IconButton>
              </Box>
              <Typography variant="h4" component="h3">
                <FormattedMessage defaultMessage="Check-in time" />
              </Typography>
              <Typography>{intl.formatMessage(getTileText())}</Typography>
            </Box>
            <Box>
              <Button
                variant="outlined"
                size="medium"
                color="secondary"
                component="a"
                href={qualtricsSurveyUrl.href}
                onClick={onSurveyLinkClick}
                target="_blank"
                id="outcomes-onboarding-survey-link"
              >
                <FormattedMessage defaultMessage="Update us" />
              </Button>
            </Box>
          </Box>
        </Box>
      </Box>
    </Tile>
  ) : null
}
