import React, { createContext, useContext, useEffect, useState } from "react"
import { AirwallexSca } from "./AirwallexSca"
import useCreateAirwallexPanToken from "./hooks/useCreateAirwallexPanToken"
import { AirwallexCardDetails } from "./AirwallexCardDetails"
import { ShowErrorRetrievingCardDetails } from "../CarrotCardActiveModule"
import { Box, Progress } from "@carrotfertility/carotene-core"

interface AirwallexCardProps {
  hideCardDetails: () => void
}
export const AirwallexCardAccess: React.FC<AirwallexCardProps> = ({ hideCardDetails }) => {
  const { scaToken } = useVerificationToken()
  const [error, setError] = useState(false)

  const {
    mutate: createPanToken,
    data: pantokenResponse,
    isLoading: pantokenIsLoading,
    isError: isErrorGettingPantoken,
    error: pantokenError
  } = useCreateAirwallexPanToken()

  const handleError = (error: any) => {
    reportError(error)
    setError(true)
  }

  useEffect(() => {
    if (isErrorGettingPantoken) {
      handleError(`Error getting pan token: ${JSON.stringify(pantokenError, null, 2)}`)
    }
  }, [isErrorGettingPantoken, pantokenError])

  useEffect(() => {
    createPanToken(scaToken)
  }, [createPanToken, scaToken])

  if (error) {
    return <ShowErrorRetrievingCardDetails />
  }

  if (pantokenIsLoading || !pantokenResponse || (!pantokenResponse?.token && scaToken)) {
    return (
      <Box width={"100%"} display="flex" alignItems="center" justifyContent="center" height="100px">
        <Progress />
      </Box>
    )
  }

  if (pantokenResponse?.token) {
    return <AirwallexCardDetails onError={handleError} isLoading={pantokenIsLoading} panToken={pantokenResponse} />
  }

  //No error, pan token is loaded and empty, so we need to do SCA
  return (
    <AirwallexSca onClose={hideCardDetails} onError={handleError} scaSessionCode={pantokenResponse?.sca_session_code} />
  )
}

const VerificationTokenContext = createContext(null)

const VerificationTokenProvider = ({ children }: { children: React.ReactNode }) => {
  const [scaToken, setScaToken] = useState(null)

  return (
    <VerificationTokenContext.Provider value={{ scaToken, setScaToken }}>{children}</VerificationTokenContext.Provider>
  )
}

// Custom hook to use the verification token context
export const useVerificationToken = () => {
  return useContext(VerificationTokenContext)
}

export const AirwallexCard: React.FC<AirwallexCardProps> = ({ hideCardDetails }) => {
  return (
    <VerificationTokenProvider>
      <AirwallexCardAccess hideCardDetails={hideCardDetails} />
    </VerificationTokenProvider>
  )
}
