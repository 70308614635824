import { Box, ExternalIcon, InternalIcon, Link, Tile, Typography, useTheme } from "@carrotfertility/carotene-core"
import React, { useEffect } from "react"
import { ContentfulContentEntry, ContentfulContentRenderer } from "../../../services/contentful"
import { defineMessage, useIntl } from "react-intl"
import useChannelId from "./hooks/useGetChannelId"
import { getHealthPlanQuickLinkEntry } from "./getHealthPlanQuickLinkEntry"
import { reportErrorMessage } from "../../../utils/ErrorReporting"

const ENV_AGNOSTIC_CARROT_URL = "get-carrot.com"

// This overrides the link styling default for Contentful
const styleOverrides = {
  a: {
    // @ts-expect-error TS7006
    component: ({ href, children, ...otherProps }) => {
      return (
        <Link target="_blank" href={href} endIcon={getIconType(href)} display={"inline-block"} {...otherProps}>
          {children}
        </Link>
      )
    }
  }
}
function getIconType(href: string): React.JSX.Element {
  return href.includes(ENV_AGNOSTIC_CARROT_URL) ? <InternalIcon fontSize="small" /> : <ExternalIcon fontSize="small" />
}

export function HealthPlanQuickLinks(): React.JSX.Element {
  const { data: channelId, isLoading } = useChannelId()
  const [content, setContent] = React.useState<Array<ContentfulContentEntry>>()
  const [title, setTitle] = React.useState<string>()
  const intl = useIntl()
  const theme = useTheme()

  useEffect(() => {
    // See https://github.com/facebook/react/issues/14326 for guidance on async useEffect
    let didCancel = false
    async function fetchQuickLinks() {
      try {
        const quickLinkEntry = await getHealthPlanQuickLinkEntry("en-US", channelId)
        setContent(quickLinkEntry == null ? null : quickLinkEntry.fields?.links)
        setTitle(quickLinkEntry == null ? null : quickLinkEntry.fields?.title)
      } catch (error) {
        if (!didCancel) {
          reportErrorMessage(`Fetching failure for quick links component with channelId=${channelId}. Error: ${error}`)
        }
      }
    }
    fetchQuickLinks()
    return () => {
      didCancel = true
    }
  }, [channelId])

  if (isLoading || channelId == null || content == null || title == null) {
    return null
  }

  const description = defineMessage({
    defaultMessage: "Use these for easy access to additional features and information from your health plan."
  })

  return (
    <Tile
      sx={{
        border: 1,
        borderRadius: theme.tokens.borderRadius.md,
        borderColor: theme.palette.divider,
        backgroundColor: theme.palette.background.paper,
        padding: theme.spacing(theme.tokens.spacing.lg)
      }}
    >
      <Typography fontWeight="bold" color={(theme) => theme.palette.text.primary}>
        {title}
      </Typography>
      <Typography color={(theme) => theme.palette.text.secondary} paddingY={theme.spacing(theme.tokens.spacing.lg)}>
        {intl.formatMessage(description)}
      </Typography>
      <Box sx={{ columnCount: 2 }}>
        <ContentfulContentRenderer contents={content} overrides={styleOverrides} />
      </Box>
    </Tile>
  )
}
