import { TypeTileDisplaySkeleton } from "types/contentful"
import { getIconUrl } from "lib/contentful/utils/mappingUtils"
import { EntryWithoutUnresolvableLinks } from "utils/ContentTypes"

export type TileDisplay = {
  id: string
  title: string
  description: string
  secondaryDescription: string
  iconUrl: string
}

export const mapContentToTileDisplay = ({
  fields,
  sys
}: EntryWithoutUnresolvableLinks<TypeTileDisplaySkeleton>): TileDisplay => {
  if (!fields || !sys) {
    return null
  }
  const { id } = sys
  const { title, description, secondaryDescription, icon } = fields
  const iconUrl = getIconUrl(icon)

  return {
    id,
    title,
    description,
    secondaryDescription,
    iconUrl
  }
}
