import { useEffect, useState } from "react"
import { useQuery } from "@tanstack/react-query"
import buildCarrotApiRetryPolicy from "../../../../utils/CarrotApiRetryPolicy"
import useQueryExpirationHandler from "../../authentication/hooks/useQueryExpirationHandler"
import ResponseError from "../../../../types/responseError"
import { carrotClient } from "utils/CarrotClient"

export type MemberEnrollmentHistory = {
  benefitEnrollmentWizardCompleted?: Date
  companyId: number
  createdDate: Date
  employeeId: number
  insuranceSyncInfoUpdated?: Date
  lastSuccessfulInsuranceSync?: Date
  legallyMarried?: boolean
  memberCoverageEndDate?: Date
  memberCoverageStartDate?: Date
  memberCoverageType?: string
  memberCoverageTypeSelectValue?: string
  memberEnrollmentAnswersHistoryId: number
  memberHasHDHP?: boolean
  memberHasHSA?: boolean
  memberHasLegalPartner?: boolean
  memberHsaContributionStatus?: string
  memberId?: string
  memberPlanSource?: string
  memberPlanSourceSelectValue?: string
  partnerAsDependent?: boolean
  partnerCoverageEndDate?: Date
  partnerCoverageStartDate?: Date
  partnerCoverageType?: string
  partnerCoverageTypeSelectValue?: string
  partnerHasHDHP?: boolean
  partnerHasHSA?: boolean
  partnerHealthPlanMemberId?: string
  partnerHealthPlanTradingPartnerId?: string
  partnerHealthPlanTradingPartnerPk?: number
  partnerHsaContributionStatus?: string
  partnerInsuranceSyncInfoUpdated?: Date
  partnerLastSuccessfulInsuranceSync?: Date
  partnerPlanSource?: string
  partnerPlanSourceSelectValue?: string
  tradingPartnerId?: string
  tradingPartnerPk?: number
}

function buildResiliencePolicy() {
  return {
    retry: buildCarrotApiRetryPolicy(3),
    // @ts-expect-error TS7006
    retryDelay: (attempt) => attempt * 100
  }
}

function buildRefetchPolicy() {
  return {
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    refetchOnReconnect: false
  }
}

// @ts-expect-error TS7006
function useQueryInitialLoading(query) {
  const isLoading = query.isLoading
  const [initialLoading, setInitialLoading] = useState(isLoading)

  useEffect(() => {
    if (initialLoading) {
      setInitialLoading(isLoading)
    }
  }, [isLoading, initialLoading])

  return initialLoading
}

export function useMemberEnrollmentHistory() {
  const queryKey = "enrollmentHistory"
  const query = useQuery<MemberEnrollmentHistory, ResponseError>(
    [queryKey],
    () => carrotClient.getMemberEnrollmentHistory(),
    {
      ...buildRefetchPolicy(),
      ...buildResiliencePolicy()
    }
  )
  useQueryExpirationHandler(query)
  const initialLoading = useQueryInitialLoading(query)

  return {
    refetch: query.refetch,
    memberHistory: query.data,
    initialLoading
  }
}

export default useMemberEnrollmentHistory
