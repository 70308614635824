import { useMutation, UseMutationResult, useQueryClient } from "@tanstack/react-query"
import { InAppMessagingThread } from "../inAppMessagingTypes"
import ResponseError from "../../../types/responseError"
import { carrotClient } from "utils/CarrotClient"
import { HttpStatusCodes } from "../../../utils/HttpStatusCodes"
import { THREADS_CACHE_KEY } from "./useGetThreads"

export default function useMarkThreadAsRead(): UseMutationResult<InAppMessagingThread, ResponseError, number> {
  const queryClient = useQueryClient()
  return useMutation<InAppMessagingThread, ResponseError, number>(
    (threadId: number) => carrotClient.patchThread(threadId, { hasUnreadMessages: false }),
    {
      retry: (failureCount, error) => {
        switch (error.statusCode) {
          case HttpStatusCodes.UNAUTHORIZED:
          case HttpStatusCodes.BAD_REQUEST:
          case HttpStatusCodes.NOT_FOUND:
            return false
          default:
            return failureCount <= 3
        }
      },
      onSuccess: () => queryClient.invalidateQueries([THREADS_CACHE_KEY])
    }
  )
}
