import React from "react"
import { Provider } from "../../types/Providers"
import { NoResultDisplay } from "./NoResultDisplay"
import { ResultList } from "./ResultList"
import { useUserContext } from "../../context/UserContext"
import { useMapViewContext } from "../../context/MapViewContext"
import { MapView } from "../map-view/MapView"
import { Benefit } from "lib/carrot-api/types/Benefit"
import { filterProviderByBenefitConfig } from "../../utils/parentIdMapping"
import { Helmet } from "react-helmet"
import { useIntl } from "react-intl"

export function ResultDisplay({
  providers,
  locationCountryCode,
  benefit
}: {
  providers: Provider[]
  locationCountryCode: string
  benefit: Benefit
}): JSX.Element {
  const intl = useIntl()
  const { companyCountryCode } = useUserContext()
  const { showMapView } = useMapViewContext()
  const isCountryCodeNotMatching = locationCountryCode && locationCountryCode !== companyCountryCode

  const filteredProviders = providers?.filter(filterProviderByBenefitConfig(benefit)) ?? []

  return (
    <>
      {filteredProviders?.length && !isCountryCodeNotMatching ? (
        showMapView ? (
          <MapView providers={filteredProviders as Provider[]} benefit={benefit} />
        ) : (
          <ResultList providers={filteredProviders as Provider[]} benefit={benefit} />
        )
      ) : (
        <>
          <Helmet>
            <title>
              {intl.formatMessage({
                defaultMessage: "No providers found | Find a provider",
                description: "The title of the current page as shown in the browser's tab or window."
              })}
            </title>
          </Helmet>
          <NoResultDisplay isCountryCodeNotMatching={isCountryCodeNotMatching} />
        </>
      )}
    </>
  )
}
