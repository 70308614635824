import React, { useEffect } from "react"
import { Stack, Box, Link, Typography, useTheme } from "@carrotfertility/carotene-core"
import { HomepageHelp } from "./components/homepage/HomepageHelp"
import { ProviderSearchForm } from "./components/homepage/ProviderSearchForm"
import { Benefit } from "lib/carrot-api/types/Benefit"
import { Helmet } from "react-helmet"
import { useIntl, FormattedMessage } from "react-intl"
import { SearchType, useSearchTypeContext } from "./context/SearchTypeContext"
import { Redirect, Link as RouterLink, useLocation } from "react-router-dom"
import useGetCustomNetworks from "./hooks/useGetCustomNetworks"
import { Loading } from "./components/Loading"
import { providerFinderRootPath } from "./utils/providerFinderSearchUrl"
import useShowProviderRecommendations from "./hooks/useShowProviderRecommendations"
import { getHeap } from "../../utils/heap"
import { ProviderRecommendationBanner } from "./components/homepage/ProviderRecommendationBanner"

const HeadingText = ({
  isCustomNetworks,
  isCarrotCore
}: {
  isCustomNetworks: boolean
  isCarrotCore: boolean
}): JSX.Element => {
  const intl = useIntl()
  const searchText = isCustomNetworks ? (
    <FormattedMessage defaultMessage="Search for providers in your network" />
  ) : (
    <FormattedMessage defaultMessage="Search for providers in the Carrot network" />
  )

  const findProviderText = (() => {
    if (isCustomNetworks) {
      return (
        <FormattedMessage defaultMessage="Find a provider that meets your needs and meets Carrot’s standards of excellence." />
      )
    }
    if (isCarrotCore) {
      return (
        <FormattedMessage defaultMessage="Find a provider that meets your needs. We've also partnered with select providers to help you save on costs and book priority appointments." />
      )
    }
    return (
      <FormattedMessage
        defaultMessage={
          "Find providers where you can use Carrot funds for <link>eligible care and services</link>. " +
          "Our experts have vetted all listed providers to ensure the highest standards."
        }
        values={{
          link: (linkContent) => (
            <Link
              component={RouterLink}
              color="inherit"
              to="/your-benefit-guide/coverage"
              title={intl.formatMessage({ defaultMessage: "Read about your eligible care and services" })}
            >
              {linkContent}
            </Link>
          )
        }}
      />
    )
  })()

  return (
    <Box sx={{ maxInlineSize: "40rem" }}>
      <Typography variant="h2" marginBottom={(theme) => theme.spacing(theme.tokens.spacing.md)}>
        {searchText}
      </Typography>
      <Typography variant="body1" color={(theme) => theme.palette.text.secondary}>
        {findProviderText}
      </Typography>
    </Box>
  )
}

export function ProviderHomepage({ benefit }: { benefit: Benefit }): JSX.Element {
  const { data, isLoading: isCustomNetworksLoading } = useGetCustomNetworks()
  const isCustomNetworks = data?.hasCustomNetworks

  const intl = useIntl()
  const theme = useTheme()
  const { pathname } = useLocation()
  const { searchType, setSearchType } = useSearchTypeContext()
  const { data: providerRecommendationsConfig, isLoading: isProviderRecommendationsLoading } =
    useShowProviderRecommendations()

  const isCarrotCore = benefit.program.isCarrotLite
  const showPediatricianRecommendationsBanner = providerRecommendationsConfig?.showPediatricianRecommendationsBanner
  const showObGynRecommendationsBanner = providerRecommendationsConfig?.showObGynRecommendationsBanner

  useEffect(() => {
    if (
      !isProviderRecommendationsLoading &&
      (showPediatricianRecommendationsBanner || showObGynRecommendationsBanner)
    ) {
      getHeap().track("Provider recommendations banner shown", {
        "Banner Type": showObGynRecommendationsBanner ? "OB/GYN" : "Pediatrician"
      })
    }
  }, [isProviderRecommendationsLoading, showPediatricianRecommendationsBanner, showObGynRecommendationsBanner])

  if (isCustomNetworksLoading || isProviderRecommendationsLoading) {
    return <Loading />
  }

  const showProviderRecommendationsBanner = providerRecommendationsConfig?.showProviderRecommendationsBanner

  if (!isCustomNetworks && isCarrotCore && pathname === `${providerFinderRootPath}/search-by-name`) {
    setSearchType(SearchType.LOCATION)
    return <Redirect to={providerFinderRootPath} />
  }
  if (pathname === providerFinderRootPath && searchType === SearchType.KEYWORD) {
    setSearchType(SearchType.LOCATION)
  }
  return (
    <>
      <Helmet>
        <title>
          {searchType === SearchType.KEYWORD
            ? intl.formatMessage({
                defaultMessage: "Provider Finder | Check provider eligibility | Carrot Fertility",
                description: "The title of the current page as shown in the browser's tab or window."
              })
            : intl.formatMessage({
                defaultMessage: "Provider Finder | Explore our network | Carrot Fertility",
                description: "The title of the current page as shown in the browser's tab or window."
              })}
        </title>
      </Helmet>
      <Stack direction="column" spacing={theme.spacing(theme.tokens.spacing.lg)}>
        <Box marginBottom={(theme) => theme.spacing(theme.tokens.spacing.lg)}>
          <HeadingText isCarrotCore={isCarrotCore} isCustomNetworks={isCustomNetworks} />
        </Box>
        {showProviderRecommendationsBanner && (
          <ProviderRecommendationBanner providerRecommendationConfig={providerRecommendationsConfig} />
        )}
        <ProviderSearchForm benefit={benefit} isCarrotCore={isCarrotCore} isCustomNetworks={isCustomNetworks} />
        {!showProviderRecommendationsBanner && (
          <Box marginTop={(theme) => theme.tokens.spacing.xxxl}>
            <HomepageHelp />
          </Box>
        )}
      </Stack>
    </>
  )
}
