import actionTypes from "actions/actionTypes"
import { reportError } from "utils/ErrorReporting"
import { signOut } from "./loginActions"
import { HttpErrors } from "utils/HttpErrors"
import { createRequestAction, createRequestActionWithParam } from "./shared"
import { carrotClient } from "utils/CarrotClient"

const fetchUserInfo = () => async (dispatch) => {
  dispatch({
    type: actionTypes.FETCH_USER_INFO_REQUEST
  })

  let response

  try {
    response = await carrotClient.getCurrentUser()
  } catch (error) {
    if (error.name === HttpErrors.UNAUTHORIZED) {
      dispatch({
        type: actionTypes.SESSION_EXPIRED
      })
      return
    }

    error.message = "Error getting user info: " + error.message
    reportError(error)
    dispatch({
      type: actionTypes.FETCH_USER_INFO_FAILURE,
      response: true
    })
    return
  }

  await dispatch({
    type: actionTypes.FETCH_USER_INFO_SUCCESS,
    response
  })

  if (response.subsidy) {
    dispatch({
      type: actionTypes.FETCH_SUBSIDY_STATUS_SUCCESS,
      response: response.subsidy
    })
  }

  if (response.subsidies) {
    dispatch({
      type: actionTypes.FETCH_SUBSIDIES_SUCCESS,
      response: response.subsidies
    })
  }
}

const updateUserInfo = (userInfo) => async (dispatch) => {
  try {
    await carrotClient.updateUser(userInfo)
    await dispatch(fetchUserInfo())
  } catch (error) {
    if (error.name === HttpErrors.UNAUTHORIZED) {
      dispatch(signOut())
      return
    }
    throw error
  }
}

const updateAboutYouInfo = (aboutYouInfo) => async (dispatch) => {
  try {
    await carrotClient.updateAboutYou(aboutYouInfo)
    await dispatch(fetchUserInfo())
  } catch (error) {
    if (error.name === HttpErrors.UNAUTHORIZED) {
      dispatch(signOut())
      return
    }
    throw error
  }
}

const updateSex = (sex) => async (dispatch) => {
  try {
    await carrotClient.updateUser({
      sex
    })
  } catch (error) {
    error.message = "Error updating sex: " + error.message
    reportError(error)
    dispatch({
      type: actionTypes.UPDATE_SEX_FAILURE
    })
    return
  }

  dispatch({
    type: actionTypes.UPDATE_SEX_SUCCESS,
    response: {
      sex
    }
  })
}

const updateEmailOptIn = (emailOptIn) => async (dispatch) => {
  try {
    await carrotClient.updateUser({
      emailOptIn
    })
  } catch (error) {
    error.message = "Error updating emailOptIn: " + error.message
    reportError(error)
    dispatch({
      type: actionTypes.UPDATE_EMAIL_OPT_IN_FAILURE
    })
    return
  }

  dispatch({
    type: actionTypes.UPDATE_EMAIL_OPT_IN_SUCCESS,
    response: {
      emailOptIn
    }
  })
}

const updatePartnerName =
  ({ partnerFirstName, partnerLastName }) =>
  async (dispatch) => {
    try {
      await carrotClient.updateUser({
        partnerFirstName,
        partnerLastName
      })
    } catch (error) {
      error.message = "Error updating partner name: " + error.message
      reportError(error)
      dispatch({
        type: actionTypes.UPDATE_PARTNER_NAME_FAILURE
      })
      return
    }

    dispatch({
      type: actionTypes.UPDATE_PARTNER_NAME_SUCCESS,
      response: {
        partnerFirstName,
        partnerLastName
      }
    })
  }

const updatePartnerEmail =
  ({ partnerEmail }) =>
  async (dispatch) => {
    try {
      await carrotClient.updateUser({
        partnerEmail
      })
    } catch (error) {
      error.message = "Error updating partnerEmail: " + error.message
      reportError(error)
      dispatch({
        type: actionTypes.UPDATE_PARTNER_EMAIL_FAILURE
      })
      return
    }

    dispatch({
      type: actionTypes.UPDATE_PARTNER_EMAIL_SUCCESS,
      response: {
        partnerEmail
      }
    })
  }

export const updatePartnerDateOfBirth = (partnerDateOfBirth) => async (dispatch) => {
  try {
    await carrotClient.updateUser({
      partnerDateOfBirth
    })
  } catch (error) {
    error.message = "Error updating partnerDateOfBirth: " + error.message
    reportError(error)
    dispatch({
      type: actionTypes.UPDATE_PARTNER_DOB_FAILURE
    })
    return
  }

  dispatch({
    type: actionTypes.UPDATE_PARTNER_DOB_SUCCESS,
    response: {
      partnerDateOfBirth
    }
  })
}

const updatePartnerSex = (partnerSex) => async (dispatch) => {
  try {
    await carrotClient.updateUser({
      partnerSex
    })
  } catch (error) {
    error.message = "Error updating partnerSex: " + error.message
    reportError(error)
    dispatch({
      type: actionTypes.UPDATE_PARTNER_SEX_FAILURE
    })
    return
  }

  dispatch({
    type: actionTypes.UPDATE_PARTNER_SEX_SUCCESS,
    response: {
      partnerSex
    }
  })
}

const updatePartnerGenderIdentity = (partnerGenderIdentity) => async (dispatch) => {
  try {
    await carrotClient.updateUser({
      partnerGenderIdentity
    })
  } catch (error) {
    error.message = "Error updating partner gender identity: " + error.message
    reportError(error)
    dispatch({
      type: actionTypes.UPDATE_PARTNER_GENDER_IDENTITY_FAILURE
    })
    return
  }

  dispatch({
    type: actionTypes.UPDATE_PARTNER_GENDER_IDENTITY_SUCCESS,
    response: {
      partnerGenderIdentity
    }
  })
}

const updateGenderIdentity = (genderIdentity) => async (dispatch) => {
  try {
    await carrotClient.updateUser({
      genderIdentity
    })
  } catch (error) {
    error.message = "Error updating genderIdentity: " + error.message
    reportError(error)
    dispatch({
      type: actionTypes.UPDATE_GENDER_IDENTITY_FAILURE
    })
    return
  }

  dispatch({
    type: actionTypes.UPDATE_GENDER_IDENTITY_SUCCESS,
    response: {
      genderIdentity
    }
  })
}

const updateMemberHasLegalPartner = (memberHasLegalPartner) =>
  createRequestActionWithParam(
    "UPDATE_MEMBER_HAS_LEGAL_PARTNER",
    memberHasLegalPartner,
    "memberHasLegalPartner",
    async function () {
      await carrotClient.updateUser({
        memberHasLegalPartner
      })
      return memberHasLegalPartner
    }
  )

const updateMemberCoverageType = (memberCoverageType) =>
  createRequestActionWithParam(
    "UPDATE_MEMBER_COVERAGE_TYPE",
    memberCoverageType,
    "memberCoverageType",
    async function () {
      await carrotClient.updateUser({
        memberCoverageType
      })
      return memberCoverageType
    }
  )

const updateMemberPlanSource = (memberPlanSource) =>
  createRequestActionWithParam("UPDATE_MEMBER_PLAN_SOURCE", memberPlanSource, "memberPlanSource", async function () {
    await carrotClient.updateUser({
      memberPlanSource
    })
    return memberPlanSource
  })

const updateMemberHasHsa = (memberHasHsa) =>
  createRequestActionWithParam("UPDATE_MEMBER_HAS_HSA", memberHasHsa, "memberHasHSA", async function () {
    await carrotClient.updateUser({
      memberHasHsa
    })
    return memberHasHsa
  })

const updatePartnerCoverageType = (partnerCoverageType) =>
  createRequestActionWithParam(
    "UPDATE_PARTNER_COVERAGE_TYPE",
    partnerCoverageType,
    "partnerCoverageType",
    async function () {
      await carrotClient.updateUser({
        partnerCoverageType
      })
      return partnerCoverageType
    }
  )

const updatePartnerPlanSource = (partnerPlanSource) =>
  createRequestActionWithParam("UPDATE_PARTNER_PLAN_SOURCE", partnerPlanSource, "partnerPlanSource", async function () {
    await carrotClient.updateUser({
      partnerPlanSource
    })
    return partnerPlanSource
  })

const updatePartnerHasHsa = (partnerHasHSA) =>
  createRequestActionWithParam("UPDATE_PARTNER_HAS_HSA", partnerHasHSA, "partnerHasHSA", async function () {
    await carrotClient.updateUser({
      partnerHasHSA
    })
    return partnerHasHSA
  })

const updateMemberId = (memberId) =>
  createRequestActionWithParam("UPDATE_MEMBER_ID", memberId, "memberId", async function () {
    await carrotClient.updateUser({
      memberId
    })
    return memberId
  })

const updateSelectedTradingPartnerId = (selectedTradingPartnerId) =>
  createRequestActionWithParam(
    "UPDATE_SELECTED_TRADING_PARTNER",
    selectedTradingPartnerId,
    "selectedTradingPartnerId",
    async function () {
      await carrotClient.updateUser({
        selectedTradingPartnerId
      })
      return selectedTradingPartnerId
    }
  )

const updateBenefitEnrollmentInfo = (userInfo) => async (dispatch) => {
  try {
    await carrotClient.updateUser(userInfo)
    await dispatch(getBenefitEnrollment())
  } catch (error) {
    if (error.name === HttpErrors.UNAUTHORIZED) {
      dispatch(signOut())
      return
    }
    throw error
  }
}

const getBenefitEnrollment = createRequestAction("FETCH_BENEFIT_ENROLLMENT", async function () {
  return await carrotClient.getBenefitEnrollment()
})

export {
  updatePartnerSex,
  fetchUserInfo,
  updateSex,
  updatePartnerName,
  updatePartnerEmail,
  updateEmailOptIn,
  updatePartnerGenderIdentity,
  updateGenderIdentity,
  updateUserInfo,
  updateAboutYouInfo,
  updateMemberHasLegalPartner,
  updateMemberCoverageType,
  updateMemberPlanSource,
  updateMemberHasHsa,
  updatePartnerCoverageType,
  updatePartnerPlanSource,
  updatePartnerHasHsa,
  updateMemberId,
  updateSelectedTradingPartnerId,
  updateBenefitEnrollmentInfo
}
