import { useQuery } from "@tanstack/react-query"
import ResponseError from "../../../types/responseError"
import { carrotClient } from "../../../utils/CarrotClient"
import { HttpStatusCodes } from "../../../utils/HttpStatusCodes"
import { getBenefitCacheKey } from "../../dynamic-content/hooks/useGetBenefit"
import { Benefit } from "lib/carrot-api/types/Benefit"

export default function useGetMemberBenefit() {
  return useQuery<Benefit, ResponseError>(getBenefitCacheKey(), () => carrotClient.getBenefit(), {
    retry: (failureCount, error) => {
      switch (error.statusCode) {
        case HttpStatusCodes.UNAUTHORIZED:
        case HttpStatusCodes.BAD_REQUEST:
        case HttpStatusCodes.NOT_FOUND:
          return false
        default:
          return failureCount <= 3
      }
    }
  })
}
