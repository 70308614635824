import { Benefit } from "lib/carrot-api/types/Benefit"
import { defineMessage } from "react-intl"
import { RoutingInfo } from "types/routingInfo"

export const mostHelpfulOptions = [
  { label: defineMessage({ defaultMessage: "Discuss my options and journey" }), value: "DISCUSS_OPTIONS" },
  { label: defineMessage({ defaultMessage: "Help with pathways or regulations" }), value: "HELP_PATHWAY" },
  { label: defineMessage({ defaultMessage: "Help finding or choosing a provider" }), value: "HELP_FINDING_PROVIDER" },
  {
    label: defineMessage({ defaultMessage: "Chat about menstrual cycles and evaluating fertility" }),
    value: "CHAT_FERTILITY"
  },
  { label: defineMessage({ defaultMessage: "Learn about nutrition and lifestyle" }), value: "CHAT_LIFESTYLE" },
  {
    label: defineMessage({ defaultMessage: "Chat about health and wellness-related questions" }),
    value: "CHAT_HEALTH"
  },
  {
    label: defineMessage({ defaultMessage: "Information about birth doulas" }),
    value: "DOULA_BIRTH_INFO"
  },
  {
    label: defineMessage({ defaultMessage: "Information about birth and postpartum doulas" }),
    value: "DOULA_INFO"
  },
  {
    label: defineMessage({ defaultMessage: "Information about postpartum doulas" }),
    value: "DOULA_POSTPARTUM_INFO"
  },
  {
    label: defineMessage({ defaultMessage: "Information about high-risk pregnancy" }),
    value: "HIGH_RISK_PREGNANCY_INFO"
  },
  {
    label: defineMessage({ defaultMessage: "Learn about 1st trimester fetal development and medical care" }),
    value: "LEARN_FIRST_TRIMESTER"
  },
  {
    label: defineMessage({ defaultMessage: "Learn about 2nd trimester fetal development and medical care" }),
    value: "LEARN_SECOND_TRIMESTER"
  },
  {
    label: defineMessage({ defaultMessage: "Learn about 3rd trimester fetal development and medical care" }),
    value: "LEARN_THIRD_TRIMESTER"
  },
  {
    label: defineMessage({ defaultMessage: "Help choosing a birth location and providers" }),
    value: "HELP_CHOOSE_BIRTH_LOCATION_PROVIDERS"
  },
  {
    label: defineMessage({ defaultMessage: "Help with birth planning, including choosing a location and providers" }),
    value: "HELP_BIRTH_PLANNING"
  },
  {
    label: defineMessage({ defaultMessage: "Help preparing for labor and delivery" }),
    value: "HELP_PREP_LABOR_DELIVERY"
  },
  {
    label: defineMessage({ defaultMessage: "Emotional support" }),
    value: "EMOTIONAL_SUPPORT"
  },
  {
    label: defineMessage({ defaultMessage: "Prenatal yoga class" }),
    value: "PRENATAL_YOGA"
  },
  {
    label: defineMessage({ defaultMessage: "Learn about milk shipping" }),
    value: "LEARN_MILK_SHIPPING"
  },
  {
    label: defineMessage({ defaultMessage: "Learn about lactation" }),
    value: "LEARN_LACTATION"
  },
  {
    label: defineMessage({ defaultMessage: "Help navigating the NICU experience" }),
    value: "HELP_NICU"
  },
  {
    label: defineMessage({ defaultMessage: "Learn about parents' nutrition and lifestyle" }),
    value: "LEARN_PARENT_NUTRITION_LIFESTYLE"
  },
  { label: defineMessage({ defaultMessage: "Help returning to work" }), value: "SUPPORT_WORK_RETURN" },
  {
    label: defineMessage({ defaultMessage: "Support with lactation and infant feeding" }),
    value: "SUPPORT_LACTATION_INFANT_FEEDING"
  },
  {
    label: defineMessage({ defaultMessage: "Learn about newborn/infant health and care" }),
    value: "LEARN_INFANT_CARE"
  },
  {
    label: defineMessage({ defaultMessage: "Help with newborn/infant feeding" }),
    value: "HELP_INFANT_FEEDING"
  },
  {
    label: defineMessage({ defaultMessage: "Help with newborn/infant sleep" }),
    value: "HELP_INFANT_SLEEP"
  },
  {
    label: defineMessage({ defaultMessage: "Learn about children's health and safety" }),
    value: "LEARN_CHILD_HEALTH_SAFETY"
  },
  {
    label: defineMessage({ defaultMessage: "Understand key developmental milestones and neurodivergence" }),
    value: "UNDERSTAND_MILESTONES_NEURODIVERGENCE"
  },
  {
    label: defineMessage({ defaultMessage: "Positive parenting tips" }),
    value: "POSITIVE_PARENTING_TIPS"
  },
  {
    label: defineMessage({ defaultMessage: "Help with children's nutrition and exercise" }),
    value: "HELP_CHILD_NUTRITION_EXERCISE"
  },
  {
    label: defineMessage({ defaultMessage: "Help with sleep issues" }),
    value: "HELP_SLEEP_ISSUES"
  },
  {
    label: defineMessage({ defaultMessage: "Learn about children's emotional well-being" }),
    value: "LEARN_CHILD_EMOTIONAL_WELL_BEING"
  },
  {
    label: defineMessage({ defaultMessage: "Learn about puberty" }),
    value: "LEARN_PUBERTY"
  },
  { label: defineMessage({ defaultMessage: "LGBTQ+ family-forming guidance" }), value: "LGBTQ_FAMILY" },
  { label: defineMessage({ defaultMessage: "Financial information or reimbursement" }), value: "FINANCE_INFO" },
  { label: defineMessage({ defaultMessage: "Advice on making the most of my benefit" }), value: "BENEFIT_ADVICE" },
  { label: defineMessage({ defaultMessage: "I’m not sure" }), value: "NOT_SURE" }
]

export const helpOptionsByJourney: Record<string, string[]> = {
  ADOPTION: [
    "DISCUSS_OPTIONS",
    "HELP_PATHWAY",
    "HELP_FINDING_PROVIDER",
    "LGBTQ_FAMILY",
    "FINANCE_INFO",
    "BENEFIT_ADVICE",
    "NOT_SURE"
  ],
  GESTATIONAL: [
    "DISCUSS_OPTIONS",
    "HELP_PATHWAY",
    "HELP_FINDING_PROVIDER",
    "LGBTQ_FAMILY",
    "FINANCE_INFO",
    "BENEFIT_ADVICE",
    "NOT_SURE"
  ],
  PRESERVATION: [
    "DISCUSS_OPTIONS",
    "CHAT_HEALTH",
    "LGBTQ_FAMILY",
    "FINANCE_INFO",
    "BENEFIT_ADVICE",
    "HELP_FINDING_PROVIDER",
    "NOT_SURE"
  ],
  ASSISTED_REPRODUCTION: [
    "DISCUSS_OPTIONS",
    "CHAT_HEALTH",
    "LGBTQ_FAMILY",
    "FINANCE_INFO",
    "BENEFIT_ADVICE",
    "HELP_FINDING_PROVIDER",
    "NOT_SURE"
  ],
  TRY_PREGNANT: [
    "DISCUSS_OPTIONS",
    "CHAT_FERTILITY",
    "CHAT_LIFESTYLE",
    "LGBTQ_FAMILY",
    "FINANCE_INFO",
    "BENEFIT_ADVICE",
    "HELP_FINDING_PROVIDER",
    "NOT_SURE"
  ],
  PREGNANT: ["CHAT_LIFESTYLE", "CHAT_HEALTH", "FINANCE_INFO", "BENEFIT_ADVICE", "NOT_SURE"],
  EXPLORING: ["CHAT_HEALTH", "LGBTQ_FAMILY", "FINANCE_INFO", "BENEFIT_ADVICE", "HELP_FINDING_PROVIDER", "NOT_SURE"],
  MENOPAUSE: [
    "DISCUSS_OPTIONS",
    "HELP_FINDING_PROVIDER",
    "CHAT_FERTILITY",
    "CHAT_LIFESTYLE",
    "CHAT_HEALTH",
    "BENEFIT_ADVICE",
    "NOT_SURE"
  ],
  LOW_TESTOSTERONE: [
    "DISCUSS_OPTIONS",
    "HELP_FINDING_PROVIDER",
    "CHAT_LIFESTYLE",
    "CHAT_HEALTH",
    "BENEFIT_ADVICE",
    "NOT_SURE"
  ],
  GENDER_AFFIRMING_CARE: [
    "DISCUSS_OPTIONS",
    "HELP_FINDING_PROVIDER",
    "CHAT_HEALTH",
    "LGBTQ_FAMILY",
    "FINANCE_INFO",
    "BENEFIT_ADVICE",
    "NOT_SURE"
  ]
}

export const getHelpOptionsByJourneyStage = (stage: string) => {
  switch (stage) {
    case "PREGNANT_FIRST":
      return [
        "CHAT_LIFESTYLE",
        "CHAT_HEALTH",
        "DOULA_BIRTH_INFO",
        "HIGH_RISK_PREGNANCY_INFO",
        "LEARN_FIRST_TRIMESTER",
        "HELP_CHOOSE_BIRTH_LOCATION_PROVIDERS",
        "EMOTIONAL_SUPPORT",
        "PRENATAL_YOGA",
        "FINANCE_INFO",
        "BENEFIT_ADVICE",
        "NOT_SURE"
      ]
    case "PREGNANT_SECOND":
      return [
        "CHAT_LIFESTYLE",
        "CHAT_HEALTH",
        "DOULA_INFO",
        "HIGH_RISK_PREGNANCY_INFO",
        "LEARN_SECOND_TRIMESTER",
        "HELP_BIRTH_PLANNING",
        "EMOTIONAL_SUPPORT",
        "PRENATAL_YOGA",
        "FINANCE_INFO",
        "BENEFIT_ADVICE",
        "NOT_SURE"
      ]
    case "PREGNANT_THIRD":
      return [
        "CHAT_LIFESTYLE",
        "CHAT_HEALTH",
        "DOULA_INFO",
        "LEARN_THIRD_TRIMESTER",
        "HELP_PREP_LABOR_DELIVERY",
        "EMOTIONAL_SUPPORT",
        "PRENATAL_YOGA",
        "LEARN_MILK_SHIPPING",
        "LEARN_LACTATION",
        "FINANCE_INFO",
        "BENEFIT_ADVICE",
        "NOT_SURE"
      ]
    case "POSTPARTUM":
      return [
        "CHAT_LIFESTYLE",
        "CHAT_HEALTH",
        "DOULA_POSTPARTUM_INFO",
        "EMOTIONAL_SUPPORT",
        "LEARN_MILK_SHIPPING",
        "HELP_NICU",
        "SUPPORT_WORK_RETURN",
        "SUPPORT_LACTATION_INFANT_FEEDING",
        "FINANCE_INFO",
        "BENEFIT_ADVICE",
        "NOT_SURE"
      ]
    case "CHILD_LESS_THAN_YEAR":
      return [
        "LEARN_MILK_SHIPPING",
        "HELP_NICU",
        "LEARN_PARENT_NUTRITION_LIFESTYLE",
        "SUPPORT_WORK_RETURN",
        "LEARN_INFANT_CARE",
        "HELP_INFANT_FEEDING",
        "HELP_INFANT_SLEEP",
        "FINANCE_INFO",
        "BENEFIT_ADVICE",
        "NOT_SURE"
      ]
    case "CHILD_ONE_TO_TWO_YEARS":
    case "CHILD_THREE_TO_FIVE_YEARS":
      return [
        "LEARN_MILK_SHIPPING",
        "LEARN_PARENT_NUTRITION_LIFESTYLE",
        "SUPPORT_WORK_RETURN",
        "LEARN_CHILD_HEALTH_SAFETY",
        "UNDERSTAND_MILESTONES_NEURODIVERGENCE",
        "POSITIVE_PARENTING_TIPS",
        "HELP_CHILD_NUTRITION_EXERCISE",
        "HELP_SLEEP_ISSUES",
        "LEARN_CHILD_EMOTIONAL_WELL_BEING",
        "FINANCE_INFO",
        "BENEFIT_ADVICE",
        "NOT_SURE"
      ]
    case "CHILD_SIX_TO_EIGHT_YEARS":
    case "CHILD_NINE_TO_TWELVE_YEARS":
      return [
        "LEARN_PARENT_NUTRITION_LIFESTYLE",
        "SUPPORT_WORK_RETURN",
        "LEARN_CHILD_HEALTH_SAFETY",
        "UNDERSTAND_MILESTONES_NEURODIVERGENCE",
        "POSITIVE_PARENTING_TIPS",
        "HELP_CHILD_NUTRITION_EXERCISE",
        "HELP_SLEEP_ISSUES",
        "LEARN_CHILD_EMOTIONAL_WELL_BEING",
        "LEARN_PUBERTY",
        "FINANCE_INFO",
        "BENEFIT_ADVICE",
        "NOT_SURE"
      ]
    default:
      return []
  }
}

function filterOptions(
  helpOptions: string[],
  hideLGBTQGuidanceOption: boolean,
  carrotLite: boolean,
  hideChatAboutCyclesOptionForNoCycles: boolean,
  companyHasProviderFinder: boolean
): string[] {
  let filteredOptions = helpOptions
  if (hideLGBTQGuidanceOption) {
    filteredOptions = filteredOptions.filter((opt) => opt !== "LGBTQ_FAMILY")
  }
  if (carrotLite) {
    filteredOptions = filteredOptions.filter((opt) => opt !== "FINANCE_INFO")
  }
  if (hideChatAboutCyclesOptionForNoCycles) {
    filteredOptions = filteredOptions.filter((opt) => opt !== "CHAT_FERTILITY")
  }
  if (!companyHasProviderFinder) {
    filteredOptions = filteredOptions.filter((opt) => opt !== "HELP_FINDING_PROVIDER")
  }
  return filteredOptions
}

export function useMostHelpfulOptions(
  routingData: RoutingInfo,
  benefit: Benefit
): {
  buttonOptions: { label: { defaultMessage: string }; value: string }[]
} {
  const memberJourney = routingData?.journey
  const memberJourneyStage = routingData?.journeyStage
  const hideChatAboutCyclesOptionForNoCycles: boolean = memberJourneyStage === "NO_MENSTRUAL_CYCLES"
  const hideLGBTQGuidanceOption = !benefit?.generalSupport.showLgbtqContent
  const baseOptionsOnJourneyStage = ["PREGNANT", "PARENTING"].includes(memberJourney)
  const options = baseOptionsOnJourneyStage
    ? getHelpOptionsByJourneyStage(memberJourneyStage)
    : helpOptionsByJourney[memberJourney] || []

  const journeyHelpOptions = filterOptions(
    options,
    hideLGBTQGuidanceOption,
    benefit?.program.isCarrotLite,
    hideChatAboutCyclesOptionForNoCycles,
    benefit?.providerFinder.showProviderFinder
  )

  const buttonOptions = mostHelpfulOptions.filter((option) => journeyHelpOptions.includes(option.value))
  return {
    buttonOptions
  }
}
