import { TalkToCarrotLegal } from "components/talk-to-carrot/TalkToCarrotLegal"
import TalkToCarrotTilePage from "components/talk-to-carrot/TalkToCarrotTilePage"

import { GuidanceElement } from "lib/contentful/components/GuidanceElement"
import React, { useContext } from "react"
import { useIntl } from "react-intl"
import { useHistory } from "react-router-dom"
import { PageLayout } from "features/global-ui/page-layout"
import { TalkToCarrotChatHeader } from "./TalkToCarrotChatHeader"
import { useGetExpertGuidance } from "./hooks/useGetExpertGuidance"
import { useGetCareNavigationTileDisplay } from "./hooks/useGetCareNavigationTileDisplay"
import { useGetSpecialistTileDisplay } from "./hooks/useGetSpecialistTileDisplay"
import { useGetDisabledSpecialistTileDisplay } from "./hooks/useGetDisabledSpecialistTileDisplay"
import useBenefit from "components/dynamic-content/hooks/useGetBenefit"
import { createTileElement } from "./util/talkToCarrotMolecules"
import { useCurrentUser } from "components/context/user/UserContext"
import { FullScreenLoading } from "components/read/shared/FullScreenLoading"
import { TalkToCarrotContext } from "./TalkToCarrotContext"
import { useFlags } from "launchdarkly-react-client-sdk"
import { ProgramType } from "lib/carrot-api/types/Benefit"

export const TalkToCarrotExpertPage = (): JSX.Element => {
  const history = useHistory()
  const { data: benefit, isLoading: isBenefitLoading } = useBenefit()
  const { isInAppLockdown } = useCurrentUser()

  // making this logic match the Contentful condition for the Specialist tiles
  const isProgramProOrCore =
    benefit && (benefit.program.type === ProgramType.PRO || benefit.program.type === ProgramType.CORE)

  const intl = useIntl()
  const { locale } = intl
  const { data: guidance } = useGetExpertGuidance(locale)
  const { data: careNavigationTileDisplay, isLoading: isCareNavigationTileDisplayLoading } =
    useGetCareNavigationTileDisplay(locale)
  const { data: specialistTileDisplay, isLoading: isSpecialistTileDisplayLoading } = useGetSpecialistTileDisplay(locale)
  const { data: disabledSpecialistTileDisplay, isLoading: isDisabledSpecialistTileDisplayLoading } =
    useGetDisabledSpecialistTileDisplay(locale)

  const careNavTile = !isCareNavigationTileDisplayLoading
    ? createTileElement({
        tileDisplay: careNavigationTileDisplay,
        onClick: () => history.push("/talk-to-carrot/care-navigation")
      })
    : null

  const { ttcReduceExpertChatSteps } = useFlags()
  const { selectedLocationOption } = useContext(TalkToCarrotContext)
  const specialistTileRoute =
    ttcReduceExpertChatSteps && selectedLocationOption
      ? `/talk-to-carrot/${selectedLocationOption.slug}/specialist`
      : "/talk-to-carrot/location"

  const specialistTile = !isSpecialistTileDisplayLoading
    ? createTileElement({
        tileDisplay: specialistTileDisplay,
        onClick: () => history.push(specialistTileRoute)
      })
    : null

  const disabledSpecialistTile = !isDisabledSpecialistTileDisplayLoading
    ? createTileElement({
        tileDisplay: disabledSpecialistTileDisplay,
        onClick: null,
        isDisabled: true
      })
    : null

  const availableTiles = [careNavTile]

  if (isProgramProOrCore) {
    availableTiles.push(...(!isInAppLockdown ? [specialistTile] : [disabledSpecialistTile]))
  }

  if (isBenefitLoading) {
    return <FullScreenLoading />
  }

  return (
    <PageLayout footer={<TalkToCarrotLegal />} header={<TalkToCarrotChatHeader />}>
      <TalkToCarrotTilePage guidance={<GuidanceElement guidance={guidance} />} tiles={availableTiles.filter(Boolean)} />
    </PageLayout>
  )
}
