/* eslint formatjs/no-literal-string-in-jsx: off -- internal tool intentionally not translated */

import React, { useEffect, useState } from "react"
import styled from "styled-components"
import { useCurrentUser } from "../context/user/UserContext"
import { ArrowLink } from "../views/atoms/links/ArrowLink"
import { carrotClient } from "utils/CarrotClient"
import Settings from "../../utils/CarrotConfig"
import queryString from "query-string"
import { unsetHeapPosingAsMember } from "utils/heap"

const StyledMemberPoseWarningMessage = styled.div`
  position: fixed;
  inset-block-start: 0;
  inset-inline-start: 0;
  inset-inline-end: 0;
  border: 4px;
  border-radius: 0 0 4px 4px;
  opacity: 0.8;
  inline-size: 100%;
  padding: 24px 48px;
  background-color: #ef4c6a;
  color: white;
  font-size: 24px;
  font-weight: bold;
  text-align: center;
  z-index: 999999;
`

export function MemberPoseWarningMessage(): JSX.Element {
  const { isPosingAsMember } = useCurrentUser()
  const [returnUrl, setReturnUrl] = useState("")

  useEffect(() => {
    const stringParsedQueryElement = queryString.parse(window.location.search)["returnUrl"] as string
    if (stringParsedQueryElement) {
      setReturnUrl(stringParsedQueryElement)
    }
  }, [])

  async function handleBackToAdmin() {
    await carrotClient.unPoseAsMember()

    unsetHeapPosingAsMember()

    const adminUrl = returnUrl ?? Settings.ABALONE_URL
    window.location.replace(adminUrl)
  }

  return isPosingAsMember ? (
    <StyledMemberPoseWarningMessage>
      Be careful, you are currently posing as a member!
      <ArrowLink
        onClickOnly
        arrowAfter
        color="white"
        href="#"
        onClick={handleBackToAdmin}
        style={{ fontSize: 14, position: "absolute", right: 48, top: 24 }}
      >
        Back to admin
      </ArrowLink>
    </StyledMemberPoseWarningMessage>
  ) : null
}
