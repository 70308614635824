import { BankDetailsData } from "lib/carrot-api/types/Bank"
import useQueryBankDetails from "./useQueryBankDetails"
import useQueryBankDetailsSchema from "./useQueryBankDetailsSchema"

export default function useGetBankDetailsPageData(): {
  isSuccess: boolean
  isLoading: boolean
  isError: boolean
  schema: any
  data: BankDetailsData
  refetchData: any
} {
  const schema = useQueryBankDetailsSchema()
  const bankDetails = useQueryBankDetails()

  return {
    isSuccess: schema.isSuccess && bankDetails.isSuccess,
    isLoading: schema.isLoading || bankDetails.isLoading,
    isError: schema.isError || bankDetails.isError,
    schema: schema.data,
    data: bankDetails.data,
    refetchData: bankDetails.refetch
  }
}
