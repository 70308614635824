import { Asset } from "contentful"
import { TypeExpertBioSkeleton } from "types/contentful"
import { RawContentfulConditionSkeleton } from "lib/contentful/utils/conditionUtils"
import { EntryWithoutUnresolvableLinks } from "utils/ContentTypes"

export type File = {
  url: string
}

export type Headshot = {
  file: File
}
export type ExpertBio = {
  id: string
  slug: string
  name: string
  credentials: string
  headshot: Headshot
  description: string
  conditions: EntryWithoutUnresolvableLinks<RawContentfulConditionSkeleton>[]
}

const mapContentToHeadshot = ({ fields }: Asset<"WITHOUT_UNRESOLVABLE_LINKS">): Headshot => {
  if (!fields) {
    return null
  }
  const { file } = fields
  return {
    file
  }
}
export const mapContentToExpertBio = ({
  fields,
  sys
}: EntryWithoutUnresolvableLinks<TypeExpertBioSkeleton>): ExpertBio => {
  if (!fields || !sys) {
    return null
  }
  const { slug, name, credentials, headshot, description, conditions } = fields
  const { id } = sys

  return {
    id,
    slug,
    name,
    credentials,
    headshot: headshot && mapContentToHeadshot(headshot),
    description,
    conditions
  }
}
