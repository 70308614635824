import React from "react"
import { FlexContainer } from "@carrotfertility/carotene"
import { Typography } from "@carrotfertility/carotene-core"
import { useStateMachine } from "components/context/stateMachine/StateMachineContext"
import useInitialSetup from "components/cmd-enrollment/hooks/useInitialSetup"
import { Steps } from "components/cmd-enrollment/workflow/steps"
import { CenteredLoadingIndicator } from "components/views/molecules/Molecules"
import { useUserDeductible } from "../../../../context/user/DeductibleContext"
import { useSelector } from "react-redux"
import { getReimbursementDetails } from "../../../../../reducers/reimbursementDetails"
import { getUserInfo } from "reducers/userInfo"
import { FormattedMessage } from "react-intl"
import { carrotClient } from "utils/CarrotClient"

interface ProcessConnectInsuranceStepProps {
  viewName: typeof Steps[keyof typeof Steps]
  handleVerifyInsurance: () => Promise<boolean>
}

function ProcessConnectInsuranceStep({
  viewName,
  handleVerifyInsurance
}: ProcessConnectInsuranceStepProps): JSX.Element {
  const { refreshUserBenefitEnrollment } = useUserDeductible()
  const reimbursementDetails = useSelector(getReimbursementDetails)
  const userInfo = useSelector(getUserInfo)
  const { setNextStep } = useStateMachine(viewName, null)

  useInitialSetup(async () => {
    const success = await handleVerifyInsurance()
    await refreshUserBenefitEnrollment()

    const knownTradingPartnerOutages = reimbursementDetails.knownTradingPartnerOutages
    const selectedPartner = userInfo.selectedTradingPartnerId
    const knownOutage = knownTradingPartnerOutages.includes(selectedPartner)

    if (!success && knownOutage) {
      setNextStep(viewName, "KNOWN_OUTAGE")
    } else {
      setNextStep(viewName, success)
    }
  })

  return (
    <FlexContainer alignItems="center" direction="column" paddingTopBottom="large">
      <CenteredLoadingIndicator />
      <Typography
        id="step-heading"
        variant="h2"
        color={(theme) => theme.palette.text.primary}
        paddingBottom={(theme) => theme.tokens.spacing.lg}
      >
        <FormattedMessage defaultMessage="We're working on your request." />
      </Typography>
    </FlexContainer>
  )
}

export function MemberProcessConnectInsuranceStep(): JSX.Element {
  const viewName = Steps.MEMBER_PROCESS_CONNECT_INSURANCE

  function handleVerifyInsurance(): Promise<boolean> {
    return carrotClient.verifyMemberInsurance()
  }

  return <ProcessConnectInsuranceStep {...{ viewName, handleVerifyInsurance }} />
}

export function PartnerProcessConnectInsuranceStep(): JSX.Element {
  const viewName = Steps.PARTNER_PROCESS_CONNECT_INSURANCE

  function handleVerifyInsurance(): Promise<boolean> {
    return carrotClient.verifyPartnerInsurance()
  }

  return <ProcessConnectInsuranceStep {...{ viewName, handleVerifyInsurance }} />
}

export {
  PartnerConnectSuccess as PartnerProcessConnectInsuranceSuccessStep,
  MemberConnectSuccess as MemberProcessConnectInsuranceSuccessStep
} from "./Success"
