import { useQuery, UseQueryResult } from "@tanstack/react-query"
import { InAppMessagingThreads } from "../inAppMessagingTypes"
import ResponseError from "../../../types/responseError"
import { carrotClient } from "utils/CarrotClient"
import { HttpStatusCodes } from "../../../utils/HttpStatusCodes"

export const THREADS_CACHE_KEY = "threads"

export default function useGetThreads(): UseQueryResult<InAppMessagingThreads, ResponseError> {
  return useQuery<InAppMessagingThreads, ResponseError>(
    [THREADS_CACHE_KEY],
    () => carrotClient.getInAppMessagingThreads(),
    {
      staleTime: 60000,
      retry: (failureCount, error) => {
        switch (error.statusCode) {
          case HttpStatusCodes.UNAUTHORIZED:
          case HttpStatusCodes.BAD_REQUEST:
          case HttpStatusCodes.NOT_FOUND:
            return false
          default:
            return failureCount <= 3
        }
      }
    }
  )
}
