import { useQuery, UseQueryResult } from "@tanstack/react-query"
import buildCarrotApiRetryPolicy from "utils/CarrotApiRetryPolicy"
import ResponseError from "types/responseError"
import { BankDetailsData } from "lib/carrot-api/types/Bank"
import { carrotClient } from "utils/CarrotClient"

const queryKey = "getMemberBankDetails"

export default function useQueryBankDetails(): UseQueryResult<BankDetailsData, ResponseError> {
  return useQuery([queryKey], () => carrotClient.getBankDetails(), {
    retry: buildCarrotApiRetryPolicy(1),
    refetchOnWindowFocus: false
  })
}
