import React, { useState, useEffect } from "react"
import { init } from "@airwallex/components-sdk"
import useGetScaAuthorizationCode from "./hooks/useGetScaAuthorizationCode"
import Settings from "../../../../../utils/CarrotConfig"
import { InfoDialog } from "@carrotfertility/carotene-core-x"
import { AirwallexScaSetup } from "./AirwallexScaSetup"
import { AirwallexScaVerify } from "./AirwallexScaVerify"
import { Box, Progress } from "@carrotfertility/carotene-core"
import { AirwallexScaInstructions } from "./AirwallexScaInstructions"
import useGetReimbursementDetails from "../../../../../services/reimbursements/hooks/useGetReimbursementDetails"

interface AirwallexScaProps {
  onClose: () => void
  onError: (error: any) => void
  scaSessionCode: string
}
enum Step {
  Instructions = "instructions",
  Setup = "setup",
  Verify = "verify",
  Done = "done",
  Cancelled = "cancelled"
}
export const AirwallexSca: React.FC<AirwallexScaProps> = ({ onClose, onError, scaSessionCode }) => {
  const { data: scaAuthorizationCodeData, error: errorGettingScaAuthCode } = useGetScaAuthorizationCode()
  const { data: reimbursementDetailData, isLoading: reimbursementDetailsLoading } = useGetReimbursementDetails(false)
  const [step, setStep] = useState<Step>(Step.Instructions)
  const [airwallexInitialized, setAirwallexInitialized] = useState(false)
  const scaSetupCompletedDate = reimbursementDetailData.scaSetupCompletedDateTime

  useEffect(() => {
    if (step === Step.Instructions && scaSetupCompletedDate) {
      setStep(Step.Verify)
    }
  }, [scaSetupCompletedDate, step])

  useEffect(() => {
    if (errorGettingScaAuthCode) {
      reportError(errorGettingScaAuthCode)
      onError(errorGettingScaAuthCode)
    }

    if (!scaAuthorizationCodeData) {
      return
    }

    const initializeAirwallex = async () => {
      try {
        const options = {
          env: Settings.AIRWALLEX_ENVIORNMENT ?? "demo",
          authCode: scaAuthorizationCodeData.authorizationCode,
          clientId: scaAuthorizationCodeData.clientId,
          codeVerifier: scaAuthorizationCodeData.codeVerifier
        }
        await init(options)
        setAirwallexInitialized(true)
      } catch (error) {
        reportError(`Error initializing Airwallex: ${error}`)
        onError(error)
      }
    }

    if (!airwallexInitialized) {
      initializeAirwallex()
    }
  }, [errorGettingScaAuthCode, scaAuthorizationCodeData, onError, step, airwallexInitialized])

  const handleSetupSuccess = () => {
    setStep(Step.Verify)
  }

  const handleVerificationSuccess = () => {
    setStep(Step.Done)
  }

  const handleCancel = () => {
    setStep(Step.Cancelled)
    onClose()
  }

  const handleError = (error: any) => {
    reportError(error)
    onError(error)
    setStep(Step.Cancelled)
  }

  // Show dialog if in 'Setup' or 'Verify' or 'Instructions' step
  const showScaDialog = step === Step.Instructions || step === Step.Setup || step === Step.Verify

  return (
    <>
      <>
        <InfoDialog open={showScaDialog} title={""} onClose={handleCancel}>
          {!airwallexInitialized || reimbursementDetailsLoading ? (
            <Box height={126} display="flex" justifyContent="center" alignItems="center">
              <Progress />
            </Box>
          ) : step === Step.Instructions ? (
            <AirwallexScaInstructions
              setAcknowledgedInstructions={() => {
                setStep(Step.Setup)
              }}
              handleCancel={handleCancel}
            />
          ) : step === Step.Setup ? (
            <AirwallexScaSetup onSetupSuccess={handleSetupSuccess} onCancel={handleCancel} onError={handleError} />
          ) : step === Step.Verify ? (
            <AirwallexScaVerify
              onVerificationSuccess={handleVerificationSuccess}
              onCancel={handleCancel}
              onError={handleError}
              scaSessionCode={scaSessionCode}
            />
          ) : null}
        </InfoDialog>
      </>
    </>
  )
}
