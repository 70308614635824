import React, { useContext, useState } from "react"
import { RequestCardContext } from "pages/request-carrot-card-flow"
import { Alert, Box, DialogContentText, DialogTitle, Link } from "@carrotfertility/carotene-core"
import { Form, FormCheckbox, FormOnSubmitHandler } from "@carrotfertility/carotene-core-x"
import { useStateMachine } from "components/context/stateMachine/StateMachineV2"
import RequestCardWrapper from "pages/request-carrot-card-flow/requestCardWrapper"
import { Steps } from "../workflows/steps"
import { FormattedMessage, useIntl } from "react-intl"
import { TtcPaths } from "../../../utils/Paths"
import { carrotClient } from "utils/CarrotClient"

const StripeUserAgreement = () => {
  const { isUsaCompany } = useContext(RequestCardContext)
  const [isError, setIsError] = useState(false)
  const intl = useIntl()

  const { canGoBack, send } = useStateMachine()

  const onSubmission: FormOnSubmitHandler<{
    stripeUserAgreementCheckbox: boolean
  }> = async () => {
    try {
      setIsError(false)
      await carrotClient.requestCard()
      send("")
    } catch (e) {
      setIsError(true)
    }
  }

  return (
    <Form onSubmit={onSubmission} defaultValues={{ stripeUserAgreementCheckbox: false }}>
      <RequestCardWrapper
        goBackToStepName={Steps.CONFIRM_PHONE_NUMBER}
        showBackButton={canGoBack}
        showContinueButton
        disableContinue={"stripeUserAgreementCheckbox"}
        sectionNum={isUsaCompany ? 3 : 2}
      >
        <DialogTitle>
          <FormattedMessage defaultMessage="Review terms" />
        </DialogTitle>
        <DialogContentText marginBottom={(theme) => theme.tokens.spacing.xl}>
          <FormattedMessage defaultMessage="To receive a Carrot Card, you need to read and accept the card issuer's terms on card use, security, data privacy, and more." />
        </DialogContentText>
        <Box sx={{ display: "flex", flexDirection: "column", gap: (theme) => theme.spacing(theme.tokens.spacing.lg) }}>
          <FormCheckbox
            name={"stripeUserAgreementCheckbox"}
            label={intl.formatMessage(
              {
                defaultMessage:
                  "I have read and accept the <link>authorized user terms</link> and <link2>E-SIGN disclosure</link2>."
              },
              {
                link: (linkContent) => (
                  <Link
                    href="https://stripe.com/legal/issuing/celtic-authorized-user-terms"
                    target="_blank"
                    color="inherit"
                  >
                    {linkContent}
                  </Link>
                ),
                link2: (linkContent) => (
                  <Link href="https://stripe.com/legal/e-sign-disclosure" target="_blank" color="inherit">
                    {linkContent}
                  </Link>
                )
              }
            )}
            required={true}
          />
          {isError && (
            <Alert severity="error">
              <FormattedMessage
                defaultMessage="An unexpected error occurred. Please <link>contact us</link> for assistance."
                values={{
                  link: (linkContent) => (
                    <Link color="inherit" href={TtcPaths.TTC_CARE_NAV} target={"_blank"}>
                      {linkContent}
                    </Link>
                  )
                }}
              />
            </Alert>
          )}
        </Box>
      </RequestCardWrapper>
    </Form>
  )
}

export { StripeUserAgreement }
