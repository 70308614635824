import { useCurrentUser } from "components/context/user/UserContext"
import React, { useEffect, useState } from "react"
import { useLocation } from "react-router-dom"
import { reportErrorMessage } from "utils/ErrorReporting"
import { UnknownErrorOccurred } from "../../views/error/UnknownErrorOccurred"
import { carrotClient } from "utils/CarrotClient"
import { Loading } from "../../views/Loading"
import { Redirect } from "react-router"
import Settings from "../../../utils/CarrotConfig"
import { SupportCenterUrlResponse } from "../../../types/zendeskTypes"

async function attemptSupportCenterLogin(args: { onAbort: () => void }) {
  const response: SupportCenterUrlResponse = await carrotClient.getSupportCenterUrl(true)
  const abort = response.abort
  if (abort) {
    args.onAbort()
  } else {
    const supportCenterUrl = response.supportCenterUrl
    window.location.href = `${supportCenterUrl}&return_to=${Settings.ZENDESK_GUIDE}`
  }
}

function getReferrer() {
  const referrerRaw = document.referrer
  try {
    return {
      referrer: new URL(referrerRaw),
      isError: false,
      referrerRaw
    }
  } catch {
    return {
      referrer: new URL(document.location.origin),
      isError: true,
      referrerRaw
    }
  }
}

function useProcessReferrer(referrer: URL) {
  const referrerUrlQueryParams = new URLSearchParams(referrer.search)
  const returnToUrl = referrerUrlQueryParams.get("return_to")

  const queryParams = new URLSearchParams(useLocation().search)
  const redirectKind = queryParams.get("kind")
  const redirectMessage = queryParams.get("message")

  const redirectedDueToError = redirectKind === "error"

  const shouldRetryLogin =
    redirectedDueToError &&
    (redirectMessage.startsWith("The unique request identifier was reused") ||
      redirectMessage.startsWith("Invalid iat parameter"))

  const [retryLogin, setRetryLogin] = useState(shouldRetryLogin)

  return {
    retryLogin,
    abortRetry: () => setRetryLogin(false),
    returnToUrl,
    redirectedDueToError,
    context: {
      redirectKind,
      redirectMessage
    }
  }
}

const SupportCenterLogoutContainer = (): JSX.Element => {
  const user = useCurrentUser()
  const { referrer, referrerRaw, isError: referrerError } = getReferrer()

  if (referrerError) {
    reportErrorMessage("Failed to parse referrer URL", {
      fingerprint: [user?.employeeId, "external-services-error", "referrer-parse"],
      tags: { "Employee-Id": user?.employeeId },
      extra: {
        referrer: referrerRaw
      }
    })
  }

  const { retryLogin, abortRetry, returnToUrl, redirectedDueToError, context } = useProcessReferrer(referrer)

  if (redirectedDueToError) {
    const errorMessage = `User coming from ${referrerRaw} could not be authenticated in Zendesk Guide.
  Reply received of kind: '${context.redirectKind}'.
  Error message: '${context.redirectMessage}'.
  Check Runbook for possible root causes and corrective measures.`

    reportErrorMessage(errorMessage, {
      fingerprint: [user?.employeeId, "external-services-error"],
      tags: { "Employee-Id": user?.employeeId }
    })
  }

  useEffect(() => {
    if (retryLogin) {
      attemptSupportCenterLogin({ onAbort: abortRetry })
    }
  }, [abortRetry, retryLogin])

  if (retryLogin) {
    return <Loading />
  }

  if (referrerError || redirectedDueToError) {
    return <UnknownErrorOccurred returnToLink={returnToUrl} />
  }

  return <Redirect to="/" />
}

export { SupportCenterLogoutContainer }
