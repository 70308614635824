import { useMutation, UseMutationResult } from "@tanstack/react-query"
import { CarrotPlanStepRequest } from "../../../types/carrotPlanTypes"
import { HttpStatusCodes } from "../../../utils/HttpStatusCodes"
import ResponseError from "../../../types/responseError"
import { carrotClient } from "utils/CarrotClient"

export default function useUpdatePlanStep(): UseMutationResult<void, ResponseError> {
  return useMutation((updateStepData: CarrotPlanStepRequest) => carrotClient.updateCarrotPlanStep(updateStepData), {
    retry: (failureCount, error) => {
      switch (error.statusCode) {
        case HttpStatusCodes.UNAUTHORIZED:
        case HttpStatusCodes.BAD_REQUEST:
        case HttpStatusCodes.NOT_FOUND:
          return false
        default:
          return failureCount <= 3
      }
    }
  })
}
