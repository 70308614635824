import React, { useLayoutEffect, useRef } from "react"
import { createElement, Sca } from "@airwallex/components-sdk"
import airwallexTheme from "./AirwallexScaTheme.json"
import { useCurrentUser } from "components/context/user/UserContext"
import useMutationMarkScaSetupAsCompleted from "./hooks/useMutationMarkScaSetupAsCompleted"

interface AirwallexScaSetupProps {
  onSetupSuccess: () => void
  onCancel: () => void
  onError: (error: any) => void
}

export const AirwallexScaSetup: React.FC<AirwallexScaSetupProps> = ({ onSetupSuccess, onCancel, onError }) => {
  const { personalEmail } = useCurrentUser()
  const { mutate } = useMutationMarkScaSetupAsCompleted()

  const scaManagementRef = useRef<HTMLDivElement>(null)
  const scaSetupElementRef = useRef<Sca.ScaElement | null>(null)
  const isUnmountedRef = useRef(false)

  useLayoutEffect(() => {
    let isMounted = true

    const initializeScaSetup = async () => {
      try {
        if (!scaManagementRef.current) {
          onError("Airwallex SCA management div not found")
          return
        }

        const scaSetupElement = await createElement("scaSetup", {
          userEmail: personalEmail,
          theme: airwallexTheme
        })

        scaSetupElementRef.current = scaSetupElement

        scaSetupElement.mount(scaManagementRef.current)

        scaSetupElement.on("ready", () => {
          // Element is ready
        })

        scaSetupElement.on("error", (data: any) => {
          onError(data)
        })

        scaSetupElement.on("scaSetupSucceed", () => {
          if (isMounted && scaSetupElementRef.current) {
            mutate(null, {
              onSuccess: () => {
                onSetupSuccess()
              }
            })
          }
        })

        scaSetupElement.on("verificationFailed", ({ reason }: { reason: string }) => {
          onError(reason)
        })

        scaSetupElement.on("cancel", () => {
          if (isMounted && scaSetupElementRef.current) {
            onCancel()
            scaSetupElementRef.current.unmount()
            scaSetupElementRef.current = null
          }
        })
      } catch (error) {
        onError(error)
      }
    }

    initializeScaSetup()

    return () => {
      isMounted = false
      isUnmountedRef.current = true
      if (scaSetupElementRef.current) {
        scaSetupElementRef.current.unmount()
        scaSetupElementRef.current = null
      }
    }
  }, [personalEmail, onSetupSuccess, onCancel, onError, mutate])

  return <div ref={scaManagementRef}></div>
}
