import React, { useReducer } from "react"
import { redirectToSamlExternalLogin } from "../../../utils/RedirectToSamlExternalLogin"
import { CarrotErrorCodes } from "../../../utils/CarrotErrors"
import { useHistory } from "react-router"
import { carrotClient } from "utils/CarrotClient"
interface SignUpFlowProviderProps {
  children: React.ReactNode | React.ReactNodeArray
  email?: string
  dateOfBirth?: string
  firstName?: string
  lastName?: string
  multipleEmployeesFound?: boolean
  externalEmployeeId?: string
  parentCompanyId?: number
}

const SignUpFlowContext = React.createContext(null)

export const useSignUpFlow = () => React.useContext(SignUpFlowContext)

const actionTypes = {
  saveMember: "SAVE_MEMBER",
  multipleEmployeesFound: "MULTIPLE_FOUND"
}

// @ts-expect-error TS7006
function signUpFlowReducer(state, action) {
  switch (action.type) {
    case actionTypes.saveMember: {
      return {
        email: action.payload.email,
        dateOfBirth: action.payload.dateOfBirth,
        firstName: action.payload.firstName,
        lastName: action.payload.lastName,
        multipleEmployeesFound: false,
        externalEmployeeId: action.payload.externalEmployeeId,
        parentCompanyId: action.payload.parentCompanyId
      }
    }
    case actionTypes.multipleEmployeesFound: {
      return {
        ...state,
        multipleEmployeesFound: true
      }
    }
    default: {
      throw new Error(`Unhandled type: ${action.type}`)
    }
  }
}

export default function SignUpFlowProvider({
  children,
  email,
  dateOfBirth,
  firstName,
  lastName,
  externalEmployeeId,
  parentCompanyId
}: SignUpFlowProviderProps): JSX.Element {
  const [state, dispatch] = useReducer(signUpFlowReducer, {
    email: email || null,
    dateOfBirth: dateOfBirth || null,
    firstName: firstName || null,
    lastName: lastName || null,
    multipleEmployeesFound: false,
    externalEmployeeId: externalEmployeeId || null,
    parentCompanyId: parentCompanyId || null
  })
  const history = useHistory()

  // @ts-expect-error TS7006
  const handleSaveMemberInfoInMemory = (memberInfo): void => {
    return dispatch({ type: actionTypes.saveMember, payload: memberInfo })
  }
  const saveMultipleEmployeesFound = (): void => dispatch({ type: actionTypes.multipleEmployeesFound })
  function getHasFoundAccount(): boolean {
    return (
      (state.email != null && state.dateOfBirth != null && state.firstName != null && state.lastName != null) ||
      (state.externalEmployeeId != null && state.dateOfBirth != null)
    )
  }
  // @ts-expect-error TS7006
  const lookupAccountByEmail = async (email) => {
    try {
      const response = await carrotClient.lookup(email)

      if (response?.employee?.samlIdpEntityId && response?.employee?.ssoOnly) {
        const { samlExternalLoginUrl, samlIdpEntityId, isAdmin } = response.employee
        redirectToSamlExternalLogin(samlExternalLoginUrl, samlIdpEntityId, isAdmin)
        return
      }
      if (response?.employee?.isRegistered) {
        handleSaveMemberInfoInMemory({ email })
        history.push("/signup-employee-already-registered")
        return
      }

      return response.status
    } catch (error) {
      return CarrotErrorCodes.INTERNAL_SERVER_ERROR
    }
  }

  return (
    <SignUpFlowContext.Provider
      value={{
        getHasFoundAccount,
        lookupAccountByEmail,
        handleSaveMemberInfoInMemory,
        saveMultipleEmployeesFound,
        ...state
      }}
    >
      {children}
    </SignUpFlowContext.Provider>
  )
}
