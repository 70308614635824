import { Asset } from "contentful"
import { TypeGuidePageSkeleton, TypeGuideSectionSkeleton, TypeGuideSkeleton } from "types/contentful"
import { RawContentfulConditionSkeleton } from "lib/contentful/utils/conditionUtils"
import { EntryWithoutUnresolvableLinks } from "utils/ContentTypes"

export type Page = {
  name: string
  body: string
}
export type Section = {
  pages: Page[]
  title: string
  slug: string
}
export type File = {
  url: string
}
export type CompanionDocument = {
  file: File
}
export type Guide = {
  id: string
  title: string
  slug: string
  companionDocument: CompanionDocument
  description: string
  audience: string
  overview: string
  afterGuideModal: string
  conditions: EntryWithoutUnresolvableLinks<RawContentfulConditionSkeleton>[]
  sections: Section[]
}

const mapContentToPage = ({ fields }: EntryWithoutUnresolvableLinks<TypeGuidePageSkeleton>): Page => {
  if (!fields) {
    return null
  }
  const { name, body } = fields
  return {
    name,
    body
  }
}
const mapContentToSection = ({ fields }: EntryWithoutUnresolvableLinks<TypeGuideSectionSkeleton>): Section => {
  if (!fields) {
    return null
  }
  const { title, pages, slug } = fields
  return {
    title,
    slug,
    pages: pages.map(mapContentToPage)
  }
}

const mapContentToCompanionDocument = (companionDocument: Asset<"WITHOUT_UNRESOLVABLE_LINKS">): CompanionDocument => {
  if (!companionDocument || !companionDocument.fields) {
    return null
  }
  const { file } = companionDocument.fields
  return {
    file
  }
}

export const mapContentToGuide = ({ fields, sys }: EntryWithoutUnresolvableLinks<TypeGuideSkeleton>): Guide => {
  if (!fields || !sys) {
    return null
  }
  const { sections, slug, title, description, audience, afterGuideModal, conditions, overview, companionDocument } =
    fields
  const { id } = sys
  return {
    id,
    title,
    slug,
    description,
    audience,
    afterGuideModal,
    conditions,
    overview,
    sections: sections.map(mapContentToSection),
    companionDocument: mapContentToCompanionDocument(companionDocument)
  }
}
