import { findCategoryBySlug, findGroupSessionsBySlug, findSubcategoryForArticle } from "components/read/shared/Finder"
import { useContentfulConditionalGate } from "lib/contentful/components/ContentfulConditionGate"
import React from "react"
import { useHistory, useParams } from "react-router"
import { Group, mapContentToGroup } from "./utils"
import useGetCategories from "../../components/read/shared/useGetCategories"
import { EntryWithoutUnresolvableLinks } from "utils/ContentTypes"
import { TypeCategorySkeleton, TypeSubCategorySkeleton } from "types/contentful"

export type GroupComponentProps = {
  group: Group
  category: EntryWithoutUnresolvableLinks<TypeCategorySkeleton>
  subcategory: EntryWithoutUnresolvableLinks<TypeSubCategorySkeleton>
}

export const GroupParser = ({
  GroupComponent
}: {
  GroupComponent: React.ComponentType<GroupComponentProps>
}): JSX.Element => {
  const history = useHistory()
  const params = useParams<{ category: string; group: string }>()

  const { data: categories } = useGetCategories()
  const category = findCategoryBySlug(categories, params.category)

  const groupContent = findGroupSessionsBySlug(categories, params.group)
  const groupConditions = groupContent?.map((g) => {
    const group = mapContentToGroup(g)
    const subcategory = findSubcategoryForArticle(categories, g)

    return {
      element: <GroupComponent group={group} category={category} subcategory={subcategory} />,
      conditions: group.conditions
    }
  })
  const [group] = useContentfulConditionalGate(groupConditions ? groupConditions : [])

  if (!group || !category) {
    history.push("/read")
    return null
  }

  return <>{group}</>
}
