import React from "react"
import { ProviderFinderParam, useStringSearchParam } from "../../utils/providerFinderSearchUrl"
import { FormattedMessage, useIntl } from "react-intl"
import { Box, Checkbox, FormControl, FormControlLabel, FormHelperText } from "@carrotfertility/carotene-core"
import { getProviderNetworkConfig } from "components/views/app/navigation/hooks/useHasProviderFinder"

export function ProviderNetworksFilter({
  providerNetworkIdentifier
}: {
  providerNetworkIdentifier: string
}): JSX.Element {
  const [isProviderNetworksSearchParam, setIsProviderNetworksSearchParam] = useStringSearchParam(
    ProviderFinderParam.ProviderNetworkId
  )
  const intl = useIntl()
  const { networkName, externalProviderNetworkId } = getProviderNetworkConfig(providerNetworkIdentifier)
  return (
    <Box
      display="flex"
      flexDirection="column"
      padding={(theme) => theme.spacing(theme.tokens.spacing.sm)}
      sx={(theme) => ({
        backgroundColor: theme.palette.background.paper,
        borderRadius: theme.tokens.borderRadius.sm
      })}
    >
      <Box
        data-id="provider-networks-filter"
        display="flex"
        flexDirection="column"
        gap={(theme) => theme.spacing(theme.tokens.spacing.md)}
      >
        <FormControl>
          <FormControlLabel
            label={
              <Box
                component="span"
                display="flex"
                flexDirection="row"
                alignItems="center"
                gap={(theme) => theme.spacing(theme.tokens.spacing.xxl)}
              >
                <FormattedMessage defaultMessage="{networkName} only" values={{ networkName }} />
              </Box>
            }
            aria-label={intl.formatMessage(
              {
                defaultMessage: "Select providers within the {networkName} network only"
              },
              { networkName }
            )}
            control={
              <Checkbox
                name="isProviderNetworks"
                checked={!!isProviderNetworksSearchParam}
                onChange={(e) => setIsProviderNetworksSearchParam(e.target.checked ? externalProviderNetworkId : null)}
              />
            }
          />
          <FormHelperText>
            <FormattedMessage defaultMessage="In-network providers are reviewed for quality and offer negotiated rates" />
          </FormHelperText>
        </FormControl>
      </Box>
    </Box>
  )
}
