import React, { useEffect } from "react"
import { useStateMachine } from "../../../context/stateMachine/StateMachineV2"
import { useCheckRoutingInfoQueryCache } from "components/carrot-plans/shared/useQueryRoutingInfo"
import { RoutingInfo } from "../../../../types/routingInfo"

export const mostHelpfulNowTransitionOptions = [
  "DISCUSS_OPTIONS",
  "HELP_PATHWAY",
  "HELP_FINDING_PROVIDER",
  "CHAT_FERTILITY",
  "CHAT_LIFESTYLE",
  "CHAT_HEALTH",
  "LGBTQ_FAMILY"
]

export default function MostHelpfulDecisionStep(): JSX.Element {
  const routingData = useCheckRoutingInfoQueryCache()

  function getMemberSelectsValue(routingData: RoutingInfo): string {
    const mostHelpfulValue = routingData?.mostHelpfulNow?.[0]
    const journey = routingData?.journey
    const journeysShouldNotSeeDoAnyApply = ["MENOPAUSE", "LOW_TESTOSTERONE", "GENDER_AFFIRMING_CARE", "PREGNANT"]
    const skipMedicalQuestionsForJourney: boolean = routingData?.journey === "PARENTING"
    const skipMedicalQuestionsForJourneyStage: boolean = routingData?.journeyStage === "POSTPARTUM"

    if (skipMedicalQuestionsForJourney || skipMedicalQuestionsForJourneyStage) {
      return "ANYTHING_ELSE"
    }

    if (journeysShouldNotSeeDoAnyApply.includes(journey)) {
      return journey
    }

    if (mostHelpfulNowTransitionOptions.includes(mostHelpfulValue)) {
      return mostHelpfulValue
    }

    return "OTHER_ANSWERS"
  }

  const { send } = useStateMachine()
  useEffect(() => {
    send(getMemberSelectsValue(routingData))
  })
  return <></>
}
