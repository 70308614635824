import React, { FC, ReactElement } from "react"
import {
  TileNextActionType,
  fieldIsValid,
  getIconUrl,
  getNextActionType,
  getNextTileAction,
  getPathForTile,
  isPhoneSupportNumber,
  isTileDeadEnd
} from "../utils/mappingUtils"
import { toCondition } from "../utils/conditionUtils"
import { ContentfulRawMarkdownRenderer } from "services/contentful/content-renderer"
import { defaultMarkdownElements } from "services/markdown/elements"
import { Condition } from "services/contentful/conditional-gate"
import { Box, InternalIcon } from "@carrotfertility/carotene-core"
import { useGetPhoneSupportNumberForUser } from "components/dynamic-content/hooks/useQueryPhoneSupport"
import { useHistory } from "react-router-dom"
import { EntryWithoutUnresolvableLinks } from "utils/ContentTypes"
import { TypeTileSkeleton } from "types/contentful"

type TileProps = {
  title: string
  entryId: string
  description?: string
  secondaryDescription?: string
  iconUrl?: string
  showArrow?: boolean
  disabled?: boolean
  onClick: () => void
}

type TileElementProps = {
  tile: EntryWithoutUnresolvableLinks<TypeTileSkeleton>
  baseUrl: string
  doesUserSatisfyConditions: (conditions: Array<Condition>) => boolean
  memberJourney?: string
}

const TileElement = ({
  tile,
  baseUrl,
  doesUserSatisfyConditions,
  memberJourney
}: TileElementProps): ReactElement<typeof Tile> => {
  const history = useHistory()
  const { data: phoneSupportNumber } = useGetPhoneSupportNumberForUser()

  const {
    sys: { id: entryId },
    fields: { display, conditions, nextAction }
  } = tile

  if (!fieldIsValid(display) || (isPhoneSupportNumber(nextAction) && !phoneSupportNumber?.e164FormatPhoneNumber)) {
    return null
  }

  const {
    fields: { title, description, icon, secondaryDescription }
  } = display

  const pushToTilePath = ({
    tile,
    baseUrl
  }: {
    tile: EntryWithoutUnresolvableLinks<TypeTileSkeleton>
    baseUrl: string
  }) => {
    history.push(getPathForTile({ tile, baseUrl }))
  }

  const getTileOnClick = ({
    tile,
    baseUrl
  }: {
    tile: EntryWithoutUnresolvableLinks<TypeTileSkeleton>
    baseUrl: string
  }): (() => void) => {
    const nextAction = getNextTileAction(tile)

    if (fieldIsValid(nextAction)) {
      switch (getNextActionType(nextAction)) {
        case TileNextActionType.TILE_PAGE:
        case TileNextActionType.CALENDLY_LINK:
        case TileNextActionType.LANGUAGE_PAGE:
        case TileNextActionType.TALK_TO_CARROT_SEND_A_MESSAGE:
          return () => pushToTilePath({ tile, baseUrl })
        case TileNextActionType.PHONE_SUPPORT_NUMBER:
          return () => window.open(`tel:${phoneSupportNumber?.e164FormatPhoneNumber}`, "_self")
      }
    } else {
      return () => history.push("/404")
    }
  }

  return (
    <>
      {!conditions || doesUserSatisfyConditions(conditions.map(toCondition)) ? (
        <Box id="tile-element-tile-box" width="100%" height="100%" data-member-journey={memberJourney}>
          <Tile
            title={title}
            entryId={entryId}
            description={description}
            secondaryDescription={secondaryDescription}
            iconUrl={getIconUrl(icon)}
            showArrow={isPhoneSupportNumber(nextAction)}
            disabled={isTileDeadEnd(tile, doesUserSatisfyConditions)}
            onClick={getTileOnClick({
              tile,
              baseUrl
            })}
          />
        </Box>
      ) : null}
    </>
  )
}

const Tile: FC<TileProps> = ({
  title,
  description,
  secondaryDescription,
  entryId,
  iconUrl,
  disabled = false,
  showArrow = true,
  onClick = null
}) => {
  return (
    <Box
      data-entryid={entryId}
      component="button"
      display="flex"
      flexDirection="column"
      justifyContent="space-between"
      border={(theme) => `solid ${theme.tokens.borderWidth.md} `}
      borderColor={(theme) => theme.palette.border.light}
      padding={(theme) => theme.tokens.spacing.lg}
      borderRadius={(theme) => theme.tokens.borderRadius.md}
      sx={{
        cursor: disabled ? "default" : "pointer",
        outlineOffset: "0.125rem",
        outlineColor: (theme) => theme.palette.focusRing,
        backgroundColor: (theme) => {
          return disabled ? theme.palette.secondary.light : theme.palette.background.paper
        }
      }}
      onClick={!disabled ? onClick : null}
      width="100%"
      height="100%"
    >
      <Box sx={{ textAlign: "start" }}>
        {iconUrl && (
          <Box
            component="img"
            alt=""
            src={iconUrl}
            width="4rem"
            height="4rem"
            marginBottom={(theme) => theme.spacing(theme.tokens.spacing.md)}
          />
        )}
        <defaultMarkdownElements.h5.component {...defaultMarkdownElements.h5.props}>
          {title}
        </defaultMarkdownElements.h5.component>
        {description && <ContentfulRawMarkdownRenderer>{description}</ContentfulRawMarkdownRenderer>}
        {secondaryDescription && <ContentfulRawMarkdownRenderer>{secondaryDescription}</ContentfulRawMarkdownRenderer>}
      </Box>
      {!disabled && showArrow && <InternalIcon fontSize="small" color="primary" sx={{ alignSelf: "self-end" }} />}
    </Box>
  )
}

export { TileElement, Tile }
