import React, { useState } from "react"
import { useSelector } from "react-redux"
import { Dialog, DialogContent, DialogProps } from "@carrotfertility/carotene-core"

import {
  StateMachineProvider,
  StateMachineStepView as RequestCardView
} from "components/context/stateMachine/StateMachineV2"
import EmployeeOnlyModalContainer from "components/employee-only-containers/EmployeeOnlyModalContainer"

import { CountryCode } from "content/CountryCode"
import { requestCardSteps } from "pages/request-carrot-card-flow/workflows/step-library"
import { requestCardWorkflows, Workflows } from "pages/request-carrot-card-flow/workflows/workflows"
import { Steps } from "pages/request-carrot-card-flow/workflows/steps"
import { getIsUsa } from "reducers/userInfo"
import { useIntl } from "react-intl"

import { getIsAvenuesMember, getIsRomanHealthNYMandateMember, getIsNBAMember, getIsWNBAMember } from "derivedSelectors"
import { DialogCloseButton } from "@carrotfertility/carotene-core-x"
import { CarrotCardProviders } from "lib/carrot-api/types/CarrotCard"

type RequestCardContextProps = {
  isUsaCompany?: boolean
  phoneNumber?: string
  countryCode?: CountryCode
  completeConfirmPhoneNumber?: (phoneNumber: string, countryCode: CountryCode, verificationCode?: string) => void
  verificationCode?: string
  showInformationScreen?: boolean
  onClose?: () => void
  totalSteps?: number
}

const RequestCardContext = React.createContext<RequestCardContextProps>({})

type RequestCardContainerState = {
  isUsaCompany?: boolean
  phoneNumber?: string
  countryCode?: CountryCode
  verificationCode?: string
  showInformationScreen?: boolean
  onClose?: () => void
}

type InitalStepContextInformation = { showInformationScreen: boolean; isUsa: boolean }

const InitialStepFunctionByProvider: Record<
  CarrotCardProviders,
  (args: InitalStepContextInformation) => Steps[number]
> = {
  STRIPE: ({ showInformationScreen, isUsa }) => {
    if (showInformationScreen && isUsa) return Steps.REQUEST_CARD_INFORMATION
    if (!showInformationScreen && isUsa) return Steps.MAILING_ADDRESS
    return Steps.CONFIRM_PHONE_NUMBER
  },
  AIRWALLEX: ({ showInformationScreen }) => {
    if (showInformationScreen) return Steps.REQUEST_CARD_INFORMATION
    return Steps.INTERNATIONAL_ADDRESS
  }
}

const DesiredWorkflowByProvider: Record<CarrotCardProviders, Workflows[number]> = {
  STRIPE: Workflows.REQUEST_CARD,
  AIRWALLEX: Workflows.REQUEST_AIRWALLEX_CARD
}

function getInitialStep(provider: CarrotCardProviders, contextualInformation: InitalStepContextInformation) {
  const initialStepFunc = InitialStepFunctionByProvider[provider]
  return initialStepFunc(contextualInformation)
}

function getDesiredWorkflow(provider: CarrotCardProviders) {
  return DesiredWorkflowByProvider[provider]
}

const RequestCardContainer = ({ open, onExit }: Pick<DialogProps, "open"> & { onExit: () => void }) => {
  const [state, setState] = useState<RequestCardContainerState>({})

  const completeConfirmPhoneNumber = (phoneNumber: string, countryCode: CountryCode, verificationCode: string) => {
    return setState({ ...state, phoneNumber, countryCode, verificationCode })
  }
  const intl = useIntl()

  const isUsa = useSelector(getIsUsa)
  const isAvenuesMember = useSelector(getIsAvenuesMember)
  const isNBAMember = useSelector(getIsNBAMember)
  const isRomanHealthNYMandateMember = useSelector(getIsRomanHealthNYMandateMember)
  const isWNBAMember = useSelector(getIsWNBAMember)

  const showInformationScreen = isAvenuesMember || isNBAMember || isRomanHealthNYMandateMember || isWNBAMember

  const context = {
    completeConfirmPhoneNumber: completeConfirmPhoneNumber,
    isUsaCompany: isUsa,
    phoneNumber: state.phoneNumber,
    countryCode: state.countryCode,
    verificationCode: state.verificationCode,
    showInformationScreen: showInformationScreen,
    onClose: onExit,
    totalSteps: (isUsa ? 3 : 2) + 1
  }

  const requestCardSection = {
    name: "RequestCard",
    initialStep: getInitialStep("STRIPE", { showInformationScreen, isUsa }),
    desiredWorkflow: getDesiredWorkflow("STRIPE"),
    steps: requestCardSteps,
    workflows: requestCardWorkflows
  }

  return (
    <RequestCardContext.Provider value={context}>
      <StateMachineProvider
        initialSection="RequestCard"
        stateMachineDescription={[requestCardSection]}
        onComplete={onExit}
      >
        <EmployeeOnlyModalContainer onClose={onExit}>
          <Dialog open={open} onClose={onExit}>
            <DialogCloseButton aria-label={intl.formatMessage({ defaultMessage: "close" })} onClick={onExit} />
            <DialogContent>
              <RequestCardView />
            </DialogContent>
          </Dialog>
        </EmployeeOnlyModalContainer>
      </StateMachineProvider>
    </RequestCardContext.Provider>
  )
}

export { RequestCardContext, RequestCardContainer }
